import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import { themeColors } from "../../Components/theme";
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
} from 'formik';
import {
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import './booking.style.css';
import { FileUpload, List } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { showSnackbarAction } from "../../Redux/actions";
import { makeStyles } from '@material-ui/core/styles';
import EditBookingDetails from "./EditBookingDetails";
import EditApplicantDetails from "./EditApplicantDetails";
import EditAdditionalCharges from "./EditAdditionalCharges";
import EditConsolidatedCosting from "./EditConsolidatedCosting";
import EditPaymentPlan from "./EditPaymentPlan";
import EditAttachments from "./EditAttachments";
import EditEmployeeDetails from "./EditEmployeeDetails";
import EditPropertyDetailsBSP from "./EditPropertyDetailsBSP";
import FormDialog from "./Dialog";

const useStyles = makeStyles((theme: any) => ({
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}));

interface MyFormValues {
    booking_id: string,
    added_by: string,
    added_time: string,
    modified_by: string,
    modified_time: string,
    date_booking: string,
    developer_name: string,
    project_name: string,
    area: string,
    unit_no: string,
    source_fund: string,
    scheme: string,
    booking_attachment: string,
    pan_card: string,
    area_type: string,
    Kyc_attachment: string,
    employee_details: {
        owner_email: string,
        in_active_employe: string
    },
    applicant_details: {
        applicant: {
            name: string,
            address_line1: string,
            address_line2: string,
            city: string,
            state: string,
            country: string,
            postal_code: string,
            phone_number1: string,
            phone_number2: string,
            email: string,
            gender: string,
            date_of_birth: string,
            marrital_status: string,
            occupation: string,
            company_name: string,
            designation: string,
            net_monthly_income: string,
            aadhaar_no: string,
            pan_no: string
        },
        co_applicant: [{
            name: string,
            address_line1: string,
            address_line2: string,
            city: string,
            state: string,
            country: string,
            postal_code: string,
            phone_number1: string,
            phone_number2: string,
            email: string,
            gender: string,
            date_of_birth: string,
            marrital_status: string,
            occupation: string,
            company_name: string,
            designation: string,
            net_monthly_income: string,
            aadhaar_no: string,
            pan_no: string
        }
        ],
    }
    property_details_BSP: {
        // unit_no: string,
        tower_block: string,
        area: string,
        measure: string,
        floor: string,
        payment_plan: string,
        bsp: string,
        bsp_discounting_type: string,
        bsp_discount_amount: string,
        bsp_discount: string,
        net_bsp_after_discount: string
    },
    additional_charges: {
        a: string,
        b: string,
        c: string,
        d: string,
        e: string,
        f: string,
        g: string,
        h: string,
        i: string,
        a_amount: string,
        b_amount: string,
        c_amount: string,
        d_amount: string,
        e_amount: string,
        f_amount: string,
        g_amount: string,
        h_amount: string,
        i_amount: string,
        total_additional_charges: string
    },
    consolidated_costing: {
        net_bsp_area: string,
        total_additional_charges: string,
        gross_property_cost_applicant: string,
        gst_amount: string,
        gst: string,
        net_property_cost_applicant: string,
        minimum_booking_amount: string
    },
    payment_plan: [{
        payment_instalments: string,
        payment: string,
        payment_amount: string,
        payment_date: string,
        payment_reference: string,
        payment_source: string,
        bank_name: string,
        payment_receiving_proof: string,
        payment_clearance_proof: string,
        payment__proof: string,
        Payment_Status: string
    }],
    source_of_fund: {
        funding_source: string,
        Occupation: string,
        gross_monthly_income: string,
        annual_bonus_incentive: string,
        rental_income: string,
        existing_EMI: string
    },
    attachments: {
        developer_receiving: string,
        BE_form: string,
        approvals: string
        remarks: string
    }

}
const CssTextField = styled(TextField)({
    fontSize: "0.8rem"
});
type props = {
    history: any;
    user: any;
    theme: any;
    authToken:any;
    bookingHeader:any;
 };
const steps = ["Bookings Details", "Property Details with BSP", "Applicant Details", "Additional Charges", "Consolidated Costing", "Payment Plan", "Attachment"];
const sourceFund = ["Self Funding", "Self Loan Process", "Loan Stone"];
function _renderStepContent(step: any, formikProps: any, projectsList: any, projectDeveloperObj: any, setBookingAttechment: any, setKycAttechment: any) {
    switch (step) {
        case 0:
            return <EditBookingDetails
                formikProps={formikProps}
                projectsList={projectsList}
                projectDeveloperObj={projectDeveloperObj}
                setBookingAttechment={setBookingAttechment}
                setKycAttechment={setKycAttechment}
            />
        case 1:
            return <EditPropertyDetailsBSP
                formikProps={formikProps}
            />
        case 2:
            return <EditApplicantDetails
                formikProps={formikProps}
            />
        case 3:
            return <EditAdditionalCharges
                formikProps={formikProps}
            />
        case 4:
            return <EditConsolidatedCosting
                formikProps={formikProps}
            />
        case 5:
            return <EditPaymentPlan
                formikProps={formikProps}
            />
        case 6:
            return <EditAttachments
                formikProps={formikProps}
            />
        default:
            return <div>Not Found</div>;
    }
}

const EditBooking = (props: props & FormikProps<MyFormValues>) => {
    const dispatcher = useDispatch();
    const classes = useStyles();
    const location: any = useLocation();
    const [load, setLoad] = useState(false);
    const [locationsList, setLocationsList] = useState<any[]>(
        []
    );
    const [budgetsList, setBudgetsList] = useState<any[]>([]);
    const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
    const [projectsList, setProjectsList] = useState<any[]>(
        []
    );
    const [projectDeveloperObj, setProjectDeveloperObj] = useState<any[]>(
        []
    );
    const [bookingAttechment, setBookingAttechment] = useState<any>();
    const [kycAttechment, setKycAttechment] = useState<any>();
    const [contactDetails, setContactsDetails] = useState<any>();
    const [notesDetails, setNotesDetails] = useState<any[]>([]);
    const [attachmentDetails, setAttachmentsDetails] = useState<any[]>([]);
    const [callLogsDetails, setCallLogsDetails] = useState<any[]>([]);
    const [bookingType, setBookingType] = useState<any>();
    const [reBookingData, setReBookingData] = useState<any>();
    const [serverUrlBooking, setServerUrlBooking] = useState<any>();
    const [serverUrlKyc, setServerUrlKyc] = useState<any>();
    const [propsBookingData, setPropsBookingData] = useState<any>();
    const [bookingStatus, setBookingStatus] = useState<any>();
    const [statusKey, setStatusKey] = useState<any>();
    const [bookingAttachmentsDetails, setBookingAttachmentsDetails] = useState<any>({});
    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;
    const [open, setOpen] = useState<boolean>(false);
    const [reason, setReason] = useState<any>();

    useEffect(() => {
        setContactsDetails(location.state?.Contact_details);
        setNotesDetails(location.state?.notesList);
        setAttachmentsDetails(location.state?.attachmentsList);
        setCallLogsDetails(location.state?.callLogsList);
        setBookingType(location.state?.booking_type);
        setReBookingData(location.state?.reBookingData);
        setPropsBookingData(location.state?.paramsData);
        setBookingStatus(location.state?.paramsData?.status);
    }, [location.state]);

    useEffect(() => {
        const organization_id = props.user?.organization_id;
        const unsub = contactResources(
            organization_id,
            (data) => setLocationsList(data),
            (data) => setBudgetsList(data),
            (data) => setProjectsList(data),
            (data) => setLeadSourceList(data),
            (data) => setProjectDeveloperObj(data),
        )
        return () => {
            unsub();
        };
    }, [props]);
    const initialValues: MyFormValues = {
        booking_id: propsBookingData?.booking_id || "",
        added_by: propsBookingData?.user_email || "",
        added_time: propsBookingData?.created_at || "",
        modified_by: `${props.user.user_first_name} ${props.user.user_last_name}` || "",
        modified_time: propsBookingData?.modified_time || "",
        date_booking: moment(propsBookingData?.date_booking).format("YYYY-MM-DD") || "",
        developer_name: propsBookingData?.developer_name || "",
        project_name: propsBookingData?.project_name || "",
        area: propsBookingData?.area || "",
        unit_no: propsBookingData?.unit_no || "",
        source_fund: propsBookingData?.source_fund || "",
        scheme: propsBookingData?.scheme || "",
        area_type: propsBookingData?.area_type || "",
        booking_attachment: propsBookingData?.booking_attachment || "",
        pan_card: propsBookingData?.pan_card || "",
        Kyc_attachment: propsBookingData?.Kyc_attachment || "",
        employee_details: {
            owner_email: propsBookingData?.contact_owner_email || "",
            in_active_employe: propsBookingData?.in_active_employe || ""
        },
        applicant_details: {
            applicant: {
                name: propsBookingData?.applicant_details?.applicant?.name || "",
                address_line1: propsBookingData?.applicant_details?.applicant?.address_line1 || "",
                address_line2: propsBookingData?.applicant_details?.applicant?.address_line2 || "",
                city: propsBookingData?.applicant_details?.applicant?.city || "",
                state: propsBookingData?.applicant_details?.applicant?.state || "",
                country: propsBookingData?.applicant_details?.applicant?.country || "",
                postal_code: propsBookingData?.applicant_details?.applicant?.postal_code || "",
                phone_number1: propsBookingData?.applicant_details?.applicant?.phone_number1 || "",
                phone_number2: propsBookingData?.applicant_details?.applicant?.phone_number2 || "",
                email: propsBookingData?.applicant_details?.applicant?.email || "",
                gender: propsBookingData?.applicant_details?.applicant?.gender || "",
                date_of_birth: moment(propsBookingData?.applicant_details?.applicant?.date_of_birth).format("YYYY-MM-DD") || "",
                marrital_status: propsBookingData?.applicant_details?.applicant?.marrital_status || "",
                occupation: propsBookingData?.applicant_details?.applicant?.occupation || "",
                company_name: propsBookingData?.applicant_details?.applicant?.company_name || "",
                designation: propsBookingData?.applicant_details?.applicant?.designation || "",
                net_monthly_income: propsBookingData?.applicant_details?.applicant?.net_monthly_income || "",
                aadhaar_no: propsBookingData?.applicant_details?.applicant?.aadhaar_no || "",
                pan_no: propsBookingData?.applicant_details?.applicant?.pan_no || ""
            },
            co_applicant:propsBookingData?.applicant_details?.co_applicant || [{
                name: "",
                address_line1: "",
                address_line2: "",
                city: "",
                state: "",
                country: "",
                postal_code: "",
                phone_number1: "",
                phone_number2: "",
                email: "",
                gender: "",
                date_of_birth: "",
                marrital_status: "",
                occupation: "",
                company_name: "",
                designation: "",
                net_monthly_income: "",
                aadhaar_no: "",
                pan_no: ""
            }
            ] 
        },
        property_details_BSP: {
            tower_block: propsBookingData?.property_details_BSP?.tower_block || "",
            area: propsBookingData?.property_details_BSP?.area || "",
            measure: propsBookingData?.property_details_BSP?.measure || "",
            floor: propsBookingData?.property_details_BSP?.floor || "",
            payment_plan: propsBookingData?.property_details_BSP?.payment_plan || "",
            bsp: propsBookingData?.property_details_BSP?.bsp || "",
            bsp_discounting_type: propsBookingData?.property_details_BSP?.bsp_discounting_type || "",
            bsp_discount_amount: propsBookingData?.property_details_BSP?.bsp_discount_amount || "",
            bsp_discount: propsBookingData?.property_details_BSP?.bsp_discount || "",
            net_bsp_after_discount: propsBookingData?.property_details_BSP?.net_bsp_after_discount || ""
        },
        additional_charges: {
            a: propsBookingData?.additional_charges?.a || "",
            b: propsBookingData?.additional_charges?.b || "",
            c: propsBookingData?.additional_charges?.c || "",
            d: propsBookingData?.additional_charges?.d || "",
            e: propsBookingData?.additional_charges?.e || "",
            f: propsBookingData?.additional_charges?.f || "",
            g: propsBookingData?.additional_charges?.g || "",
            h: propsBookingData?.additional_charges?.h || "",
            i: propsBookingData?.additional_charges?.i || "",
            a_amount: propsBookingData?.additional_charges?.a_amount || "",
            b_amount: propsBookingData?.additional_charges?.b_amount || "",
            c_amount: propsBookingData?.additional_charges?.c_amount || "",
            d_amount: propsBookingData?.additional_charges?.d_amount || "",
            e_amount: propsBookingData?.additional_charges?.d_amount || "",
            f_amount: propsBookingData?.additional_charges?.f_amount || "",
            g_amount: propsBookingData?.additional_charges?.g_amount || "",
            h_amount: propsBookingData?.additional_charges?.h_amount || "",
            i_amount: propsBookingData?.additional_charges?.i_amount || "",
            total_additional_charges: propsBookingData?.additional_charges?.total_additional_charges || ""
        },
        consolidated_costing: {
            net_bsp_area: propsBookingData?.consolidated_costing?.net_bsp_area || "",
            total_additional_charges: propsBookingData?.consolidated_costing?.total_additional_charges || "",
            gross_property_cost_applicant: propsBookingData?.consolidated_costing?.gross_property_cost_applicant || "",
            gst_amount: propsBookingData?.consolidated_costing?.gst_amount || "",
            gst: propsBookingData?.consolidated_costing?.gst || "",
            net_property_cost_applicant: propsBookingData?.consolidated_costing?.net_property_cost_applicant || "",
            minimum_booking_amount: propsBookingData?.consolidated_costing?.minimum_booking_amount || ""
        },
        payment_plan:propsBookingData?.payment_plan || [{
            payment_instalments: "",
            payment: "",
            payment_amount: "",
            payment_date: "",
            payment_reference: "",
            payment_source: "",
            bank_name: "",
            payment_receiving_proof: "",
            payment_clearance_proof: "",
            payment__proof: "",
            Payment_Status: ""
        }],
        source_of_fund: {
            funding_source: propsBookingData?.source_of_fund?.funding_source || "",
            Occupation: propsBookingData?.source_of_fund?.Occupation || "",
            gross_monthly_income: propsBookingData?.source_of_fund?.gross_monthly_income || "",
            rental_income: propsBookingData?.source_of_fund?.rental_income || "",
            annual_bonus_incentive: propsBookingData?.source_of_fund?.annual_bonus_incentive || "",
            existing_EMI: propsBookingData?.source_of_fund?.existing_EMI || ""
        },
        attachments: {
            developer_receiving: propsBookingData?.attachments?.developer_receiving || "",
            BE_form: propsBookingData?.attachments?.BE_form || "",
            approvals: propsBookingData?.attachments?.approvals || "",
            remarks: propsBookingData?.attachments?.remarks || ""
        }
    };
    const validationFormik = yup.object().shape({
        date_booking: yup.string()
            .required('Date is required*'),
        developer_name: yup.string().required('Develper Name is required*'),
        project_name: yup.string().required('Date is required*'),
        area: yup.string().required('Project Name is required*').matches(/^[0-9\b]+$/, "Only allow numbers"),
        area_type: yup.string().required('Area  type is required*'),
        unit_no: yup.string().required('Unit No is required*'),
        source_fund: yup.string().required('Source of Fund is required*'),
        booking_attachment: yup.string().required('Booking Attachment is required*'),
        pan_card: yup.string()
            .required('Pan number is required')
            .test('is-number', 'Invalid Pan number', (val: any) =>
                /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(val?.toUpperCase())
            ),
        Kyc_attachment: yup.string().required('Kyc Attachment is required*')
    });
    const validationFormikPropertyDetails = yup.object().shape({
        property_details_BSP: yup.object().shape({
            bsp: yup.string().required('BSP is required*'),
            bsp_discount_amount: yup.string().required('BSP Discount Amount is required*'),
        }),
    });
    const phoneRegExp = /^\+?[1-9][0-9]{7,14}$/;
    const addharRegExp = /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
    const validationFormikApplicantDetails = yup.object().shape({
        applicant_details: yup.object().shape({
            applicant: yup.object().shape({
                name: yup.string().required('Applicant Name is required*'),
                address_line1: yup.string().required('Applicant Address Line 1 is required*'),
                city: yup.string().required('Applicant City is required*'),
                state: yup.string().required('Applicant State is required*'),
                country: yup.string().required('Applicant Country is required*'),
                postal_code: yup.string().required('Applicant Postal Code is required*'),
                phone_number1: yup.string().required("Phone Number is required").matches(phoneRegExp, 'Phone number is not valid'),
                email: yup.string().required('Applicant Email is required*'),
                gender: yup.string().required('Applicant Gender is required*'),
                aadhaar_no: yup.string().matches(addharRegExp, 'Addhar number is not valid'),
                pan_no: yup.string()
                    .required('Pan number is required')
                    .test('is-number', 'Invalid Pan number', (val: any) =>
                        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(val?.toUpperCase())
                    )
            }),
        }),
    });
    const validationFormikAdditionalCharges = yup.object().shape({
        // additional_charges: yup.object().shape({
        //     a: yup.string()
        //     .required('a is required*'),
        //     b: yup.string()
        //     .required('b is required*'),
        //     c: yup.string()
        //     .required('c is required*'),
        //     d: yup.string()
        //     .required('d is required*'),
        //     e: yup.string()
        //     .required('e is required*'),
        //     f: yup.string()
        //     .required('f is required*'),
        //     g: yup.string()
        //     .required('g is required*'),
        //     h: yup.string()
        //     .required('h is required*'),
        //     i: yup.string()
        //     .required('i is required*'),
        //     a_amount: yup.string()
        //     .required('a amount is required*'),
        //     b_amount: yup.string()
        //     .required('b amount is required*'),
        //     c_amount: yup.string()
        //     .required('c amount is required*'),
        //     d_amount: yup.string()
        //     .required('d amount is required*'),
        //     e_amount: yup.string()
        //     .required('e amount is required*'),
        //     f_amount: yup.string()
        //     .required('f amount is required*'),
        //     g_amount: yup.string()
        //     .required('g amount is required*'),
        //     h_amount: yup.string()
        //     .required('h amount is required*'),
        //     i_amount: yup.string()
        //     .required('i amount is required*'),
        //     total_additional_charges: yup.string()
        //     .required('Total Amount is required*')
        // }),
    });
    const validationFormikConsolidatedCosting = yup.object().shape({
        consolidated_costing: yup.object().shape({
            gst_amount: yup.string()
                .required('GST Amount is required*'),
            minimum_booking_amount: yup.string()
                .required('Minimum Booking Amount is required*'),
        }),
    });
    const validationFormikPaymentPlan = yup.object().shape({
        source_of_fund: yup.object().shape({
            funding_source: yup.string()
                .required('Funding Source is required*'),
            Occupation: yup.string()
                .required('Occupation is required*'),
            gross_monthly_income: yup.string()
                .required('Gross monthly Income is required*'),
        }),
    });
    const validationFormikAttachment = yup.object().shape({
        // attachments: yup.object().shape({
        //     developer_receiving: yup.string()
        //     .required("Developer Receiving is required*")
        //     // BE_form: "",
        //     // approvals: "",
        //     // remarks: ""
        // }),
    });

    function _sleep(ms: any) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    // useEffect(()=>{
    //     if(reason!== undefined ){
    //         _submitForm();
    //     }
    // },[reason])

    async function _submitForm(values?: any, actions?: any) {
        // setLoad(true);
        window.scrollTo(0, 0);
        // if (statusKey==="REJECTED" && reason=== undefined || statusKey==="HOLD" && reason=== undefined) {
        //     alert("test");
        //     setOpen(true);
        // }
        let ServerUrlBooking;
        let kycUrlBooking;
        let paymentProofUrl;
        let paymentClearanceProofUrl;
        let developerReceivingUrl;
        let paymentReceiverProofUrl;
        let paymentProofUrlArr: { [key: string]: [] } = {};
        let paymentPaymentClearanceProofUrlArr: { [key: string]: [] } = {};
        let paymentPaymentReceivingProofUrlArr: { [key: string]: [] } = {};
        if (typeof values.booking_attachment === 'object' && values.booking_attachment !== null) {
            ServerUrlBooking = await uploadAttachment(
                `${contactDetails?.contactId || contactDetails?.Id || location.state?.paramsData?.Contact_Id}/${values?.booking_id}`,
                values.booking_attachment,
                'file',
                values.booking_attachment?.name,
            );
        }
        if (typeof values.Kyc_attachment === 'object' && values.Kyc_attachment !== null) {
            kycUrlBooking = await uploadAttachment(
                `${contactDetails?.contactId || contactDetails?.Id || location.state?.paramsData?.Contact_Id}/${values?.booking_id}`,
                values.Kyc_attachment,
                'image',
                values.Kyc_attachment?.name,
            );
        }
        await Promise.all(
            values?.payment_plan?.map(async (list: any, index: any) => {
                if (list.payment__proof !== undefined) {
                    paymentProofUrl = await uploadAttachment(
                        `${contactDetails?.contactId || contactDetails?.Id || location.state?.paramsData?.Contact_Id}/${values?.booking_id}`,
                        list.payment__proof,
                        `paymentProof${index}`,
                        list.payment__proof?.name,
                    );
                    paymentProofUrlArr[index] = paymentProofUrl;

                }
                if (list.payment_clearance_proof !== undefined) {
                    paymentClearanceProofUrl = await uploadAttachment(
                        `${location.state?.paramsData?.Contact_Id || contactDetails?.contactId || contactDetails?.Id}/${values?.booking_id}`,
                        list.payment_clearance_proof,
                        `paymentClearanceProof${index}`,
                        list.payment_clearance_proof?.name,
                    );
                    paymentPaymentClearanceProofUrlArr[index] = paymentClearanceProofUrl;
                }
                if (list.payment_receiving_proof !== undefined) {
                    paymentReceiverProofUrl = await uploadAttachment(
                        `${location.state?.paramsData?.Contact_Id || contactDetails?.contactId || contactDetails?.Id}/${values?.booking_id}`,
                        list.payment_receiving_proof,
                        `paymentReceivingProof${index}`,
                        list.payment_receiving_proof?.name,
                    );
                    paymentPaymentReceivingProofUrlArr[index] = paymentReceiverProofUrl;
                }
            })
        )
        if (values.attachments.developer_receiving !== undefined) {
            developerReceivingUrl = await uploadAttachment(
                `${contactDetails?.contactId || contactDetails?.Id || location.state?.paramsData?.Contact_Id}/${values?.booking_id}`,
                values.attachments.developer_receiving,
                'developerReceiving',
                values.attachments.developer_receiving?.name,
            );
        }
        const { booking_attachment, Kyc_attachment, status, payment_plan, ...objData } = values;
        const bodyData = {
            "organization_id": propsBookingData?.organization_id,
            "uid": props.user.uid,
            "contact_no": propsBookingData?.contact_no,
            "booking_details": {
                ...values,
                modified_time: values?.modified_time ? values?.modified_time : new Date(),
                payment_plan: values.payment_plan?.map((list: any, index: any) => {
                    const { payment_receiving_proof, payment_clearance_proof, payment__proof, ...listObj } = list;
                    return {
                        ...listObj,
                        payment_receiving_proof: paymentPaymentReceivingProofUrlArr[index],
                        payment_clearance_proof: paymentPaymentClearanceProofUrlArr[index],
                        payment__proof: paymentProofUrlArr[index]
                    }
                }),
                attachments: {
                    ...values.attachments,
                    developer_receiving: developerReceivingUrl
                },
                booking_attachment: ServerUrlBooking ? ServerUrlBooking : values.booking_attachment,
                Kyc_attachment: kycUrlBooking ? kycUrlBooking : values.Kyc_attachment,
                status: statusKey,
            }
        }
        await _sleep(1000);
        axios.defaults.headers.common['x-access-token'] =
        props.authToken
        const bookingUpdateRes = await axios.patch(
            url + '/bookings/update',
            bodyData
        );
        if (bookingUpdateRes) {
            setLoad(false);
            dispatcher(
                showSnackbarAction(
                    "Booking Updated!!",
                    "success"
                )
            );
            props.history.push({
                pathname: '/',
            })
        }
        else {
            dispatcher(
                showSnackbarAction(
                    "ReBooking was not Updated!!",
                    "error"
                )
            );
        }
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
    }
    const handleSubmitting = (values: any, actions: any) => {
        if (isLastStep) {
            setLoad(true)
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            window.scrollTo(0, 0);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }
    const containerStyles = { backgroundColor: props.theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const cardContainerStyles = { backgroundColor: props.theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
    const selectInputContainerStyles = { width: "140px", marginRight: "5px" };

    const useHelperTextStyles = makeStyles((theme) => ({
        step_label_root: {
            color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
            "& .MuiStepLabel-label.MuiStepLabel-active": {
                color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
            }
        }
    }));
    const helperTextStyles = useHelperTextStyles();
    const handleErrors = (props: any) => {
        for (let key in props.errors) {
            let isObject = typeof props.errors[key] === 'object' && props.errors[key] !== null;
            if (isObject) {
                for (let anotherKey in props.errors[key]) {
                    let isObject = typeof props.errors[key][anotherKey] === 'object' && props.errors[key][anotherKey] !== null;
                    if (isObject) {
                        for (let keyAgain in props.errors[key][anotherKey]) {
                            dispatcher(
                                showSnackbarAction(
                                    props.errors[key][anotherKey][keyAgain],
                                    "error"
                                )
                            );
                        }
                    } else {
                        dispatcher(
                            showSnackbarAction(
                                props.errors[key][anotherKey],
                                "error"
                            )
                        );
                    }
                }
            } else {
                dispatcher(
                    showSnackbarAction(
                        props.errors[key],
                        "error"
                    )
                );
            }
        }
    }
    return (
        <>
            <React.Fragment>
                <div style={{ width: '100%', padding: "20px", height: "fit-content", minHeight: "100%", ...containerStyles,maxHeight:"500px", overflowX:"hidden",overflowY:"scroll",marginBottom:"20px" }}>
                    <div style={{
                        padding: "20px",
                        ...cardContainerStyles
                    }}>
                        <Stepper style={{ ...cardContainerStyles }} activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel classes={{ label: helperTextStyles.step_label_root }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <></>
                        ) : (
                            <Formik
                                initialValues={initialValues}
                                validationSchema={activeStep == 0 ? validationFormik : activeStep == 1 ? validationFormikPropertyDetails : activeStep == 2 ? validationFormikApplicantDetails : activeStep == 3 ? validationFormikAdditionalCharges : activeStep == 4 ? validationFormikConsolidatedCosting : activeStep == 5 ? validationFormikPaymentPlan : validationFormikAttachment}
                                enableReinitialize
                                onSubmit={async (values, actions) => {
                                    actions.setSubmitting(true);
                                    await handleSubmitting(values, actions);
                                    actions.setSubmitting(false);
                                }}
                            >
                                {FormikProps => (
                                    <Form autoComplete="off" style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        ...cardContainerStyles,
                                        marginTop: "2.5rem",
                                    }} noValidate onSubmit={
                                        (e) => {
                                            e.preventDefault();
                                            FormikProps.handleSubmit();
                                            handleErrors(FormikProps);
                                        }
                                    } >
                                        {load === true && <Loading subtext={true} />}
                                        {_renderStepContent(activeStep, FormikProps, projectsList, projectDeveloperObj, setBookingAttechment, setKycAttechment)}
                                        <div
                                            className={classes.buttons}
                                        >
                                            {activeStep !== 0 && (
                                                <Button onClick={_handleBack}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                            )}
                                            <div
                                                className={classes.wrapper}
                                            >
                                                {isLastStep ? <>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ padding: "5px", margin: "5px", backgroundColor: "#D51E1E" }}
                                                        className={classes.button}
                                                        onClick={() => setStatusKey("REJECTED")}
                                                    >
                                                        Rejected
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ padding: "5px", margin: "5px", backgroundColor: "FE7E13" }}
                                                        className={classes.button}
                                                        onClick={() => setStatusKey("HOLD")}
                                                    >
                                                        Hold
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ padding: "5px", margin: "5px", backgroundColor: "#4CCC47" }}
                                                        className={classes.button}
                                                        onClick={() => setStatusKey("ACCEPTED")}
                                                    >
                                                        Accept
                                                    </Button>
                                                </> : <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ padding: "5px", margin: "5px" }}
                                                    className={classes.button}
                                                >
                                                    Next
                                                </Button>}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </React.Fragment>
                </div>
            </React.Fragment>
            {open && <FormDialog
                openDialog={open}
                setOpenDialog={setOpen}
                userDetails={props.user}
                history={props.history}
                bookingType={"EDIT"}
                setReason={setReason}
            />}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode,
        authToken:state.user.authToken,
        bookingHeader: state.bookingHearderList.data,
    };
};
export default connect(mapStateToProps)(EditBooking);