import React, { useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import { fetchOrganizations } from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import {BOOKINGFORM_COLUMNS} from "../../Values/tables";
import { connect, useDispatch } from "react-redux";
import { filterStatus, searchOrganizationItem } from "../../Values/utils";
import styles from "../Analytics/Analytics.module.css";
import CustomTable from "../../Components/CustomTable/CustomTable";
import axios from "axios";
import { url } from "../../Values/constants";
import { themeColors } from "../../Components/theme";

const Booking = ({ history, theme }: any) => {
  const [organizationData, setOrganizationData] = useState<any[] | undefined>(
    undefined
  );
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    getDefaultBookingHeaders();
  }, []);

  useEffect(() => {
    if (organizationData !== undefined) {
      setFilterData(organizationData);
    }
  }, [organizationData, dispatcher]);

  useEffect(() => {
    if (status === "ALL") {
      if (organizationData) {
        const data = searchOrganizationItem(organizationData, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchOrganizationItem(temporaryData, searchQuery);
      setFilterData(data);
    }
  }, [searchQuery, organizationData]);

  useEffect(() => {
    if (searchQuery === "") {
      if (organizationData) {
        const data = filterStatus(organizationData, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
  }, [status, organizationData]);

  const getDefaultBookingHeaders = async () => {
    setLoad(true);
    try {
      const res = await axios.get(url + "/bookings/getBookingFrom");
      let resSortData = res.data.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      setOrganizationData(resSortData);
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };
  console.log("organizationData",organizationData);
  const topBarStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    width: "97%",
  };
  const parentStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
  };
  return (
    <>
      <div
        className={styles.parent}
        style={{ display: "block", marginTop: "4%", ...parentStyles }}
      >
        {load === true && <Loading />}
        <div
          className={commonStyle.topBar}
          style={{ marginTop: "0px", ...topBarStyles }}
        >
          <TopBar
            history={history}
            title={"Add Form"}
            path={"addForm"}
            onChange={(val) => setsearchQuery(val)}
            setStatus={(status) => setStatus(status)}
            filterText="Status"
            status={status}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={true}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          <CustomTable
            tableColumns={BOOKINGFORM_COLUMNS}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="Booking From Header"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            setSearchedItem={(val) => setsearchQuery(val)}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
  };
};
export default connect(mapStateToProps)(Booking);
