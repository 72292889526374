import React, { FunctionComponent, useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import SuperAdminPanel from "../Screens/SuperAdminPanel/SuperAdminPanel";
import Reports from "../Screens/Reports/Reports";
import AddOrganization from "../Screens/AddOrganization/AddOrganization";
import AddNewOrganization from "../Screens/AddOrganization/AddNewOrganization";
import { Route } from "react-router-dom";
// import EditOrganization from "../Screens/EditOrganization/EditNewOrganization";
import EditOrganization from "../Screens/EditOrganization/EditOrganization";
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import Headers from "../Screens/Headers/HeaderPanel";
import AddHeaders from "../Screens/AddHeaders/AddHeaders";
import EditHeaders from "../Screens/EditHeaders/EditHeaders";
import LeadManagerAnalytics from "../Screens/Analytics/LeadManagerAnalytics";
import { fetchOrganizationsUsersAuthId } from "../Services/users";
import { connect, useDispatch } from "react-redux";
import AddLicensesCost from "../Screens/AddLicensesCost/AddLicensesCost";
import UserList from "../Screens/UserList/UserList";
import AddUsers from "../Screens/AddUsers/AddUsers";
import FAQPanel from "../Screens/SuperAdminFAQPanel/FAQPanel"
import AdminAddFAQ from "../Screens/AddFAQ/AdminAddFAQ";
import AdminEditFAQ from "../Screens/EditFAQ/AdminEditFAQ ";
import NewsPanel from "../Screens/NewsPanel/NewsPanelSuperAdmin";
import ResourcesSuperAdmin from "../Screens/Resources/ResourcesSuperAdmin";
import Booking from "../Screens/BookingForm/Booking";
import AddBooking from "../Screens/BookingForm/AddBooking";
import CouponList from "../Screens/CouponList/CouponList";
import AddCoupon from "../Screens/AddCoupon/AddCoupon";


type props = {
  user: any;
  history: any;
  leadsStage: any;
  organizationUsers: any;
  showDeactivationAlert: any;
  paymentStatus: any;
};

const SuperAdminRoute: FunctionComponent<props> = ({
  history,
  user,
  leadsStage,
  organizationUsers,
  showDeactivationAlert,
  paymentStatus
}) => {
  const dispatcher = useDispatch();

  useEffect(() => {
    const localStoreOrgObj= localStorage.getItem("superAdminSelectedOrg");
    if(localStoreOrgObj!= null){
      const covertObj=JSON.parse(localStoreOrgObj);
      const orgAuthId=covertObj.value;

      if (orgAuthId) {
        const unSub = fetchOrganizationsUsersAuthId(
          dispatcher,
          orgAuthId,
          user
        );
        return () => {
          unSub();
        };
      }
    }
  }, [user,localStorage.getItem("superAdminSelectedOrg")]);

  return (
    <>
      <Navbar
        props={{ history }}
        title={"Organizations"}
        path={"/"}
        leadManger={false}
      />
      <div className={styles.parent} style={{overflow:"hidden",height:"100vh"}}>
        <AnalyticsSideNav
          props={{ history }}
          title={"Organizations"}
          path={"/"}
          leadManger={false}
          operationManger={false}
        />
        <Route exact path="/" component={SuperAdminPanel} />
        <Route exact path="/userlist" component={UserList} />
        <Route path="/addUsers" component={AddUsers} />
        {/* <Route path="/analytics" component={Analytics} />
            <Route path="/reports" component={Reports} /> */}
        <Route path="/addHeaders" component={AddHeaders} />
        <Route path="/headers" component={Headers} />
        <Route path="/editOrganizations" component={EditOrganization} />
        <Route path="/editHeaders" component={EditHeaders} />
        {/* <Route path="/analytics" component={Analytics} /> */}
        <Route path="/analytics" component={LeadManagerAnalytics} />
        {/* <Route path="/reports" component={Reports} /> */}
        <Route path="/addOrganizations" component={AddOrganization} />
        {/* <Route path="/addOrganizations" component={AddNewOrganization} /> */}
        <Route path="/addCost" component={AddLicensesCost} />
        <Route path="/couponlist" component={CouponList} />
        <Route path="/addcoupon" component={AddCoupon} />
        <Route path="/bookingForm" component={Booking} />
        <Route path="/addForm" component={AddBooking} />
        <Route path="/editForm" component={AddBooking} />
        <Route path="/faq" component={FAQPanel} />
        <Route path="/addFAQ" component={AdminAddFAQ} />
        <Route path="/editFAQ" component={AdminEditFAQ} />
        <Route path="/news" component={NewsPanel} />
        <Route path="/resources" component={ResourcesSuperAdmin} />
      </div>

    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
    organizationUsers: state.organizationUsers.data,
    showDeactivationAlert: state.organization.showDeactivationAlert,
    paymentStatus: state.organization.paymentStatus
  };
};
export default connect(mapStateToProps)(SuperAdminRoute);
