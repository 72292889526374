import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
  contactResources,
} from "../../Services/contacts";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from 'formik';
import './booking.style.css';
import PreviewIcon from '@mui/icons-material/Preview';
import { FileUpload } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { DropzoneDialog } from 'material-ui-dropzone'
import { green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { uploadAttachment } from "../../Services/booking";
import { useLocation } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import DownloadIcon from '@mui/icons-material/Download';
import { themeColors } from "../../Components/theme";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import { showSnackbarAction } from "../../Redux/actions";

const arrMeasure = [
  {
    value: 'Sq. Yards',
    label: 'Sq. Yards'
  },
  {
    value: 'Sq. Meter',
    label: 'Sq. Meter'
  },
  {
    value: 'Sq. Feet',
    label: 'Sq. Feet'
  },
  {
    value: 'Acres',
    label: 'Acres'
  },
  {
    value: 'Bigha',
    label: 'Bigha'
  },
  {
    value: 'Gaj',
    label: 'Gaj'
  },
  {
    value: 'Killa',
    label: 'Killa'
  },
  {
    value: 'Gunta',
    label: 'Gunta'
  },
  {
    value: 'Kanal',
    label: 'Kanal'
  },
  {
    value: '40:60',
    label: '40:60'
  },
];
interface MyFormValues {
  date_booking: string,
  developer_name: string,
  project_name: string,
  area: string,
  unit_no: string,
  source_fund: string,
  scheme: string,
  booking_attachment: string,
  pan_card: string,
  area_type: string,
  Kyc_attachment: string
}

type props = {
  history: any;
  user: any;
  theme: any;
  authToken:any;
  bookingHeader:any;
};
const sourceFund = ["Self Funding", "Self Loan Process", "Loan Stone"];
const AddBooking = (props: props & FormikProps<MyFormValues>) => {
  const CssTextField: any = styled(TextField)({
    fontSize: "0.8rem",
    borderRadius: "6px"
  });
  const dispatcher = useDispatch();
  const location: any = useLocation();
  const [load, setLoad] = useState(false);
  const [locationsList, setLocationsList] = useState<any[]>(
    []
  );
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>(
    []
  );
  const [projectDeveloperObj, setProjectDeveloperObj] = useState<any[]>(
    []
  );
  const [bookingAttechment, setBookingAttechment] = useState<any>();
  const [kycAttechment, setKycAttechment] = useState<any>();
  const [contactDetails, setContactsDetails] = useState<any>();
  const [notesDetails, setNotesDetails] = useState<any[]>([]);
  const [attachmentDetails, setAttachmentsDetails] = useState<any[]>([]);
  const [callLogsDetails, setCallLogsDetails] = useState<any[]>([]);
  const [bookingType, setBookingType] = useState<any>();
  const [reBookingData, setReBookingData] = useState<any>();
  const [serverUrlBooking, setServerUrlBooking] = useState<any>();
  const [serverUrlKyc, setServerUrlKyc] = useState<any>();
  const [action, setAction] = useState<any>();
  const [paramsValue, setParamsValues] = useState<any>();
  const [bookingAttachmentsDetails, setBookingAttachmentsDetails] = useState<any>({});
  const [declaration,setDeclaration] = useState(false);
   const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.8); // Set an initial value, adjust as needed
  
    const handleResize = () => {
      // Update maxHeight when the window is resized
      setMaxHeight(window.innerHeight * 0.8); // Adjust the multiplier as needed
    };
  
    useEffect(() => {
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


  useEffect(() => {
    setContactsDetails(location.state?.Contact_details);
    setNotesDetails(location.state?.notesList);
    setAttachmentsDetails(location.state?.attachmentsList);
    setCallLogsDetails(location.state?.callLogsList);
    setBookingType(location.state?.booking_type);
    setReBookingData(location.state?.reBookingData);
    setAction(location.state?.key);
    setParamsValues(location.state?.data);
  }, [location.state])

  const initialValues: MyFormValues = {
    date_booking: moment(paramsValue?.date_booking).format("YYYY-MM-DD") || moment(new Date()).format("YYYY-MM-DD"),
    developer_name: paramsValue?.developer_name || '',
    project_name: paramsValue?.project_name || '',
    area: paramsValue?.area || '',
    unit_no: paramsValue?.unit_no || '',
    source_fund: paramsValue?.source_fund || '',
    scheme: paramsValue?.scheme || '',
    area_type: paramsValue?.area_type || '',
    booking_attachment: paramsValue?.booking_attachment || '',
    pan_card: paramsValue?.pan_card || '',
    Kyc_attachment: paramsValue?.Kyc_attachment || ''
  };

  let validationFormik 
  {declaration===false?validationFormik= yup.object().shape({
    date_booking: yup.string()
      .required('Date is required*'),
    developer_name: yup.string().required('Develper Name is required*'),
    project_name: yup.string().required('Project is required*'),
    area: yup.string().required('area Name is required*').matches(/^[0-9\b]+$/, "Only allow numbers"),
    area_type: yup.string().required('Measure type is required*'),
    unit_no: yup.string().required('Unit No is required*'),
    source_fund: yup.string().required('Source of Fund is required*'),
    booking_attachment: yup.string().required('Booking Attachment is required*'),
    pan_card: yup.string()
      .required('Pan number is required')
      .test('is-number', 'Invalid Pan number', (val: any) =>
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(val?.toUpperCase())
      ),
    Kyc_attachment: yup.string().required('Kyc Attachment is required*'),
    
    declaration: yup.boolean().required('declaration is required*'),
    
  }):validationFormik= yup.object().shape({
    date_booking: yup.string()
      .required('Date is required*'),
    developer_name: yup.string().required('Develper Name is required*'),
    project_name: yup.string().required('Project is required*'),
    area: yup.string().required('area Name is required*').matches(/^[0-9\b]+$/, "Only allow numbers"),
    area_type: yup.string().required('Measure type is required*'),
    unit_no: yup.string().required('Unit No is required*'),
    source_fund: yup.string().required('Source of Fund is required*'),
    booking_attachment: yup.string().required('Booking Attachment is required*'),
    pan_card: yup.string()
      .required('Pan number is required')
      .test('is-number', 'Invalid Pan number', (val: any) =>
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(val?.toUpperCase())
      ),
    Kyc_attachment: yup.string().required('Kyc Attachment is required*'),
  });}
  useEffect(() => {
    const organization_id = props.user?.organization_id;
    const unsub = contactResources(
      organization_id,
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
      (data) => setLeadSourceList(data),
      (data) => setProjectDeveloperObj(data),
    )
    return () => {
      unsub();
    };
  }, [props]);

  const handleChangeFile = async (type: any, data: any) => {
    type === 'file' ? setBookingAttechment(data[0]) : setKycAttechment(data[0]);
  }
  const handleBooking = async (values: any) => {
    window.scrollTo(0, 0);
    setLoad(true)
    const { booking_attachment, Kyc_attachment, booking_id, ...objValue } = values;
    const bookingId = uuidv4();
    let ServerUrlBooking;
    let kycUrlBooking;
    if (bookingAttechment !== undefined) {
      ServerUrlBooking = await uploadAttachment(
        `${contactDetails?.contactId}/${bookingId}`,
        bookingAttechment,
        'file',
        bookingAttechment?.name,
      );
    }
    if (kycAttechment !== undefined) {
      kycUrlBooking = await uploadAttachment(
        `${contactDetails?.contactId}/${bookingId}`,
        kycAttechment,
        'image',
        kycAttechment?.name,
      );
    }
    if (ServerUrlBooking !== undefined && kycUrlBooking !== undefined) {
      if (bookingType === "REBOOKING") {
        const bodyData = {
          organization_id: reBookingData.organization_id,
          uid: reBookingData.uid,
          contact_no: reBookingData.contact_no,
          booking_details: {
            ...objValue,
            booking_id: bookingId,
            booking_attachment: ServerUrlBooking,
            Kyc_attachment: kycUrlBooking,
            declaration:declaration,
            status: "PENDING"
          }
        }
        axios.defaults.headers.common['x-access-token'] =
props.authToken
        const bookingUpdateRes = await axios.patch(
          url + '/bookings/update',
          bodyData
        );
        if (bookingUpdateRes) {
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "ReBooking Created!!",
              "success"
            )
          );
          props.history.push('/booking');
        }
        else {
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "ReBooking was not Created!!",
              "error"
            )
          );
        }
      }
      else {
        const {
          organization_id,
          uid,
          contact_no,
          reporting_to,
          branch,
          project,
          location,
          team,
          ...ObjContactsDetails
        } = contactDetails;
        const bodyData = {
          organization_id,
          uid,
          contact_no,
          reporting_to,
          branch,
          team,
          location,
          project,
          contact_details: ObjContactsDetails,
          notes: notesDetails,
          attachments: attachmentDetails,
          call_logs: callLogsDetails,
          booking_details: {
            ...objValue,
            booking_id: bookingId,
            booking_attachment: ServerUrlBooking,
            Kyc_attachment: kycUrlBooking,
            declaration:declaration,
            status: "PENDING"
          }
        }
        axios.defaults.headers.common['x-access-token'] =
props.authToken
        const bookingCreateRes = await axios.post(
          url + '/bookings/create',
          bodyData
        );
        if (bookingCreateRes) {
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Booking Created!!",
              "success"
            )
          );
          props.history.push('/booking');
        }
        else {
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Booking was not Created!!",
              "error"
            )
          );
        }
      }
    }
  }

  const containerStyles = { backgroundColor: props.theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const cardContainerStyles = { backgroundColor: props.theme ? themeColors.cardBackgroundColor : themeColors.cardBackgroundColorDark, color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };
  const useHelperTextStyles = makeStyles((theme) => ({
    inputRoot: {
      // "&$disabled": {
      //   color: "green"
      // }
    },
    disabled: {
    },
    root: {
      "& .MuiFormLabel-root": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiFormLabel-root.Mui-disabled": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSelect-select": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiSelect-select.Mui-disabled": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiInputBase-input": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
        "-webkit-text-fill-color": `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiOutlinedInput-input": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
      "& .MuiOutlinedInput-input.Mui-disabled": {
        color: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
      },
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: `${props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
    },
    preview: {
      color: "black"
    },
    dropzone: {
      color: "black"
    },
    formHelperText: {
      // width:"300px",
      // height:"200px",
      // marginRight:"50px",
      // marginBottom:"20px",
      // backgroundColor:"black"
    },
  }));
  const helperTextStyles = useHelperTextStyles();

  const handleErrors = (props: any) => {
    for (let key in props.errors) {
      dispatcher(
        showSnackbarAction(
          props.errors[key],
          "error"
        )
      );
    }
  }
  
  return (
    <>
      <div style={{...containerStyles,marginTop:40,maxHeight:`${maxHeight}`, overflowX:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        {load === true && <Loading />}
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationFormik}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleBooking(values);
            actions.setSubmitting(false);
          }}
        >
          {FormikProps => (
            <Form autoComplete="off" style={{
              width: "100%",
              padding: "20px",
              borderRadius: "10px",
              paddingTop: "20px",
              marginTop: "20px",
              ...cardContainerStyles
            }} noValidate onSubmit={
              (e) => {
                e.preventDefault();
                FormikProps.handleSubmit();
                handleErrors(FormikProps);
              }
            }>

              <div className="add-booking" style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "20px",
              }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                  <Grid item xs={3}>
                    <CssTextField
                      type="date"
                      fullWidth
                      label={props.bookingHeader[0]?.date_booking || "Date of Booking"}
                      required
                      size="small"
                      disabled
                      InputLabelProps={{ shrink: true, required: true }}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('date_booking')}
                      defaultValue={moment(FormikProps.values.date_booking).format("YYYY-MM-DD")}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline,
                          root: helperTextStyles.inputRoot,
                          disabled: helperTextStyles.disabled
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      select
                      label={props.bookingHeader[0]?.project_name || "Project Name"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('project_name')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    >
                      {projectDeveloperObj.map(option => (
                        <MenuItem key={option.project_name} value={option.project_name}>
                          {option.project_name}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      select
                      label={props.bookingHeader[0]?.developer_name || "Developer Name"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('developer_name')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    >

                      {projectDeveloperObj?.map(option => (
                        <MenuItem key={option.developer_name} value={option.developer_name}>
                          {option.developer_name}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      select
                      label={props.bookingHeader[0]?.source_fund || "Source of Fund"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('source_fund')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    >
                      {sourceFund?.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      label={props.bookingHeader[0]?.unit_no || "Unit Number"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('unit_no')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      label={props.bookingHeader[0]?.pan_card || "PAN Number"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('pan_card')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline,
                        }
                      }}
                      FormHelperTextProps={{
                        classes: {
                          root: helperTextStyles.formHelperText
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      label={props.bookingHeader[0]?.area || "Area"}
                      required
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('area')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CssTextField
                      type="text"
                      fullWidth
                      label={props.bookingHeader[0]?.area_type || "Measure"}
                      required
                      select
                      size="small"
                      disabled={action === "view" ? true : false}
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('area_type')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    >
                      {arrMeasure.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Grid>
                  <Grid item xs={8}>
                    <CssTextField
                      type="text"
                      fullWidth
                      label={props.bookingHeader[0]?.scheme || "Scheme if Any"}
                      required
                      disabled={action === "view" ? true : false}
                      size="small"
                      id="custom-css-outlined-input"
                      {...FormikProps.getFieldProps('scheme')}
                      className={helperTextStyles.root}
                      InputProps={{
                        classes: {
                          notchedOutline: helperTextStyles.notchedOutline
                        }
                      }}
                      sx={{ input: { color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {action === "view" ? <>  <div
                    >
                      <button
                        className="addBox"
                        onClick={() => window.open(FormikProps.values.booking_attachment)}
                      >
                        <PreviewIcon />
                        {`View ${props.bookingHeader[0]?.booking_attachment}`}
                      </button>
                    </div></> : <>
                      <span style={{ color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>{`Upload your ${props.bookingHeader[0]?.booking_attachment || "Booking Attachment"}`}</span>
                      <DropzoneArea
                        dropzoneParagraphClass="dropzone-text"
                        filesLimit={1}
                        showAlerts={true}
                        showPreviews={true}
                        dropzoneClass={helperTextStyles.dropzone}
                        previewGridClasses={{
                          item: helperTextStyles.preview,
                        }}
                        showPreviewsInDropzone={false}
                        acceptedFiles={['text/csv', 'application/pdf']}
                        maxFileSize={80000000000}
                        initialFiles={FormikProps.values.booking_attachment ? [FormikProps.values.booking_attachment] : []}
                        onDrop={(file) => {
                          FormikProps.setFieldValue("booking_attachment", file[0]);
                          handleChangeFile("file", file);
                        }}
                        onDelete={() => FormikProps.setFieldValue("booking_attachment", '')}
                      />
                    </>}
                  </Grid>
                  <Grid item xs={6}>
                    {action === "view" ? <> <div>
                      <div
                      >
                        <button
                          className="addBox"
                          onClick={() => window.open(FormikProps.values.Kyc_attachment)}
                        >
                          <PreviewIcon />
                          {`View ${props.bookingHeader[0]?.Kyc_attachment}`}
                        </button>
                      </div>
                    </div></> :
                      <>
                        <span style={{ color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark }}>{`Upload your ${props.bookingHeader[0]?.Kyc_attachment || "KYC Attachment"}`}</span>
                        <DropzoneArea
                          dropzoneParagraphClass="dropzone-text"
                          filesLimit={1}
                          showAlerts={true}
                          showPreviews={true}
                          dropzoneClass={helperTextStyles.dropzone}
                          previewGridClasses={{
                            item: helperTextStyles.preview,
                          }}
                          showPreviewsInDropzone={false}
                          acceptedFiles={['image/jpeg', 'image/png']}
                          initialFiles={FormikProps.values.Kyc_attachment ? [FormikProps.values.Kyc_attachment] : []}
                          onDrop={(file) => {
                            FormikProps.setFieldValue("Kyc_attachment", file[0]);
                            handleChangeFile("photo", file);
                          }}
                          onDelete={() => FormikProps.setFieldValue("Kyc_attachment", '')}
                        />
                      </>}
                  </Grid>
                  {action === "view" ? null :<div style={{marginLeft:"16px",marginTop:"10px",marginBottom:"10px"}}>
                      <input
                        style={{marginLeft:"16px",marginTop:"10px",marginBottom:"10px"}}
                        type="checkbox"
                        onChange={() => setDeclaration(!declaration)}
                        checked={declaration}
                        // {...FormikProps.getFieldProps('declaration')}
                      />
                      <span style={{ color: props.theme ? themeColors.textPrimary : themeColors.textPrimaryDark ,marginLeft:10 }}>I hereby give my assurance to the concerned Organization that I have thoroughly investigated the booking details including the costing and property details of this client. I have also ensured all the necessary documents such as booking form, costing, Aadhaar card, and pan card are duly signed and attested by the customer himself/herself before uploading the documents in the system and submitting the record. I hereby take complete responsibility for this record in case of any dispute.</span>
                      </div>}
                  {action === "view" ? null : <>
                    <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
                      <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ marginTop: "20px", background: "#272944", width: "15%" }}
                      // pending={FormikProps.isSubmitting}
                      // disabled={!(FormikProps.dirty && FormikProps.isValid)}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </>}
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
    authToken:state.user.authToken,
    bookingHeader: state.bookingHearderList.data,
  };
};
export default connect(mapStateToProps)(AddBooking);