import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import Firebase from "firebase/app";
import { firestore, functions } from "../../Firebase";
import styles from "./SignUp.module.css";
import Logo from "../../Assets/Images/readPro_logo.png";
import SignInTextInput from "../../Components/SignInTextInput/SignInTextInput";
import PasswordInput from "../../Components/Password/PasswordInput";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInFirebase } from "../../Services/auth";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import Logo2 from "../../Assets/Images/ify_logo2.png";
import LeadRubixLogo from "../../Assets/Images/leadrubixlogo.png";
import LogoOld from "../../Assets/Images/head.svg";
import Cookies from "universal-cookie";
import LatestBackground from "../../Assets/Images/signin_background_latest.svg";
import AppStore from "../../Assets/Images/appstore.svg";
import PlayStore from "../../Assets/Images/playstore.svg";
import background from "../../Assets/Images/backgrounrsignup.jpg";
import Select from "react-select";
import { countryData, url } from "../../Values/constants"
import { createOrganization } from "../../Services/organizations";
import { emailValidate, nameValidate, noValidate, phoneValidate, pincodeValidate } from "../../Values/validatorsnew";
import TextInput from "./TextInput/TextInput";
import { showSnackbarAction } from "../../Redux/actions";
import { auth } from "../../Firebase";
import { addDays, generatePassword } from "../../Values/utils";
import axios from "axios";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchLicensesCost } from "../../Services/resources";

type props = {
  history: any;
};

const SignUp: FunctionComponent<props> = ({ history }) => {
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [state, setState] = useState<object>({});
  const [industry, setIndustry] = useState<object>({});
  const [booleanSendEmail, setBooleanSendEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [phoneCode, setPhoneCode] = useState();
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [country, setCountry] = useState<object>({});
  const [orgId, setOrgId] = useState();
  const [authId, setAuthId] = useState();
  const [cost, setCost] = useState<any>("");
  const [noEmp, setNoEmp] = useState<any>("");
  const [costLicensesData, setCostLicensesData] = useState<any[] | undefined>(undefined);

  const [cities, setCities] = useState<object>({});
  const organizationNameRef: any = useRef("");
  const mobileNumberRef: any = useRef("");
  const emailRef: any = useRef("");
  const addressRef: any = useRef("");
  // const industryRef: any = useRef("");
  const pincodeRef: any = useRef("");
  const adminFirstNameRef: any = useRef("");
  const adminLastNameRef: any = useRef("");
  const adminContactNumberRef: any = useRef("");
  const adminEmailRef: any = useRef("");
  const employeesRef: any = useRef("");

  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState('');
  const [adminPhoneCountryCode, setAdminPhoneCountryCode] = useState('in');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminFinalPhoneNumber, setAdminFinalPhoneNumber] = useState('');

  const handlePhoneChange = (value: string, country: CountryData) => {
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
    setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  };
  const adminHandlePhoneChange = (value: string, country: CountryData) => {
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setAdminFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setAdminFinalPhoneNumber(value);
    }
    setAdminPhoneCountryCode(country?.dialCode);
    setAdminPhoneNumber(value)
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  useEffect(() => {
    fetchLicensesCost((data) => setCostLicensesData(data));
  }, [])

  useEffect(() => {
    setCost(costLicensesData?.[0]?.licensesCost);
    setNoEmp(costLicensesData?.[0]?.no_employees);
  }, [costLicensesData]);

  const create = (e: any) => {
    e.preventDefault();
    if (organizationNameRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Organization Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (employeesRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "No of Employees Required!!",
          "error"
        )
      );
      return;
    }

    else if (emailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Email Required!!",
          "error"
        )
      );
      return;
    }
    else if (addressRef.current.value === "") {
      dispatcher(
        showSnackbarAction(
          "Address Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(country).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Country Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(state).length === 0) {
      dispatcher(
        showSnackbarAction(
          "State Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(cities).length === 0) {
      dispatcher(
        showSnackbarAction(
          "City Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(industry).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Industry Id Required!!",
          "error"
        )
      );
      return;
    }
    else if (finalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (pincodeRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Pincode Required!!",
          "error"
        )
      );
      return;
    }

    else if (adminFirstNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminLastNameRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Last Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminFinalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminEmailRef.current?.value === "") {
      dispatcher(
        showSnackbarAction(
          "Admin Email Required!!",
          "error"
        )
      );
      return;
    }


    setLoad(true);

    createOrganization(
      organizationNameRef.current?.value,
      finalPhoneNumber,
      phoneCountryCode == "in" ? 91 : parseInt(phoneCountryCode),
      emailRef.current?.value,
      addressRef.current?.value,
      (country as any).label,
      (country as any).value,
      (state as any).label,
      (state as any).value,
      (cities as any).label,
      (cities as any).value,
      pincodeRef.current?.value,
      adminFirstNameRef.current?.value,
      adminLastNameRef.current?.value,
      adminFinalPhoneNumber,
      adminPhoneCountryCode == "in" ? 91 : parseInt(adminPhoneCountryCode),
      adminEmailRef.current?.value,
      employeesRef.current?.value,
      history,
      dispatcher,
      window,
      (data: boolean) => setLoad(data),
      (data: boolean) => setBooleanSendEmail(data),
      (data: any) => setPwd(data),
      (industry as any).value,
      (data: any) => setOrgId(data),
      (data: any) => setAuthId(data),
      cost,
      noEmp
    );
  };
  useEffect(() => {
    if (booleanSendEmail) {
      sendResetPasswordEmail();
      createOrganizationDb();
    }
  }, [booleanSendEmail]);

  // useEffect(()=>{
  //     let currentDate = new Date();
  //     let validTill=getFormattedDateDays();
  //     console.log("dsakdasjkd",currentDate);
  //     console.log("dsakdasjkd",validTill);
  // },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryRes = await axios.post(
          `${url}/country/countries`);
        // handle response
        setCountryList(countryRes.data);
      } catch (error) {
        // handle error
      }
    };
    fetchData();
  }, [])

  useEffect(() => {
    if (Object.keys(country).length !== 0) {
      const fetchData = async () => {
        try {
          const statesRes = await axios.post(
            `${url}/country/states`, { iso3: (country as any).value });
          // handle response
          setStatesList(statesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();

    }
  }, [country]);

  // useEffect(() => {
  //   if (country !== "") {
  //     const phonecode = countryList.find((list: any) => list?.iso3 === country);
  //     setPhoneCode(phonecode);
  //   }
  // }, [country]);

  useEffect(() => {
    if (Object.keys(country).length !== 0 && Object.keys(state).length !== 0) {
      const fetchData = async () => {
        try {
          const citiesRes = await axios.post(
            `${url}/country/cities`, { iso3: (country as any).value, state_code: (state as any).value });
          // handle response
          setCitiesList(citiesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }
  }, [country, state])

  const createOrganizationDb = async () => {
    const bodyData = {
      organization_id: orgId,
      address: addressRef.current?.value,
      admin_contact_number: adminFinalPhoneNumber,
      admin_email_id: adminEmailRef.current?.value.toLowerCase(),
      admin_first_name: adminFirstNameRef.current?.value,
      admin_last_name: adminLastNameRef.current?.value,
      auth_id: authId,
      city: (cities as any).label,
      country: (country as any).label,
      created_at: "",
      designations: [],
      email_id: emailRef.current?.value.toLowerCase(),
      mobile_number: finalPhoneNumber,
      no_of_employees: employeesRef.current?.value,
      organization_name: organizationNameRef.current?.value,
      state: (state as any).label,
      status: "ACTIVE",
      teams: [],
      industry_id: (industry as any).value,
      payment_status: true,
      grace_period: 7,
      showData: true,
      trial_period: true,
    }
    const createOrg = await axios.post(
      url + '/organizations/create', bodyData
    );
    console.log("createOrg", createOrg);
  }
  const sendResetPasswordEmail = async () => {
    let email = adminEmailRef.current?.value.toLowerCase()
    let firstName = adminFirstNameRef.current?.value;
    let lastName = adminLastNameRef.current?.value;
    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          const resetPasswordEmailApiData = {
            "template_name": "Password_Reset_Notification",
            "replaceable_params": [
              { "%#username#%": `${firstName} ${lastName}` },
              { "%#password#%": pwd }
            ],
            "email": email
          }
          await axios.post(
            url + '/email/sendEmail',
            resetPasswordEmailApiData
          );
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  }

  const choice = () => {
    let optionArr = statesList?.map((list: { state_code: any, name: any }) => ({
      value: list.state_code,
      label: list.name
    }));
    return optionArr;
  };
  const countryChoice = () => {
    let optionArr = countryList?.map((list: { iso3: any, name: any, phone_code: any }) => ({
      value: list.iso3,
      label: `${list.name}`
      // label: `+${list.phone_code} ${list.name}`
    }));
    return optionArr;
  };
  const citiesChoice = () => {
    let optionArr = citiesList?.map((list: { name: any }) => ({
      value: list.name,
      label: list.name
    }));
    return optionArr;
  };

  useEffect(() => {
    getIndustry();
  }, []);

  const getIndustry = async () => {
    const industryRes = await axios.post(
      url + '/headers/getAllIndustries',
      {
        "status": "Launched"
      }
    );
    setIndustryList(industryRes.data);
  }
  const choiceIndustry = () => {
    let optionArr = industryList?.map((list: { industry_id: any, industry_name: any }) => ({
      value: list.industry_id,
      label: list.industry_name
    }));
    return optionArr;
  }
  const handleCountryChange = (event: { target: { value: any; }; }) => {
    console.log("choosed country :",event.target.value);
    setCountry(JSON.parse(event.target.value));
  };

  const handleStateChange = (event: { target: { value: any; }; }) => {
    console.log("choosed country :",event.target.value);
    setState(JSON.parse(event.target.value));
  };
  const handleCitiesChange = (event: { target: { value: any; }; }) => {
    console.log("choosed country :",event.target.value);
    setCities(JSON.parse(event.target.value));
  };
  const handleIndustryChange = (event: { target: { value: any; }; }) => {
    console.log("choosed country :",event.target.value);
    setIndustry(JSON.parse(event.target.value));
  };

  const noValidateEmp = (empNo: string) => {
    console.log("phone", Number(empNo));
    const re = /^[0-9]*$/;
    const valid = re.test(empNo);
    if (valid) {
      if (Number(empNo) > noEmp) {
        return `Trial period allows ${noEmp} licences.`
      }
      else {
        return "";
      }
    }
    else {
      return "Allow only Number.";
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      className={styles.container}
    >
      {/* <img
        src={LeadRubixLogo}
        alt="logo"
        className={styles.logo} /> */}
      <div style={{ display: "flex", height: "80vh", alignItems: "center", justifyContent: "center" }}>
        {/* <div className={styles.box1}>
          <img
            src={LatestBackground}
            alt=""
            width="100%"
            height="100%"
          />
        </div> */}

        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "auto", flexDirection: "column" }}>
          {/* form and image main block */}
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "auto" }}>
            {/* image block */}
            <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img
                src={LatestBackground}
                alt=""
                width="100%"
                height="100%"
              />
            </div>

            {/* form block */}
            <div style={{ width: "40%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <div className={styles.box2} style={{ background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,28,34,0.87718837535014) 51%, rgba(0,151,255,0.3561799719887955) 100%)" }} >
                <div style={{ display: "flex", width: "100%", height: "80px" }}>
                  <img
                    src={LeadRubixLogo}
                    alt=""
                    width="100%"
                    height="80px"
                  // height="90%"
                  />
                </div>
                <div className={styles.form}>
                  <div className={styles.header}>
                    <h4 style={{ fontFamily: "Poppins", fontWeight: 300, fontSize: "24px", color: "white" }}>Registration Details</h4>
                  </div>
                  {/* my form main block*/}

                  <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "auto", flexWrap: "wrap" }}>
                    {/* input feild block */}
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={" Enter Organization Name *"}
                        style={{ backgroundColor: "#fff" }}
                        ref={organizationNameRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter No of Employees *"}
                        style={{ backgroundColor: "#fff" }}
                        validator={noValidateEmp}
                        ref={employeesRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Email Id *"}
                        style={{ backgroundColor: "#fff" }}
                        ref={emailRef}
                        validator={emailValidate}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Address *"}
                        style={{ backgroundColor: "#fff" }}
                        ref={addressRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <select name="" id="" onChange={handleCountryChange} style={{ width: "100%", height: "75%", borderRadius: "5px", color: "black" }}>
                        <option value="" disabled selected>
                          Select a country
                        </option>
                        {countryChoice().map((country, index) => (
                          <option key={index} value={JSON.stringify(country)}>
                            {country.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <select name="" id="" onChange={handleStateChange} style={{ width: "100%", height: "75%", borderRadius: "5px", color: "black" }}>
                        <option value="" disabled selected>
                          Select a state
                        </option>
                        {choice().map((state, index) => (
                          <option key={index} value={JSON.stringify(state)}>
                            {state.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <select name="" id="" onChange={handleCitiesChange} style={{ width: "100%", height: "75%", borderRadius: "5px", color: "black" }}>
                      <option value="" disabled selected>
                          Select a City
                        </option>
                        {citiesChoice().map((state, index) => (
                          <option key={index} value={JSON.stringify(state)}>
                            {state.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <select name="" id="" onChange={handleIndustryChange} style={{ width: "100%", height: "75%", borderRadius: "5px", color: "black" }}>
                      <option value="" disabled selected>
                          Select a Industries
                        </option>
                        {choiceIndustry().map((indutry, index) => (
                          <option key={index} value={JSON.stringify(indutry)}>
                            {indutry.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <PhoneInput
                        country={phoneCountryCode}
                        value={phoneNumber}
                        placeholder="Contact Number"
                        onChange={handlePhoneChange}
                        inputStyle={{ width: 170 }}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Pincode"}
                        style={{ backgroundColor: "#fff" }}
                        validator={pincodeValidate}
                        ref={pincodeRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Admin First Name"}
                        style={{ backgroundColor: "#fff" }}
                        validator={nameValidate}
                        ref={adminFirstNameRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Admin Last Name"}
                        style={{ backgroundColor: "#fff" }}
                        validator={nameValidate}
                        ref={adminLastNameRef}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <PhoneInput
                        country={adminPhoneCountryCode}
                        value={adminPhoneNumber}
                        placeholder="Contact Number"
                        onChange={adminHandlePhoneChange}
                        inputStyle={{ width: 170 }}
                      />
                    </div>
                    <div style={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "center", height: "45px", margin: "1px 5px" }}>
                      <TextInput
                        title={"Enter Admin Email"}
                        style={{ backgroundColor: "#fff" }}
                        ref={adminEmailRef}
                        validator={emailValidate}
                      />
                    </div>
                    <div className={styles.buttons}>
                      <button
                        className={styles.button}
                        onClick={(e: any) => create(e)}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>



                  {/* <div style={{ width: "100%", display: 'flex', justifyContent: "space-between" }} >
                    <TextInput
                      title={" Enter Organization Name *"}
                      style={{ backgroundColor: "#fff" }}
                      ref={organizationNameRef}
                    />
                    <TextInput
                      title={"Enter No of Employees *"}
                      style={{ backgroundColor: "#fff", paddingLeft: "10px" }}
                      validator={noValidateEmp}
                      ref={employeesRef}
                    />
                  </div>
                  <div style={{ width: "100%", display: 'flex', justifyContent: "space-between" }} >
                    <TextInput
                      title={"Enter Email Id *"}
                      style={{ backgroundColor: "#fff" }}
                      ref={emailRef}
                      validator={emailValidate}
                    />
                    <TextInput
                      title={"Enter Address *"}
                      style={{ backgroundColor: "#fff", paddingLeft: "10px" }}
                      ref={addressRef}
                    />
                  </div>
                  <div style={{ width: "100%", display: 'flex', justifyContent: "space-between", marginTop: "5px", marginLeft: '7px' }}>
                    <div style={{ width: '49%' }}>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: 'solid 1px #ccc',
                            fontSize: 13,
                            outline: 'none',
                            width: '100%',
                            height: '35px',
                          }),
                        }}
                        placeholder="Country *"
                        options={countryChoice()}
                        onChange={(data) => {
                          if (data != null) {
                            setCountry(data);
                          }
                        }}
                        isSearchable={true}
                      />
                    </div>
                    <div style={{ width: '49%' }}>
                      <Select
                        placeholder="State *"
                        options={choice()}
                        onChange={(data) => {
                          if (data != null) {
                            setState(data);
                          }
                        }}
                        isSearchable={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: 'solid 1px #ccc',
                            paddingLeft: 10,
                            fontSize: 13,
                            outline: 'none',
                            width: '100%',
                            height: '35px',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", display: 'flex', justifyContent: "space-between", marginTop: "14px", marginLeft: '7px' }}>
                    <div style={{ width: '48%' }}>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: 'solid 1px #ccc',
                            fontSize: 13,
                            outline: 'none',
                            width: '100%',
                            height: '35px',
                          }),
                        }}
                        placeholder="City *"
                        options={citiesChoice()}
                        onChange={(data) => {
                          if (data != null) {
                            setCities(data);
                          }
                        }}
                        isSearchable={true}
                      />
                    </div>
                    <div style={{ width: '49%' }}>
                      <Select
                        placeholder="Industry Id *"
                        options={choiceIndustry()}
                        onChange={(data) => {
                          if (data != null) { // Check if the value is not null or undefined
                            setIndustry(data); // Set the industry state to the selected value
                          }
                        }}
                        isSearchable={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: 'solid 1px #ccc',
                            paddingLeft: 10,
                            fontSize: 13,
                            outline: 'none',
                            width: '100%',
                            height: '35px',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "baseline", marginTop: "5px", marginLeft: "7px" }}>
                    <div style={{ width: "48%" }}>
                      <PhoneInput
                        country={phoneCountryCode}
                        value={phoneNumber}
                        placeholder="Contact Number"
                        onChange={handlePhoneChange}
                        inputStyle={{ width: 190 }}
                      />
                    </div>
                    <div style={{ width: "49%" }}>
                      <TextInput
                        title={"Enter Pincode"}
                        style={{ backgroundColor: "#fff" }}
                        validator={pincodeValidate}
                        ref={pincodeRef}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", display: 'flex', justifyContent: "space-between" }} >
                    <TextInput
                      title={"Enter Admin First Name"}
                      style={{ backgroundColor: "#fff" }}
                      validator={nameValidate}
                      ref={adminFirstNameRef}
                    />
                    <TextInput
                      title={"Enter Admin Last Name"}
                      style={{ backgroundColor: "#fff", paddingLeft: "10px" }}
                      validator={nameValidate}
                      ref={adminLastNameRef}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "baseline", marginLeft: "7px" }}>
                    <div style={{ width: "49%" }}>
                      <PhoneInput
                        country={adminPhoneCountryCode}
                        value={adminPhoneNumber}
                        placeholder="Contact Number"
                        onChange={adminHandlePhoneChange}
                        inputStyle={{ width: 190 }}
                      />
                    </div>
                    <div style={{ width: "49%" }}>
                      <TextInput
                        title={"Enter Admin Email"}
                        style={{ backgroundColor: "#fff" }}
                        ref={adminEmailRef}
                        validator={emailValidate}
                      />
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.button}
                      onClick={(e: any) => create(e)}
                    >
                      Create Account
                    </button>
                  </div> */}
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div
                      className={styles.forgetPassword}
                      onClick={() => history.push("/")}
                    >
                      <p style={{ marginBottom: "20px", marginTop: "10px", fontSize: "14px", color: "white" }}>Already have an account?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SignUp;
