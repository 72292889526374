import moment from "moment";
import { properFormat } from "./utils";
import { connect,useSelector } from 'react-redux';
import axios from "axios";
import { format } from "date-fns";

const getDateString = (value: any) => {
  const utcDate = moment.utc(value.toDate());
  const localDate = moment(utcDate).local();
  return localDate.format("DD MMM YY, LT");
};

function multiSelectFilter(
  rows: any,
  columnIds: any,
  filterValue: any
) {
  // Filters only if filters are selected
  if (columnIds.includes("Name")) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        filterValue.includes(
          String(
            row.original["user_first_name"] +
            " " +
            row.original["user_last_name"]
          )
        )
      );
  } else if (columnIds.includes("Admin Name")) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        filterValue.includes(
          String(
            row.original["admin_first_name"] +
            " " +
            row.original["admin_last_name"]
          )
        )
      );
  } else {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        filterValue.includes(
          String(row.original[columnIds])
        )
      );
  }
}

const compareDates = (filterValue: any[], field: any) => {
  if (
    filterValue[0] <= field?.toDate() &&
    filterValue[1] >= field?.toDate()
  ) {
    return true;
  } else {
    return false;
  }
};

function dateFilter(
  rows: any,
  columnIds: any,
  filterValue: any
) {
  if (columnIds.includes("created_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        compareDates(
          filterValue,
          row.original["created_at"]
        )
      );
  } else if (columnIds.includes("lead_assign_time")) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        compareDates(
          filterValue,
          row.original["lead_assign_time"]
        )
      );
  }
  else if (columnIds.includes("call_response_time")) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row: any) =>
        compareDates(
          filterValue,
          row.original["call_response_time"]
        )
      );
  }
   else if (
    columnIds.includes("next_follow_up_date_time")
  ) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["next_follow_up_date_time"] &&
          row.original["next_follow_up_date_time"]
            .toDate &&
          compareDates(
            filterValue,
            row.original["next_follow_up_date_time"]
          )
      );
  } else if (columnIds.includes("stage_change_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["stage_change_at"] &&
          row.original["stage_change_at"].toDate &&
          compareDates(
            filterValue,
            row.original["stage_change_at"]
          )
      );
  } else if (columnIds.includes("deactivated_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["deactivated_at"] &&
          row.original["deactivated_at"].toDate &&
          compareDates(
            filterValue,
            row.original["deactivated_at"]
          )
      );
  } else if (columnIds.includes("activated_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["activated_at"] &&
          row.original["activated_at"].toDate &&
          compareDates(
            filterValue,
            row.original["activated_at"]
          )
      );
  } else if (columnIds.includes("modified_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["modified_at"] &&
          row.original["modified_at"].toDate &&
          compareDates(
            filterValue,
            row.original["modified_at"]
          )
      );
  } else if (columnIds.includes("due_date")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["due_date"] &&
          row.original["due_date"].toDate &&
          compareDates(
            filterValue,
            row.original["due_date"]
          )
      );
  } else if (columnIds.includes("completed_at")) {
    return filterValue.length === 0
      ? rows
      : rows.filter(
        (row: any) =>
          row.original["completed_at"] &&
          row.original["completed_at"].toDate &&
          compareDates(
            filterValue,
            row.original["completed_at"]
          )
      );
  }
}

 export const ContactColumns = (data:any) => {
 
  const columns = [
    {
      Header: data[11].value,
      accessor: data[11].label,
      // Header: "Customer Name",
      // accessor: "customer_name",
      Cell: (props: any) => {
        if (props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[47].value,
      accessor: data[47].label,
      // Header: "Custom Button Executed",
      // accessor: "is_button_called",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[4].value,
      accessor: data[4].label,
      // Header: "Mobile No.",
      // accessor: "contact_no",
      filter: multiSelectFilter,
    },
    {
      Header: data[1].value,
      accessor: data[1].label,
      // Header: "Alternate No.",
      // accessor: "alternate_no",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return props.value;
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[6].value,
      accessor: data[6].label,
      // Header: "Country Code",
      // accessor: "country_code",
      filter: multiSelectFilter,
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
    },
    {
      Header: data[12].value,
      accessor: data[12].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[31].value,
      accessor: data[31].label,
      // Header: "Stage",
      // accessor: "stage",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
  
      filter: multiSelectFilter,
    },
    {
      Header: data[5].value,
      accessor: data[5].label,
      // Header: "Owner",
      // accessor: "contact_owner_email",
  
      filter: multiSelectFilter,
    },
    // {
    //   Header: "Reporting To",
    //   accessor: "reporting_to",
  
    //   filter: multiSelectFilter,
    // },
    // {
    //   Header: "Team",
    //   accessor: "team",
  
    //   filter: multiSelectFilter,
    // },
    // {
    //   Header: "Branch",
  
    //   accessor: "branch",
  
    //   filter: multiSelectFilter,
    // },
    {
      Header: data[7].value,
      accessor: data[7].label,
      // Header: "Lead Type",
      // accessor: "lead_type",
      filter: multiSelectFilter,
    },
    {
      Header: data[46].value,
      accessor: data[46].label,
      // Header: "Call Back Reason",
      // accessor: "call_back_reason",
      filter: multiSelectFilter,
    },
    {
      Header: data[29].value,
      accessor: data[29].label,
      // Header: "Property Type",
      // accessor: "property_type",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[38].value,
      accessor: data[38].label,
      // Header: "Property Sub Type",
      // accessor: "property_sub_type",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[28].value,
      accessor: data[28].label,
      // Header: "Property Stage",
      // accessor: "property_stage",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[16].value,
      accessor: data[16].label,
      // Header: "Location",
      // accessor: "location",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[27].value,
      accessor: data[27].label,
      // Header: "Project",
      // accessor: "project",
  
      filter: multiSelectFilter,
    },
   
    {
      Header: data[3].value,
      accessor: data[3].label,
      // Header: "Budget",
      // accessor: "budget",
  
      filter: multiSelectFilter,
    },
    // {
    //   Header: data[37].value,
    //   accessor: data[37].label,
    //   // Header: "Inventory Type",
    //   // accessor: "inventory_type",
    //   Cell: (props: any) => {
    //     if (props.value === "" || props.value === undefined) {
    //       return "";
    //     } else {
    //       return properFormat(props.value);
    //     }
    //   },
    //   filter: multiSelectFilter,
    // },
    {
      Header: data[36].value,
      accessor: data[36].label,
      // Header: "Campaign",
      // accessor: "campaign",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[35].value,
      accessor: data[35].label,
      // Header: "Add set",
      // accessor: "addset",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[22].value,
      accessor: data[22].label,
      // Header: "Not Interested Reason",
      // accessor: "not_int_reason",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[17].value,
      accessor: data[17].label,
      // Header: "Lost Reason",
      // accessor: "lost_reason",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[25].value,
      accessor: data[25].label,
      // Header: "Other Not Interested Reason",
      // accessor: "other_not_int_reason",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[24].value,
      accessor: data[24].label,
      // Header: "Other Lost Reason",
      // accessor: "other_lost_reason",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[26].value,
      accessor: data[26].label,
      // Header: "Previous Owner",
      // accessor: "previous_owner",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[15].value,
      accessor: data[15].label,
      // Header: "Lead Source",
      // accessor: "lead_source",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[33].value,
      accessor: data[33].label,
      // Header: "Transfer Status",
      // accessor: "transfer_status",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[2].value,
      accessor: data[2].label,
      // Header: "Associate Status",
      // accessor: "associate_status",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[30].value,
      accessor: data[30].label,
      // Header: "Source Status",
      // accessor: "source_status",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[39].value,
      accessor: data[39].label,
      // Header: "Transfer Reason",
      // accessor: "transfer_reason",
      cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return props.value;
        }
      },
  
      filter: multiSelectFilter,
    },
    {
      Header: data[20].value,
      accessor: data[20].label,
      // Header: "Next Follow Up Date & Time",
      // accessor: "next_follow_up_date_time",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[21].value,
      accessor: data[21].label,
      // Header: "Next Follow Up Type",
      // accessor: "next_follow_up_type",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[9].value,
      accessor: data[9].label,
      // Header: "Created By",
      // accessor: "created_by",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[8].value,
      accessor: data[8].label,
      // Header: "Created Date & Time",
      // accessor: "created_at",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[44].value,
      accessor: data[44].label,
      // Header: "Previous Stage 1",
      // accessor: "previous_stage_1",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
  
      filter: multiSelectFilter,
    },
    {
      Header: data[45].value,
      accessor: data[45].label,
      // Header: "Previous Stage 2",
      // accessor: "previous_stage_2",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
  
      filter: multiSelectFilter,
    },
    {
      Header: data[42].value,
      accessor: data[42].label,
      // Header: "Transfer By 1",
      // accessor: "transfer_by_1",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[43].value,
      accessor: data[43].label,
      // Header: "Transfer By 2",
      // accessor: "transfer_by_2",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[40].value,
      accessor: data[40].label,
      // Header: "Previous Owner 1",
      // accessor: "previous_owner_1",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[41].value,
      accessor: data[41].label,
      // Header: "Previous Owner 2",
      // accessor: "previous_owner_2",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[18].value,
      accessor: data[18].label,
      // Header: "Modified Date & Time",
      // accessor: "modified_at",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value === undefined ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[32].value,
      accessor: data[32].label,
      // Header: "Stage Change Date & Time",
      // accessor: "stage_change_at",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value === undefined ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[14].value,
      accessor: data[14].label,
      // Header: "Lead Assign Date & Time",
      // accessor: "lead_assign_time",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value === undefined ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[19].value,
      accessor: data[19].label,
      // Header: "Call Response Time",
      // accessor: "call_response_time",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value === undefined ||
          props.value.toDate === undefined
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[48].value,
      accessor: data[48].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[49].value,
      accessor: data[49].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[50].value,
      accessor: data[50].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[51].value,
      accessor: data[51].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[52].value,
      accessor: data[52].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[53].value,
      accessor: data[53].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
  ];
  return columns;
};

export const TasksColumns = (data:any) => {

  const columns = [
    {
      Header: data[1].value,
      accessor: data[1].label,
      // Header: "Customer Name",
      // accessor: "customer_name",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[14].value,
      accessor: data[14].label,
      // Header: "Task Type",
      // accessor: "type",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[4].value,
      accessor: data[4].label,
      // Header: "Owner",
      // accessor: "contact_owner_email",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[3].value,
      accessor: data[3].label,
      // Header: "Stage",
      // accessor: "stage",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: "Latitude",
      accessor: "latitude",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: "Longitude",
      accessor: "longitude",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[7].value,
      accessor: data[7].label,
      // Header: "Project",
      // accessor: "project",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[6].value,
      accessor: data[6].label,
      // Header: "Location",
      // accessor: "location",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[8].value,
      accessor: data[8].label,
      // Header: "Budget",
      // accessor: "budget",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[2].value,
      accessor: data[2].label,
      // Header: "Contact No.",
      // accessor: "contact_no",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    // {
    //   Header: data[15].value,
    //   accessor: data[15].label,
    //   // Header: "Inventory Type",
    //   // accessor: "inventory_type",
    //   Cell: (props: any) => {
    //     if (props.value === "" || props.value === undefined) {
    //       return "";
    //     } else {
    //       return properFormat(props.value);
    //     }
    //   },
    //   filter: multiSelectFilter,
    // },
    {
      Header: data[16].value,
      accessor: data[16].label,
      // Header: "Source",
      // accessor: "source",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[9].value,
      accessor: data[9].label,
      // Header: "Transfer Status",
      // accessor: "transfer_status",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[17].value,
      accessor: data[17].label,
      // Header: "Due Date & Time",
      // accessor: "due_date",
      Cell: (props: any) => {
        if (
          props.value === undefined ||
          !props.value.toDate
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header: data[18].value,
      accessor: data[18].label,
      // Header: "Completed Date & Time",
      // accessor: "completed_at",
      filter: dateFilter,
      Cell: (props: any) => {
        if (
          props.value === null ||
          props.value === undefined ||
          !props.value.toDate
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
    },
    // {
    //   Header: "Unique Meeting",
    //   accessor: "unique_meeting",
  
    //   filter: multiSelectFilter,
    // },
    {
      Header: data[10].value,
      accessor: data[10].label,
      // Header: "Unique Meeting",
      // accessor: "unique_meeting",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[11].value,
      accessor: data[11].label,
      // Header: "Unique Site Visit",
      // accessor: "unique_site_visit",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[5].value,
      accessor: data[5].label,
      // Header: "Call Reason",
      // accessor: "call_back_reason",
  
      filter: multiSelectFilter,
    },
  
    {
      Header: data[19].value,
      accessor: data[19].label,
      // Header: "Status",
      // accessor: "status",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[12].value,
      accessor: data[12].label,
      // Header: "Created By",
      // accessor: "created_by",
  
      filter: multiSelectFilter,
    },
    // {
    //   Header: "Lead Assign Date & Time",
    //   accessor: "lead_assign_time",
    //   Cell: (props: any) => {
    //     if (
    //       props.value === null ||
    //       props.value === undefined ||
    //       !props.value.toDate
    //     ) {
    //       return "";
    //     } else {
    //       const utcDate = moment.utc(props.value.toDate());
    //       const localDate = moment(utcDate).local();
    //       return localDate.format("DD MMM YY, LT");
    //     }
    //   },
  
    //   sortType: (
    //     rowA: any,
    //     rowB: any,
    //     id: any,
    //     desc: any
    //   ) => {
    //     if (
    //       rowA.values[id]?.toDate &&
    //       rowB.values[id]?.toDate
    //     ) {
    //       if (
    //         rowA.values[id].toDate() >
    //         rowB.values[id].toDate()
    //       )
    //         return 1;
    //       else return -1;
    //     } else {
    //       if (
    //         rowA.values[id]?.toDate === undefined &&
    //         rowB.values[id]?.toDate
    //       ) {
    //         return desc ? -1 : 1;
    //       } else if (
    //         rowA.values[id]?.toDate &&
    //         rowB.values[id]?.toDate === undefined
    //       ) {
    //         return desc ? 1 : -1;
    //       }
    //       return -1;
    //     }
    //   },
    //   filter: dateFilter,
    // },
    // {
    //   Header: "Lead Created At Date & Time",
    //   accessor: "lead_created_at",
    //   Cell: (props: any) => {
    //     if (
    //       props.value === null ||
    //       props.value === undefined ||
    //       !props.value.toDate
    //     ) {
    //       return "";
    //     } else {
    //       const utcDate = moment.utc(props.value.toDate());
    //       const localDate = moment(utcDate).local();
    //       return localDate.format("DD MMM YY, LT");
    //     }
    //   },
  
    //   sortType: (
    //     rowA: any,
    //     rowB: any,
    //     id: any,
    //     desc: any
    //   ) => {
    //     if (
    //       rowA.values[id]?.toDate &&
    //       rowB.values[id]?.toDate
    //     ) {
    //       if (
    //         rowA.values[id].toDate() >
    //         rowB.values[id].toDate()
    //       )
    //         return 1;
    //       else return -1;
    //     } else {
    //       if (
    //         rowA.values[id]?.toDate === undefined &&
    //         rowB.values[id]?.toDate
    //       ) {
    //         return desc ? -1 : 1;
    //       } else if (
    //         rowA.values[id]?.toDate &&
    //         rowB.values[id]?.toDate === undefined
    //       ) {
    //         return desc ? 1 : -1;
    //       }
    //       return -1;
    //     }
    //   },
    //   filter: dateFilter,
    // },
    // {
    //   Header: "Stage Change Date & Time",
    //   accessor: "stage_change_at",
    //   Cell: (props: any) => {
    //     if (
    //       props.value === null ||
    //       props.value === undefined ||
    //       !props.value.toDate
    //     ) {
    //       return "";
    //     } else {
    //       const utcDate = moment.utc(props.value.toDate());
    //       const localDate = moment(utcDate).local();
    //       return localDate.format("DD MMM YY, LT");
    //     }
    //   },
  
    //   sortType: (
    //     rowA: any,
    //     rowB: any,
    //     id: any,
    //     desc: any
    //   ) => {
    //     if (
    //       rowA.values[id]?.toDate &&
    //       rowB.values[id]?.toDate
    //     ) {
    //       if (
    //         rowA.values[id].toDate() >
    //         rowB.values[id].toDate()
    //       )
    //         return 1;
    //       else return -1;
    //     } else {
    //       if (
    //         rowA.values[id]?.toDate === undefined &&
    //         rowB.values[id]?.toDate
    //       ) {
    //         return desc ? -1 : 1;
    //       } else if (
    //         rowA.values[id]?.toDate &&
    //         rowB.values[id]?.toDate === undefined
    //       ) {
    //         return desc ? 1 : -1;
    //       }
    //       return -1;
    //     }
    //   },
    //   filter: dateFilter,
    // },
    {
      Header: data[13].value,
      accessor: data[13].label,
      // Header: "Created Date & Time",
      // accessor: "created_at",
      Cell: (props: any) => {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
  ];
  return columns;
};

export const ProjectColumns = (data:any) => {
  console.log("Table Headers Printed :",data);
  const columns = [
    {
      Header:data[6].value,
      accessor:data[6].label,
      // Header:"Status",
      // accessor:"project_status",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header:data[5].value,
      accessor:data[5].label,
      // Header: "Project Name",
      // accessor: "project_name",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header:data[3].value,
      accessor:data[3].label,
      // Header: "Developer Name",
      // accessor: "developer_name",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header:data[0].value,
      accessor:data[0].label,
      // Header: "Address",
      // accessor: "address",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header:data[9].value,
      accessor:data[9].label,
      // Header: "Rera Link",
      // accessor: "rera_link",
      filter: multiSelectFilter,
    },
    {
      Header:data[10].value,
      accessor:data[10].label,
      // Header: "Walkthrough Link",
      // accessor: "walkthrough_link",
      filter: multiSelectFilter,
    },
    {
      Header:data[8].value,
      accessor:data[8].label,
      // Header: "Property Type",
      // accessor: "property_type",
      Cell: (props: any) => {
        return properFormat(props.value);
  
      },
      filter: multiSelectFilter,
    },
  
    {
      Header:data[2].value,
      accessor:data[2].label,
      // Header: "Created By",
      // accessor: "created_by",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header:data[1].value,
      accessor:data[1].label,
      // Header: "Created Date & Time",
      // accessor: "created_at",
      Cell: (props: any) => {
        if (
          props.value === "" ||
          props.value === undefined ||
          !props.value.toDate
        ) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
    {
      Header:data[7].value,
      accessor:data[7].label,
      // Header: "Property Stage",
      // accessor: "property_stage",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header:"Project Resources",
      accessor:"project_resources",
      // Header: "Property Stage",
      // accessor: "property_stage",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "Upload";
        } else {
          return "Upload";
        }
      },
      // filter: multiSelectFilter,
    },
  
  ];
  return columns;
};

export const CallLogColumns = (data:any) => {
  const columns = [
    {
      Header: data[1].value,
      accessor: data[1].label,
      // Header: "Customer Name",
      // accessor: "customer_name",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[4].value,
      accessor: data[4].label,
      // Header: "Owner",
      // accessor: "contact_owner_email",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[3].value,
      accessor: data[3].label,
      // Header: "Stage",
      // accessor: "stage",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[6].value,
      accessor: data[6].label,
      // Header: "Project",
      // accessor: "project",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[5].value,
      accessor: data[5].label,
      // Header: "Location",
      // accessor: "location",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[7].value,
      accessor: data[7].label,
      // Header: "Budget",
      // accessor: "budget",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[2].value,
      accessor: data[2].label,
      // Header: "Contact No.",
      // accessor: "contact_no",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    // {
    //   Header: data[11].value,
    //   accessor: data[11].label,
    //   // Header: "Inventory Type",
    //   // accessor: "inventory_type",
    //   Cell: (props: any) => {
    //     if (props.value === "" || props.value === undefined) {
    //       return "";
    //     } else {
    //       return properFormat(props.value);
    //     }
    //   },
    //   filter: multiSelectFilter,
    // },
    {
      Header: "Source",
      accessor: "source",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[8].value,
      accessor: data[8].label,
      // Header: "Transfer Status",
      // accessor: "transfer_status",
      Cell: (props: any) => {
        if (typeof props.value == "boolean") {
          if (props.value) return "True";
          else return "False";
        } else {
          return "";
        }
      },
      filter: multiSelectFilter,
    },
  
    {
      Header: data[9].value,
      accessor: data[9].label,
      // Header: "Created By",
      // accessor: "created_by",
      Cell: (props: any) => {
        if (props.value == undefined) {
          return "";
        } else {
          return props.value;
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[12].value,
      accessor: data[12].label,
      // Header: "Duration",
      // accessor: "duration",
      filter: multiSelectFilter,
    },
    {
      Header: data[10].value,
      accessor: data[10].label,
      // Header: "Created Date & Time",
      // accessor: "created_at",
      Cell: (props: any) => {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
  ];
  return columns;
};

export const ApiDataColumns = (data:any) => {
  const columns = [
    {
      Header: data[1].value,
      accessor: data[1].label,
      // Header: "Customer Name",
      // accessor: "customer_name",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[2].value,
      accessor: data[2].label,
      // Header: "Mobile No.",
      // accessor: "contact_no",
      filter: multiSelectFilter,
    },
    {
      Header: data[3].value,
      accessor: data[3].label,
      // Header: "Alternate No.",
      // accessor: "alternate_no",
      filter: multiSelectFilter,
    },
    {
      Header: data[4].value,
      accessor: data[4].label,
      // Header: "Country Code",
      // accessor: "country_code",
      filter: multiSelectFilter,
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
    },
    {
      Header: data[5].value,
      accessor: data[5].label,
      // Header: "Associate Contact",
      // accessor: "associate_contact",
      filter: multiSelectFilter,
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
    },
    {
      Header: data[6].value,
      accessor: data[6].label,
      // Header: "Email ID",
      // accessor: "email",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[7].value,
      accessor: data[7].label,
      // Header: "Stage",
      // accessor: "stage",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
  
      filter: multiSelectFilter,
    },
    {
      Header: data[8].value,
      accessor: data[8].label,
      // Header: "Campaign",
      // accessor: "campaign",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[9].value,
      accessor: data[9].label,
      // Header: "Add set",
      // accessor: "addset",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[10].value,
      accessor: data[10].label,
      // Header: "Owner",
  
      // accessor: "contact_owner_email",
  
      filter: multiSelectFilter,
    },
    {
      Header: "Status",
      accessor: "status",
      filter: multiSelectFilter,
    },
    {
      Header: data[11].value,
      accessor: data[11].label,
      // Header: "Fail Reason",
      // accessor: "fail_reason",
      filter: multiSelectFilter,
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          return properFormat(props.value);
        }
      },
    },
    {
      Header: data[12].value,
      accessor: data[12].label,
      // Header: "Property Type",
      // accessor: "property_type",
  
      filter: multiSelectFilter,
    },
  
    {
      Header: data[13].value,
      accessor: data[13].label,
      // Header: "Property Stage",
      // accessor: "property_stage",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[14].value,
      accessor: data[14].label,
      // Header: "Location",
      // accessor: "location",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[15].value,
      accessor: data[15].label,
      // Header: "Project",
      // accessor: "project",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[16].value,
      accessor: data[16].label,
      // Header: "Budget",
      // accessor: "budget",
  
      filter: multiSelectFilter,
    },
    {
      Header: data[17].value,
      accessor: data[17].label,
      // Header: "Lead Source",
      // accessor: "lead_source",
  
      filter: multiSelectFilter,
    },
  
    {
      Header: data[18].value,
      accessor: data[18].label,
      // Header: "Created By",
      // accessor: "created_by",
      Cell: (props: any) => {
        return properFormat(props.value);
      },
      filter: multiSelectFilter,
    },
    {
      Header: data[19].value,
      accessor: data[19].label,
      // Header: "Created Date & Time",
      // accessor: "created_at",
      Cell: (props: any) => {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
  
    {
      Header: data[20].value,
      accessor: data[20].label,
      // Header: "Lead Assign Date & Time",
      // accessor: "lead_assign_time",
      Cell: (props: any) => {
        if (props.value === "" || props.value === undefined) {
          return "";
        } else {
          const utcDate = moment.utc(props.value.toDate());
          const localDate = moment(utcDate).local();
          return localDate.format("DD MMM YY, LT");
        }
      },
  
      sortType: (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
      ) => {
        if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate
        ) {
          if (
            rowA.values[id].toDate() >
            rowB.values[id].toDate()
          )
            return 1;
          else return -1;
        } else {
          if (
            rowA.values[id]?.toDate === undefined &&
            rowB.values[id]?.toDate
          ) {
            return desc ? -1 : 1;
          } else if (
            rowA.values[id]?.toDate &&
            rowB.values[id]?.toDate === undefined
          ) {
            return desc ? 1 : -1;
          }
          return -1;
        }
      },
      filter: dateFilter,
    },
  ];
  return columns;
};



export const ORGANIZATION_COLUMNS = [
  {
    Header: "Name",
    accessor: "organization_name",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Mobile No.",
    accessor: "mobile_number",
    filter: multiSelectFilter,
  },
  {
    Header: "Email ID",
    accessor: "email_id",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    filter: multiSelectFilter,
  },
  {
    Header: "Address",
    accessor: "address",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "City",
    accessor: "city",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "State",
    accessor: "state",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Admin Name",
    accessor: (d: any) =>
      `${d.admin_first_name} ${d.admin_last_name}`,
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Admin Contact No.",
    accessor: "admin_contact_number",
    filter: multiSelectFilter,
  },
  {
    Header: "Admin Email",
    accessor: "admin_email_id",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "No. of Employees",
    accessor: "no_of_employees",
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  
];

export const HEADERS_COLUMNS = [
  // {
  //   Header: "Industry ID",
  //   accessor: "industry_id",
  //   // Cell: (props: any) => {
  //   //   return props.value
  //   //     .toLowerCase()
  //   //     .split(" ")
  //   //     .map(function (word: any) {
  //   //       return (
  //   //         word.charAt(0).toUpperCase() + word.slice(1)
  //   //       );
  //   //     })
  //   //     .join(" ");
  //   // },
  //   // filter: multiSelectFilter,
  // },
  {
    Header: "Industry Name",
    accessor: "industry_name",
    // Cell: (props: any) => {
    //   return props.value
    //     .toLowerCase()
    //     .split(" ")
    //     .map(function (word: any) {
    //       return (
    //         word.charAt(0).toUpperCase() + word.slice(1)
    //       );
    //     })
    //     .join(" ");
    // },
    // filter: multiSelectFilter,
  },
  {
    Header: "Screen Name",
    accessor: "screen_name",
    // Cell: (props: any) => {
    //   return props.value
    //     .toLowerCase()
    //     .split(" ")
    //     .map(function (word: any) {
    //       return (
    //         word.charAt(0).toUpperCase() + word.slice(1)
    //       );
    //     })
    //     .join(" ");
    // },
    // filter: multiSelectFilter,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    // Cell: (props: any) => {
    //   return props.value
    //     .toLowerCase()
    //     .split(" ")
    //     .map(function (word: any) {
    //       return (
    //         word.charAt(0).toUpperCase() + word.slice(1)
    //       );
    //     })
    //     .join(" ");
    // },
    // filter: multiSelectFilter,
  },
  {
    Header: "Modified By",
    accessor: "modified_by",
    // Cell: (props: any) => {
    //   return props.value
    //     .toLowerCase()
    //     .split(" ")
    //     .map(function (word: any) {
    //       return (
    //         word.charAt(0).toUpperCase() + word.slice(1)
    //       );
    //     })
    //     .join(" ");
    // },
    // filter: multiSelectFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    // Cell: (props: any) => {
    //   const utcDate = moment.utc(props.value.toDate());
    //   const localDate = moment(utcDate).local();
    //   return localDate.format("DD MMM YY, LT");
    // },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Modified Date & Time",
    accessor: "modified_at",
    // Cell: (props: any) => {
    //   const utcDate = moment.utc(props.value.toDate());
    //   const localDate = moment(utcDate).local();
    //   return localDate.format("DD MMM YY, LT");
    // },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];
export const BOOKINGFORM_COLUMNS = [
  {
    Header: "Industry Name",
    accessor: "industry_name",
  },
  {
    Header: "Created By",
    accessor: "created_by",
  },
  {
    Header: "Modified By",
    accessor: "modified_by",
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Modified Date & Time",
    accessor: "modified_at",
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];


export const USER_COLUMNS = [
  {
    Header: "Name",
    accessor: (d: any) =>
      `${d.user_first_name} ${d.user_last_name}`,
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Mobile No.",
    accessor: "contact_no",
    filter: multiSelectFilter,
  },
  {
    Header: "Email ID",
    accessor: "user_email",

    filter: multiSelectFilter,
  },
  {
    Header: "Team",
    accessor: "team",

    filter: multiSelectFilter,
  },
  {
    Header: "Reporting To",
    accessor: "reporting_to",

    filter: multiSelectFilter,
  },
  {
    Header: "Designation",
    accessor: "designation",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    filter: multiSelectFilter,
  },
  {
    Header: "Branch",
    accessor: "branch",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return props.value;
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Deactivated At",
    accessor: "deactivated_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value === null
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Activated At",
    accessor: "activated_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value === null
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Profile",
    accessor: "profile",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
];

export const USERLIST_COLUMNS = [
  {
    Header: "Organization Name",
    accessor: "organization_name",
    Cell: (props: any) => {
      return props?.value
        ?.toLowerCase()
        ?.split(" ")
        ?.map(function (word: any) {
          return (
            word.charAt(0)?.toUpperCase() + word?.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Name",
    accessor: (d: any) =>
      `${d.user_first_name} ${d.user_last_name}`,
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Mobile No.",
    accessor: "contact_no",
    filter: multiSelectFilter,
  },
  {
    Header: "Email ID",
    accessor: "user_email",

    filter: multiSelectFilter,
  },
  {
    Header: "Team",
    accessor: "team",

    filter: multiSelectFilter,
  },
  {
    Header: "Reporting To",
    accessor: "reporting_to",

    filter: multiSelectFilter,
  },
  {
    Header: "Designation",
    accessor: "designation",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    filter: multiSelectFilter,
  },
  {
    Header: "Branch",
    accessor: "branch",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return props.value;
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Deactivated At",
    accessor: "deactivated_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value === null
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Activated At",
    accessor: "activated_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value === null
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Profile",
    accessor: "profile",
    Cell: (props: any) => {
      return props.value
        .toLowerCase()
        .split(" ")
        .map(function (word: any) {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          );
        })
        .join(" ");
    },
    filter: multiSelectFilter,
  },
];

// export const CONTACT_COLUMNS = ContactColumns();

export const CONTACT_COLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    Cell: (props: any) => {
      if (props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Custom Button Executed",
    accessor: "is_button_called",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Mobile No.",
    accessor: "contact_no",
    filter: multiSelectFilter,
  },
  {
    Header: "Alternate No.",
    accessor: "alternate_no",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return props.value;
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Country Code",
    accessor: "country_code",
    filter: multiSelectFilter,
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
  },
  {
    Header: "Email ID",
    accessor: "email",

    filter: multiSelectFilter,
  },
  {
    Header: "Stage",
    accessor: "stage",
    Cell: (props: any) => {
      return properFormat(props.value);
    },

    filter: multiSelectFilter,
  },
  {
    Header: "Owner",

    accessor: "contact_owner_email",

    filter: multiSelectFilter,
  },
  {
    Header: "Reporting To",

    accessor: "reporting_to",

    filter: multiSelectFilter,
  },
  {
    Header: "Team",

    accessor: "team",

    filter: multiSelectFilter,
  },
  {
    Header: "Branch",

    accessor: "branch",

    filter: multiSelectFilter,
  },
  {
    Header: "Lead Type",
    accessor: "lead_type",

    filter: multiSelectFilter,
  },
  {
    Header: "Call Back Reason",
    accessor: "call_back_reason",

    filter: multiSelectFilter,
  },
  {
    Header: "Property Type",
    accessor: "property_type",

    filter: multiSelectFilter,
  },
  {
    Header: "Property Sub Type",
    accessor: "property_sub_type",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Property Stage",
    accessor: "property_stage",

    filter: multiSelectFilter,
  },
  {
    Header: "Location",
    accessor: "location",

    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",

    filter: multiSelectFilter,
  },
 
  {
    Header: "Budget",
    accessor: "budget",

    filter: multiSelectFilter,
  },
  // {
  //   Header: "Inventory Type",
  //   accessor: "inventory_type",
  //   Cell: (props: any) => {
  //     if (props.value === "" || props.value === undefined) {
  //       return "";
  //     } else {
  //       return properFormat(props.value);
  //     }
  //   },
  //   filter: multiSelectFilter,
  // },
  {
    Header: "Campaign",
    accessor: "campaign",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Add set",
    accessor: "addset",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Not Interested Reason",
    accessor: "not_int_reason",

    filter: multiSelectFilter,
  },
  {
    Header: "Lost Reason",
    accessor: "lost_reason",

    filter: multiSelectFilter,
  },
  {
    Header: "Other Not Interested Reason",
    accessor: "other_not_int_reason",

    filter: multiSelectFilter,
  },
  {
    Header: "Other Lost Reason",
    accessor: "other_lost_reason",

    filter: multiSelectFilter,
  },
  {
    Header: "Previous Owner",
    accessor: "previous_owner",

    filter: multiSelectFilter,
  },
  {
    Header: "Lead Source",
    accessor: "lead_source",

    filter: multiSelectFilter,
  },
  {
    Header: "Transfer Status",
    accessor: "transfer_status",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Associate Status",
    accessor: "associate_status",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Source Status",
    accessor: "source_status",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Transfer Reason",
    accessor: "transfer_reason",
    cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return props.value;
      }
    },

    filter: multiSelectFilter,
  },
  {
    Header: "Next Follow Up Date & Time",
    accessor: "next_follow_up_date_time",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Next Follow Up Type",
    accessor: "next_follow_up_type",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Previous Stage 1",
    accessor: "previous_stage_1",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },

    filter: multiSelectFilter,
  },
  {
    Header: "Previous Stage 2",
    accessor: "previous_stage_2",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },

    filter: multiSelectFilter,
  },
  {
    Header: "Transfer By 1",
    accessor: "transfer_by_1",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Transfer By 2",
    accessor: "transfer_by_2",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Previous Owner 1",
    accessor: "previous_owner_1",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Previous Owner 2",
    accessor: "previous_owner_2",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Modified Date & Time",
    accessor: "modified_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Stage Change Date & Time",
    accessor: "stage_change_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Lead Assign Date & Time",
    accessor: "lead_assign_time",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Call Response Time",
    accessor: "call_response_time",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        props.value.toDate === undefined
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const PROJECT_COLUMNS = [
  {
    Header:"Status",
    accessor:"project_status",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Project Name",
    accessor: "project_name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Developer Name",
    accessor: "developer_name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Address",
    accessor: "address",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Rera Link",
    accessor: "rera_link",
    filter: multiSelectFilter,
  },
  {
    Header: "Walkthrough Link",
    accessor: "walkthrough_link",
    filter: multiSelectFilter,
  },
  {
    Header: "Property Type",
    accessor: "property_type",
    Cell: (props: any) => {
      return properFormat(props.value);

    },
    filter: multiSelectFilter,
  },

  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      if (
        props.value === "" ||
        props.value === undefined ||
        !props.value.toDate
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Property Stage",
    accessor: "property_stage",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "Ready To Move I";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },

];

export const TASK_COLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Task Type",
    accessor: "type",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Owner",
    accessor: "contact_owner_email",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Stage",
    accessor: "stage",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Location",
    accessor: "location",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Budget",
    accessor: "budget",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Contact No.",
    accessor: "contact_no",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  // {
  //   Header: "Inventory Type",
  //   accessor: "inventory_type",
  //   Cell: (props: any) => {
  //     if (props.value === "" || props.value === undefined) {
  //       return "";
  //     } else {
  //       return properFormat(props.value);
  //     }
  //   },
  //   filter: multiSelectFilter,
  // },
  {
    Header: "Source",
    accessor: "source",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Transfer Status",
    accessor: "transfer_status",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Due Date & Time",
    accessor: "due_date",
    Cell: (props: any) => {
      if (
        props.value === undefined ||
        !props.value.toDate
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Completed Date & Time",
    accessor: "completed_at",
    filter: dateFilter,
    Cell: (props: any) => {
      if (
        props.value === null ||
        props.value === undefined ||
        !props.value.toDate
      ) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },
    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
  },
  // {
  //   Header: "Unique Meeting",
  //   accessor: "unique_meeting",

  //   filter: multiSelectFilter,
  // },
  {
    Header: "Unique Meeting",
    accessor: "unique_meeting",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Unique Site Visit",
    accessor: "unique_site_visit",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Call Reason",
    accessor: "call_back_reason",

    filter: multiSelectFilter,
  },

  {
    Header: "Status",
    accessor: "status",

    filter: multiSelectFilter,
  },
  {
    Header: "Created By",
    accessor: "created_by",

    filter: multiSelectFilter,
  },
  // {
  //   Header: "Lead Assign Date & Time",
  //   accessor: "lead_assign_time",
  //   Cell: (props: any) => {
  //     if (
  //       props.value === null ||
  //       props.value === undefined ||
  //       !props.value.toDate
  //     ) {
  //       return "";
  //     } else {
  //       const utcDate = moment.utc(props.value.toDate());
  //       const localDate = moment(utcDate).local();
  //       return localDate.format("DD MMM YY, LT");
  //     }
  //   },

  //   sortType: (
  //     rowA: any,
  //     rowB: any,
  //     id: any,
  //     desc: any
  //   ) => {
  //     if (
  //       rowA.values[id]?.toDate &&
  //       rowB.values[id]?.toDate
  //     ) {
  //       if (
  //         rowA.values[id].toDate() >
  //         rowB.values[id].toDate()
  //       )
  //         return 1;
  //       else return -1;
  //     } else {
  //       if (
  //         rowA.values[id]?.toDate === undefined &&
  //         rowB.values[id]?.toDate
  //       ) {
  //         return desc ? -1 : 1;
  //       } else if (
  //         rowA.values[id]?.toDate &&
  //         rowB.values[id]?.toDate === undefined
  //       ) {
  //         return desc ? 1 : -1;
  //       }
  //       return -1;
  //     }
  //   },
  //   filter: dateFilter,
  // },
  // {
  //   Header: "Lead Created At Date & Time",
  //   accessor: "lead_created_at",
  //   Cell: (props: any) => {
  //     if (
  //       props.value === null ||
  //       props.value === undefined ||
  //       !props.value.toDate
  //     ) {
  //       return "";
  //     } else {
  //       const utcDate = moment.utc(props.value.toDate());
  //       const localDate = moment(utcDate).local();
  //       return localDate.format("DD MMM YY, LT");
  //     }
  //   },

  //   sortType: (
  //     rowA: any,
  //     rowB: any,
  //     id: any,
  //     desc: any
  //   ) => {
  //     if (
  //       rowA.values[id]?.toDate &&
  //       rowB.values[id]?.toDate
  //     ) {
  //       if (
  //         rowA.values[id].toDate() >
  //         rowB.values[id].toDate()
  //       )
  //         return 1;
  //       else return -1;
  //     } else {
  //       if (
  //         rowA.values[id]?.toDate === undefined &&
  //         rowB.values[id]?.toDate
  //       ) {
  //         return desc ? -1 : 1;
  //       } else if (
  //         rowA.values[id]?.toDate &&
  //         rowB.values[id]?.toDate === undefined
  //       ) {
  //         return desc ? 1 : -1;
  //       }
  //       return -1;
  //     }
  //   },
  //   filter: dateFilter,
  // },
  // {
  //   Header: "Stage Change Date & Time",
  //   accessor: "stage_change_at",
  //   Cell: (props: any) => {
  //     if (
  //       props.value === null ||
  //       props.value === undefined ||
  //       !props.value.toDate
  //     ) {
  //       return "";
  //     } else {
  //       const utcDate = moment.utc(props.value.toDate());
  //       const localDate = moment(utcDate).local();
  //       return localDate.format("DD MMM YY, LT");
  //     }
  //   },

  //   sortType: (
  //     rowA: any,
  //     rowB: any,
  //     id: any,
  //     desc: any
  //   ) => {
  //     if (
  //       rowA.values[id]?.toDate &&
  //       rowB.values[id]?.toDate
  //     ) {
  //       if (
  //         rowA.values[id].toDate() >
  //         rowB.values[id].toDate()
  //       )
  //         return 1;
  //       else return -1;
  //     } else {
  //       if (
  //         rowA.values[id]?.toDate === undefined &&
  //         rowB.values[id]?.toDate
  //       ) {
  //         return desc ? -1 : 1;
  //       } else if (
  //         rowA.values[id]?.toDate &&
  //         rowB.values[id]?.toDate === undefined
  //       ) {
  //         return desc ? 1 : -1;
  //       }
  //       return -1;
  //     }
  //   },
  //   filter: dateFilter,
  // },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const API_COLUMNS = [
  {
    Header: "Source",
    accessor: "source",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },

  {
    Header: "Status",
    accessor: "status",

    filter: multiSelectFilter,
  },

  {
    Header: "Country Code",
    accessor: "country_code",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },

  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },

  {
    Header: "API Key",
    accessor: "api_key",

    filter: multiSelectFilter,
  },
];

export const HOLIDAY_CONFIG_COLUMNS = [
  {
    Header: "Holiday Name",
    accessor: "holiday_name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: (props: any) => {
      // Format the date using `date-fns`
      const formattedDate = format(new Date(props.value), "MMMM do");
      return formattedDate;
    },
    filter: multiSelectFilter,
  },
];

export const NEWS_COLUMNS = [
  {
    Header: "Area Name",
    accessor: "name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },

  {
    Header: "News Link",
    accessor: "link",

    filter: multiSelectFilter,
  },

  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const APIDATA_COLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Mobile No.",
    accessor: "contact_no",
    filter: multiSelectFilter,
  },
  {
    Header: "Alternate No.",
    accessor: "alternate_no",
    filter: multiSelectFilter,
  },
  {
    Header: "Country Code",
    accessor: "country_code",
    filter: multiSelectFilter,
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
  },
  {
    Header: "Associate Contact",
    accessor: "associate_contact",
    filter: multiSelectFilter,
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
  },
  {
    Header: "Email ID",
    accessor: "email",

    filter: multiSelectFilter,
  },
  {
    Header: "Stage",
    accessor: "stage",
    Cell: (props: any) => {
      return properFormat(props.value);
    },

    filter: multiSelectFilter,
  },
  {
    Header: "Campaign",
    accessor: "campaign",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Add set",
    accessor: "addset",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Owner",

    accessor: "contact_owner_email",

    filter: multiSelectFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    filter: multiSelectFilter,
  },
  {
    Header: "Fail Reason",
    accessor: "fail_reason",
    filter: multiSelectFilter,
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
  },
  {
    Header: "Property Type",
    accessor: "property_type",

    filter: multiSelectFilter,
  },

  {
    Header: "Property Stage",
    accessor: "property_stage",

    filter: multiSelectFilter,
  },
  {
    Header: "Location",
    accessor: "location",

    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",

    filter: multiSelectFilter,
  },
  {
    Header: "Budget",
    accessor: "budget",

    filter: multiSelectFilter,
  },
  {
    Header: "Lead Source",
    accessor: "lead_source",

    filter: multiSelectFilter,
  },

  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      return properFormat(props.value);
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },

  {
    Header: "Lead Assign Date & Time",
    accessor: "lead_assign_time",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        const utcDate = moment.utc(props.value.toDate());
        const localDate = moment(utcDate).local();
        return localDate.format("DD MMM YY, LT");
      }
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const CUSTOM_BUTTONS_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
    filter: multiSelectFilter,
  },

  {
    Header: "URL",
    accessor: "url",
    filter: multiSelectFilter,
  },

  {
    Header: "Parameters",
    accessor: "parameters",
    filter: multiSelectFilter,
  },
  {
    Header: "Headers",
    accessor: "headers",

    filter: multiSelectFilter,
  },
  {
    Header: "Type",
    accessor: "type",

    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const nameList = [
  "Name",
  "Customer Name",
  "User Name",
  "Contact Name",
  "Consumer Name",
  "Customer name",
];

export const emailList = [
  "Email ID",
  "Email Id",
  "Email id",
  "Mail",
  "ID",
  "id",
];

export const phoneList = [
  "Contact Number",
  "Phone Number",
  "Number",
  "Mobile No",
  "Mobile No.",
  "Phone No",
  "Phone No.",
  "Mobile",
  "Contact No.",
  "Contact No",
];

export const ownerList = [
  "Owner",
  "Contact Owner",
  "Lead Owner",
  "owner",
  "Contact Owner Email ID",
];

export const leadSource = [
  "Lead Source",
  "lead source",
  "Source",
];

export const reportingList = [
  "Reporting To",
  "Reporting Manager Email ID",
  "Reporting Email ID",
];

export const profileList = [
  "Profile",
  "profile",
  "Profile assigned",
];

export const DesignationList = [
  "Designation",
  "Desig",
  "designation",
];

export const teamList = [
  "Team",
  "Team Name",
  "Team Assigned",
];

export const LEAD_DISTRIBUTOR_COLUMNS = [
  {
    Header: "Source",
    accessor: "source",
    filter: multiSelectFilter,
  },
  {
    Header: "Distribution Type",
    accessor: "distribution_type",
    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",
    filter: multiSelectFilter,
  },
  {
    Header: "Location",
    accessor: "location",
    filter: multiSelectFilter,
  },
  {
    Header: "Budget",
    accessor: "budget",
    filter: multiSelectFilter,
  },
  {
    Header: "Property Type",
    accessor: "property_type",
    filter: multiSelectFilter,
  },
  {
    Header: "Associate",
    accessor: "users",
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const LEAD_ROTATION_COLUMNS = [
  {
    Header: "Source",
    accessor: "source",
    filter: multiSelectFilter,
  },
  {
    Header: "Time(min)",
    accessor: "rotation_time",
    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",
    filter: multiSelectFilter,
  },
  {
    Header: "Associate",
    accessor: "users",
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const COUPON_COLUMNS = [
  {
    Header: "Coupon Name",
    accessor: "coupon_Code",
    filter: multiSelectFilter,
  },
  {
    Header: "Discount (%)",
    accessor: "discount",
    filter: multiSelectFilter,
  },
  // {
  //   Header: "Expiration Date",
  //   accessor: "expiration_Date",
  //   filter: multiSelectFilter,
  // },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const CALLLOG_COLUMNS = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Owner",
    accessor: "contact_owner_email",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Stage",
    accessor: "stage",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Project",
    accessor: "project",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Location",
    accessor: "location",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Budget",
    accessor: "budget",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Contact No.",
    accessor: "contact_no",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  // {
  //   Header: "Inventory Type",
  //   accessor: "inventory_type",
  //   Cell: (props: any) => {
  //     if (props.value === "" || props.value === undefined) {
  //       return "";
  //     } else {
  //       return properFormat(props.value);
  //     }
  //   },
  //   filter: multiSelectFilter,
  // },
  {
    Header: "Source",
    accessor: "source",
    Cell: (props: any) => {
      if (props.value === "" || props.value === undefined) {
        return "";
      } else {
        return properFormat(props.value);
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Transfer Status",
    accessor: "transfer_status",
    Cell: (props: any) => {
      if (typeof props.value == "boolean") {
        if (props.value) return "True";
        else return "False";
      } else {
        return "";
      }
    },
    filter: multiSelectFilter,
  },

  {
    Header: "Created By",
    accessor: "created_by",
    Cell: (props: any) => {
      if (props.value == undefined) {
        return "";
      } else {
        return props.value;
      }
    },
    filter: multiSelectFilter,
  },
  {
    Header: "Duration",
    accessor: "duration",
    filter: multiSelectFilter,
  },
  {
    Header: "Created Date & Time",
    accessor: "created_at",
    Cell: (props: any) => {
      const utcDate = moment.utc(props.value.toDate());
      const localDate = moment(utcDate).local();
      return localDate.format("DD MMM YY, LT");
    },

    sortType: (
      rowA: any,
      rowB: any,
      id: any,
      desc: any
    ) => {
      if (
        rowA.values[id]?.toDate &&
        rowB.values[id]?.toDate
      ) {
        if (
          rowA.values[id].toDate() >
          rowB.values[id].toDate()
        )
          return 1;
        else return -1;
      } else {
        if (
          rowA.values[id]?.toDate === undefined &&
          rowB.values[id]?.toDate
        ) {
          return desc ? -1 : 1;
        } else if (
          rowA.values[id]?.toDate &&
          rowB.values[id]?.toDate === undefined
        ) {
          return desc ? 1 : -1;
        }
        return -1;
      }
    },
    filter: dateFilter,
  },
];

export const dateFieldList = [
  "Created Date & Time",
  "Next Follow Up Date & Time",
  "Stage Change Date & Time",
  "Modified Date & Time",
  "Completed Date & Time",
  "Due Date & Time",
  "Lead Assign Date & Time",
  "Created At",
  "Lead Assign At",
  "Stage Change At",
  "Completion Time",
  "Call Response Time",
  "Deactivated At",
  "Activated At"
];

export const datesField = [
  "created_at",
  "next_follow_up_date_time",
  "stage_change_at",
  "modified_at",
  "lead_assign_time",
  "lead_created_at",
  "feedback_time",
  "due_date",
  "completed_at",
  "call_response_time"
];

export const stageList = [
  "FRESH",
  "INTERESTED",
  "NOT INTERESTED",
  "CALL BACK",
  "LOST",
  "WON",
];

export const booleanObject = {
  associate_status: ["True", "False"],
  source_status: ["True", "False"],
  transfer_status: ["True", "False"],
  is_button_called: ["True", "False"],
  unique_meeting:["True", "False"],
  unique_site_visit:["True", "False"],
};
