import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import SuperAdminPanel from "../Screens/SuperAdminPanel/SuperAdminPanel";
import Reports from "../Screens/Reports/Reports";
import AddOrganization from "../Screens/AddOrganization/AddOrganization";
import { Route } from "react-router-dom";
import EditOrganization from "../Screens/EditOrganization/EditOrganization";
import styles from '../Screens/Analytics/Analytics.module.css'
import AnalyticsSideNav from "../Components/AnalyticsSideNav/AnalyticsSideNav";
import UserPanel from "../Screens/UserPanel/UserPanel";
import Booking from "../Screens/Booking/Booking";
import EditBooking from "../Screens/Booking/EditBooking";

const OrganizationManagerRoute = (props: any) => {
  return (
    <>
      <Navbar
        props={props}
        title={"Organizations"}
        path={"/"}
        leadManger={false}
      />
       <div className={styles.parent}>
        <AnalyticsSideNav
          props={props}
          title={"Bookings"}
          path={"/"}
          leadManger={false}
          operationManger={true}
        />
       <Route exact path="/" component={Booking} />
       <Route exact path="/editBooking" component={EditBooking} />
       
      </div>
      
    </>
  );
};

export default OrganizationManagerRoute;
