import Firebase from "firebase/app";
import { firestore, functions, storage } from "../Firebase";
import {
  setBranchUsersList,
  setCompleted,
  setContacts,
  setRefreshContacts,
  setTotal,
  showSnackbarAction,
} from "../Redux/actions";
import {
  contactNumberSplit,
  phoneValidate,
  validateNumber,
} from "../Values/validatorsnew";
import * as XLSX from "xlsx";
import { datesField, stageList } from "../Values/tables";
import moment from "moment";
import {
  CallbackValidation,
  FreshLeadValidation,
  InterestedLeadValidation,
  LostLeadValidation,
  NotInterestedValidation,
  WonValidation,
} from "./validation";
import {
  chunk,
  properFormat,
  timer,
} from "../Values/utils";

export const createContacts = async (
  organization_id: string,
  email: string,
  first_name: string,
  last_name: string,
  contact_no: any,
  created_by: string,
  uid: string,
  dispatcher: any,
  history: any,
  user_email: any,
  alternate_no: any,
  country_code: string,
  setLoad: (data: boolean) => any,
  country: string,
  property_type: string,
  property_stage: string,
  lead_type: string,
  location: string,
  budget: string,
  project: string,
  property_sub_type: string,
  leadSource: string,
  addSet:string,
  campaign:string,
  notes: string,
  is_button_called: boolean,
  userEmail: string,
  allowDuplicateLeads: boolean,
  field_one: number,
  field_two: number,
  field_three: string,
  field_four: string,
  field_five: string,
  field_six: string,
) => {
  try {
    let result = true;
    if (alternate_no !== "" && allowDuplicateLeads !== true) {
      const res = await functions.httpsCallable(
        "checkLead"
      )(
        JSON.stringify({
          organization_id: organization_id,
          contactList: [contact_no, alternate_no],
        })
      );
      result = res.data;
    } else {
      if(allowDuplicateLeads !== true){
      const res = await functions.httpsCallable(
        "checkLead"
      )(
        JSON.stringify({
          organization_id: organization_id,
          contact_no: contact_no,
        })
      );
      result = res.data;
    }
    }

    let check = true;

    if (result === false) {
      dispatcher(
        showSnackbarAction(
          // "This Contact Number Already Exists",
          "Contact number or Alternate number already exists on another lead",
          "warning"
        )
      );
      setLoad && setLoad(false);
    } else {
      if (check === true) {
        const contactDoc = firestore
          .collection("contacts")
          .doc();
        contactDoc
          .set({
            alternate_no: alternate_no,
            organization_id: organization_id,
            associate_status: true,
            budget: budget,
            contact_no: contactNumberSplit({
              contact_no,
              country_code,
            }).contact_no,
            country_code: country_code,
            created_at: Firebase.firestore.Timestamp.now(),
            created_by: created_by,
            customer_image: "",
            customer_name: first_name + " " + last_name,
            email: email.toLowerCase(),
            lead_source: leadSource,
            lead_assign_time:
              Firebase.firestore.Timestamp.now(),
            location: location,
            lost_reason: "",
            modified_at: Firebase.firestore.Timestamp.now(),
            not_int_reason: "",
            other_not_int_reason: "",
            other_lost_reason: "",
            previous_owner: "",
            project: project,
            property_stage: property_stage,
            property_type: property_type,
            property_sub_type: property_sub_type,
            source_status: true,
            stage: "FRESH",
            transfer_status: false,
            uid: uid,
            lead_type:lead_type,
            contact_owner_email: user_email.toLowerCase(),
            feedback_time: "",
            next_follow_up_type: "",
            next_follow_up_date_time: "",
            is_button_called: false,
            addset:addSet,
            campaign:campaign,
            field_one:field_one,
            field_two:field_two,
            field_three:field_three,
            field_four:field_four,
            field_five:field_five,
            field_six:field_six,
          })
          .then(() => {
            functions.httpsCallable("sendNotifications")(
              JSON.stringify({
                organization_id: organization_id,
                notifications: { [uid]: 1 },
              })
            );
            if (notes !== "") {
              firestore
                .collection("contactResources")
                .doc(contactDoc.id)
                .set({
                  notes: [
                    {
                      note: notes,
                      userEmail: userEmail,
                      created_at:
                        Firebase.firestore.Timestamp.now(),
                    },
                  ],
                });
            }
            dispatcher(
              showSnackbarAction(
                "Contact Created!!",
                "success"
              )
            );
            setLoad(false);
            // history.replace("/");
            setTimeout(() => {
              history.replace("/");
              dispatcher(setRefreshContacts(true));
            },1000);
          })
          .catch((error) => {
            console.log("Firestore Contacts error", error);
            setLoad(false);
          });
      } else {
        console.log("Firestore Contacts error");
        dispatcher(
          showSnackbarAction("Error!! Try Again!!", "error")
        );
        setLoad(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const sortContacts = (a: any, b: any) => {
  if (a.created_at.toDate() > b.created_at.toDate()) {
    return -1;
  } else {
    return 1;
  }
};

export const fetchDrillDownAllContacts = (
  organization_id: string,
  stage: string,
  dispatcher: any
) => {
  firestore
    .collection("contacts")
    .where("organization_id", "==", organization_id)
    .where("stage", "==", stage)
    .get()
    .then((contact) => {
      const data: any[] = [];
      if (contact) {
        contact.forEach((contact) => {
          data.push({
            ...contact.data(),
            contactId: contact.id,
            contact_owner_email: contact
              .data()
              .contact_owner_email.toLowerCase(),
          });
        });
        dispatcher(
          setContacts({ [stage]: data.sort(sortContacts) })
        );
      } else {
        console.log("org not found");
      }
    });
};

export const fetchTeamLeadContacts = (
  setContacts: (data: any[]) => void,
  uidList: any[]
) => {
  const subscriber = firestore
    .collection("contacts")
    .where("uid", "in", uidList)
    .onSnapshot((contact) => {
      const data: any[] = [];
      if (contact) {
        contact.forEach((contact) => {
          data.push({
            ...contact.data(),
            contactId: contact.id,
            contact_owner_email: contact
              .data()
              .contact_owner_email.toLowerCase(),
          });
        });
        setContacts(data.sort(sortContacts));
      } else {
        console.log("Team Lead Error");
      }
    });
  return subscriber;
};

export const fetchLeadSources = (
  setLeadSource: (data: any[]) => void,
  organization_id: string
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();

        if (resourceData?.leadSources) {
          setLeadSource(resourceData?.leadSources);
        } else {
          setLeadSource([]);
        }
      } else {
        console.log("Org not found");
      }
    });
  return subscriber;
};

const checkLeadSource = (
  leadSourceList: any[],
  leadSource: any[],
  dispatcher: any
) => {
  let count = 0;

  leadSourceList.forEach((item: any) => {
    if (leadSource.includes(item.leadSource)) {
    } else {
      dispatcher(
        showSnackbarAction(
          "Lead Source At " +
          item.sno +
          " does not exists!!",
          "error"
        )
      );
      count = count + 1;
    }
  });
  if (count === 0) {
    return true;
  } else {
    return false;
  }
};

export const mapContactsTemplate = (
  data: { [key: string]: any }[],
  map: { [key: string]: string },
  organization_id: string,
  dispatcher: any,
  submit: () => void,
  usersList: any[],
  setLoad: (data: boolean) => any,
  leadSource: any[],
  name: string,
  projects: any[],
  locations: any[],
  budgets: any[],
  country: string,
  resTypes: any[],
  comTypes: any[]
) => {
  let templateList: any[] = [];
  let contactList: any[] = [];
  let notesList: any[] = [];
  let leadSourceList: any[] = [];
  let checkStage: boolean = true;
  let numberList: any[] = [];

  data.forEach((contact, index) => {
    if (checkStage === false) {
      setLoad(false);
      return;
    }
    let uid = "";
    if (map["Owner"]) {
      const userData = usersList.filter(
        (item) =>
          String(item.user_email).toLowerCase() ===
          String(contact[map["Owner"]].toLowerCase())
      );
      uid = userData.length === 0 ? "" : userData[0].uid;
      if (uid === "") {
        dispatcher(
          showSnackbarAction(
            "Please Provide The Owner For the Contact At: " +
            contact.sno,
            "error"
          )
        );

        checkStage = false;
        return;
      }
    }

    if (!map.hasOwnProperty("Customer Name")) {
      dispatcher(
        showSnackbarAction(
          "Please Select customer name",
          "error"
        )
      );
      checkStage = false;
      return;
    } else if (!map.hasOwnProperty("Mobile No.")) {
      dispatcher(
        showSnackbarAction(
          "Please Select contact number",
          "error"
        )
      );

      checkStage = false;
      return;
    } else if (!map.hasOwnProperty("Lead Source")) {
      dispatcher(
        showSnackbarAction(
          "Please Select Lead Source",
          "error"
        )
      );

      checkStage = false;

      return;
    } else if (!map.hasOwnProperty("Stage")) {
      dispatcher(
        showSnackbarAction("Please Select Stage", "error")
      );

      checkStage = false;

      return;
    } else if (!map.hasOwnProperty("Country Code")) {
      dispatcher(
        showSnackbarAction(
          "Please Select Country Code",
          "error"
        )
      );

      checkStage = false;

      return;
    } else if (contact[map["Customer Name"]] === "") {
      dispatcher(
        showSnackbarAction(
          "Invalid Customer Name at row " + contact.sno,
          "error"
        )
      );

      checkStage = false;
      return;
    } else if (contact[map["Country Code"]] === "") {
      dispatcher(
        showSnackbarAction(
          "Invalid Country Code at row " + contact.sno,
          "error"
        )
      );

      checkStage = false;
      return;
    } else if (map.hasOwnProperty("Inventory Type")) {
      if (
        contact[map["Inventory Type"]] !== "Primary" &&
        contact[map["Inventory Type"]] !== "Secondary" &&
        contact[map["Inventory Type"]] !== ""
      ) {
        dispatcher(
          showSnackbarAction(
            "Invalid Inventory Type at row " + contact.sno,
            "error"
          )
        );
        checkStage = false;
        return;
      }
    } else if (
      contact[map["Stage"]] === "" ||
      !stageList.includes(
        contact[map["Stage"]].toUpperCase()
      )
    ) {
      dispatcher(
        showSnackbarAction(
          "Invalid Stage at row " + contact.sno,
          "error"
        )
      );

      checkStage = false;
      return;
    }
    if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() === "INTERESTED"
    ) {

      checkStage = InterestedLeadValidation(
        map,
        dispatcher,
        contact,
        locations,
        projects,

        budgets,
        resTypes,
        comTypes
      );
    } else if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() === "LOST"
    ) {
      checkStage = LostLeadValidation(
        map,
        dispatcher,
        contact,
        locations,
        budgets,
        projects,
        comTypes,
        resTypes
      );
    } else if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() === "CALL BACK"
    ) {
      checkStage = CallbackValidation(
        map,
        dispatcher,
        contact
      );
    } else if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() ===
      "NOT INTERESTED"
    ) {
      checkStage = NotInterestedValidation(
        map,
        dispatcher,
        contact
      );
    } else if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() === "WON"
    ) {
      checkStage = WonValidation(
        map,
        dispatcher,
        contact,
        locations,
        budgets,
        projects,
        comTypes,
        resTypes
      );
    } else if (
      map["Stage"] &&
      contact[map["Stage"]].toUpperCase() === "FRESH"
    ) {
      checkStage = FreshLeadValidation(
        map,
        dispatcher,
        contact,
        locations,
        projects,
        budgets,
        resTypes,
        comTypes
      );
    } else {
      dispatcher(
        showSnackbarAction(
          "Invalid Stage at row: " + contact.sno,
          "error"
        )
      );
      checkStage = false;
    }
    if (checkStage === false) {
      return;
    }

    templateList.push({
      alternate_no: map["Alternate No."]
        ? contact[map["Alternate No."]]
          ? contact[map["Alternate No."]]
          : ""
        : "",
      organization_id: organization_id,
      associate_status: true,
      budget: map["Budget"] ? contact[map["Budget"]] : "",
      contact_no: map["Mobile No."]
        ? validateNumber({
          contact_no: contact[map["Mobile No."]],
          country,
        }).contact_no
        : "",
      country_code: map["Country Code"]
        ? contact[map["Country Code"]]
        : "",
      created_at: map["Created At"]
        ? contact[map["Created At"]] !== ""
          ? Firebase.firestore.Timestamp.fromDate(
            moment(
              contact[map["Created At"]],
              "MM/DD/YY HH:mm"
            ).toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      created_by: map["Created By"]
        ? contact[map["Created By"]]
          ? contact[map["Created By"]]
          : name
        : name,
      call_back_reason: map["Call Back Reason"]
        ? contact[map["Call Back Reason"]]
        : "",
      customer_image: "",
      customer_name: map["Customer Name"]
        ? contact[map["Customer Name"]]
        : "",
      email: map["Email ID"]
        ? contact[map["Email ID"]].toLowerCase()
        : "",
      lead_source: map["Lead Source"]
        ? contact[map["Lead Source"]]
        : "",
      lead_assign_time: map["Lead Assign At"]
        ? contact[map["Lead Assign At"]] !== ""
          ? Firebase.firestore.Timestamp.fromDate(
            moment(
              contact[map["Lead Assign At"]],
              "MM/DD/YY HH:mm"
            ).toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      modified_at: Firebase.firestore.Timestamp.now(),
      addset: map["Addset"]
        ? contact[map["Addset"]]
          ? contact[map["Addset"]]
          : ""
        : "",
      campaign: map["Campaign"]
        ? contact[map["Campaign"]]
          ? contact[map["Campaign"]]
          : ""
        : "",
      stage_change_at: map["Stage Change At"]
        ? contact[map["Stage Change At"]] !== ""
          ? Firebase.firestore.Timestamp.fromDate(
            moment(
              contact[map["Stage Change At"]],
              "MM/DD/YY HH:mm"
            ).toDate()
          )
          : Firebase.firestore.Timestamp.now()
        : Firebase.firestore.Timestamp.now(),
      location: map["Location"]
        ? contact[map["Location"]]
        : "",
      lost_reason: map["Lost Reason"]
        ? contact[map["Lost Reason"]]
        : "",
      not_int_reason: map["Not interested reason"]
        ? contact[map["Not interested reason"]]
        : "",
      other_not_int_reason: "",
      other_lost_reason: "",
      previous_owner: "",
      project: map["Project"]
        ? contact[map["Project"]]
        : "",
      property_stage: map["Property Stage"]
        ? properFormat(contact[map["Property Stage"]])
        : "",
      property_type: map["Property Type"]
        ? properFormat(contact[map["Property Type"]])
        : "",
      property_sub_type: map["Property Sub Type"]
        ? properFormat(contact[map["Property Sub Type"]])
        : "",
      source_status: true,
      inventory_type: map["Inventory Type"]
        ? properFormat(contact[map["Inventory Type"]])
        : "",
      stage: map["Stage"]
        ? contact[map["Stage"]].toUpperCase() ===
          "CALL BACK"
          ? "CALLBACK"
          : contact[map["Stage"]].toUpperCase()
        : "FRESH",
      transfer_status: false,
      uid: uid,
      contact_owner_email: map["Owner"]
        ? contact[map["Owner"]].toLowerCase()
        : "",
      feedback_time: "",
      next_follow_up_type: map["Next Follow Up Type"]
        ? contact[map["Next Follow Up Type"]]
        : "",
      next_follow_up_date_time: map[
        "Next Follow Up Date & Time"
      ]
        ? contact[map["Next Follow Up Date & Time"]] !== ""
          ? Firebase.firestore.Timestamp.fromDate(
            moment(
              contact[map["Next Follow Up Date & Time"]],
              "MM/DD/YY HH:mm"
            ).toDate()
          )
          : ""
        : "",
    });

    if (
      numberList.includes(
        validateNumber({
          contact_no: contact[map["Mobile No."]],
          country,
        }).contact_no
      )
    ) {
      dispatcher(
        showSnackbarAction(
          "Repeted Contact at " + contact.sno,
          "error"
        )
      );

      checkStage = false;
      return;
    } else {
      contactList.push({
        contact_no: validateNumber({
          contact_no: contact[map["Mobile No."]],
          country,
        }).contact_no,
        sno: contact.sno,
      });
      numberList.push(
        validateNumber({
          contact_no: contact[map["Mobile No."]],
          country,
        }).contact_no
      );
    }
    if (map.hasOwnProperty("Notes")) {
      notesList.push(contact[map["Notes"]]);
    }
    leadSourceList.push({
      leadSource: contact[map["Lead Source"]],
      sno: contact.sno,
    });
  });

  if (templateList.length === data.length) {
    let check = false;
    check = checkLeadSource(
      leadSourceList,
      leadSource,
      dispatcher
    );
    if (check === true) {
      createContactsFirebase(
        templateList,
        contactList,
        dispatcher,
        submit,
        (data: boolean) => setLoad(data),
        organization_id,
        notesList
      );
    } else {
      setLoad(false);
    }
  }
  if (templateList.length === 0) {
    setLoad(false);
  }
};

const createContactsFirebase = async (
  data: any[],
  contactList: any[],
  dispatcher: any,
  submit: () => void,
  setLoad: (data: boolean) => any,
  organization_id: any,
  notesList: any[]
) => {
  const contacts: string[] = [];

  contactList.forEach((item) =>
    contacts.push(item.contact_no)
  );
  const funcData = JSON.stringify({
    organization_id: organization_id,
    contact_no: contacts,
  });
  const result = await functions.httpsCallable(
    "checkMultipleLead"
  )(funcData);

  if (result.data !== true) {
    const contact = contactList.filter(
      (item) => item.contact_no === result.data
    );
    dispatcher(
      showSnackbarAction(
        result.data +
        " Contact Already Exists at " +
        contact[0].sno,
        "error"
      )
    );
    setLoad(false);
  } else {
    if (data.length > 500) {
      dispatcher(setTotal(data.length));
      const splitedData = chunk(data, 500);
      const notesData = chunk(notesList, 500);
      dispatcher(setCompleted(0));

      let total = 0;
      try {
        for (let i = 0; i < splitedData.length; i++) {
          total += splitedData[i].length;
          await createMultipleContact(
            splitedData[i],
            notesData[i]
          );

          dispatcher(setCompleted(total));
        }
        dispatcher(setTotal(0));
        sendNotifications(data, organization_id);
        dispatcher(
          showSnackbarAction(
            "Contacts Uploaded!!",
            "success"
          )
        );
        submit();
        setLoad(false);
      } catch (error) {
        console.log("Create contacts error", error);
        setLoad(false);
      }
    } else {
      createMultipleContact(data, notesList)
        .then(() => {
          sendNotifications(data, organization_id);
          dispatcher(
            showSnackbarAction(
              "Contacts Uploaded!!",
              "success"
            )
          );
          submit();
          setLoad(false);
        })
        .catch((error) => {
          console.log("Create contacts error", error);
          setLoad(false);
        });
    }
  }
};

const createMultipleContact = async (
  data: any[],
  notesList: any[]
) => {
  const batch = firestore.batch();
  const notesBatch = firestore.batch();

  data.forEach((item, index) => {
    const contactsRef = firestore
      .collection("contacts")
      .doc();
    const notesRef = firestore
      .collection("contactResources")
      .doc(contactsRef.id);
    batch.set(contactsRef, item, { merge: true });
    if (notesList !== undefined && notesList.length !== 0) {
      if (notesList[index] !== "") {
        notesBatch.set(
          notesRef,
          {
            notes: [
              {
                note: notesList[index],
                created_at:
                  Firebase.firestore.Timestamp.now(),
              },
            ],
          },
          { merge: true }
        );
      }
    }
  });
  await batch.commit();
  if (notesList !== undefined && notesList.length !== 0) {
    await notesBatch.commit();
  }
};

const sendNotifications = async (
  data: any[],
  organization_id: string
) => {
  const notificationData: { [key: string]: number } = {};
  data.forEach((item) => {
    if (item.uid !== "") {
      if (notificationData[item.uid]) {
        notificationData[item.uid] += 1;
      } else {
        notificationData[item.uid] = 1;
      }
    }
  });
  functions.httpsCallable("sendNotifications")(
    JSON.stringify({
      organization_id: organization_id,
      notifications: notificationData,
    })
  );
};

export const handleFileUpload = (
  file: any,
  setData: (data: any[]) => void,
  setColumns: (data: any[]) => void,
  setLoad: (data: boolean) => void
) => {
  setTimeout(
    () =>
      readFile(
        file,
        (data: any) => setData(data),
        (col: any) => setColumns(col)
      ),
    100
  );
  setLoad(false);
};

const readFile = (
  file: any,
  setData: (data: any[]) => void,
  setColumns: (data: any[]) => void
) => {
  const reader = new FileReader();
  reader.onload = (evt: ProgressEvent<FileReader>) => {
    /* Parse data */
    const bstr = evt?.target?.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws: any = wb.Sheets[wsname];
    /* Convert array of arrays */
    //@ts-ignore
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    processData(
      data,
      (data: any) => setData(data),
      (col: any) => setColumns(col)
    );
  };
  reader.readAsBinaryString(file);
};

const processData = (
  dataString: any,
  setData: (data: any[]) => void,
  setColumns: (data: any[]) => void
) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(
    /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  );

  const list: any = [];
  let sno = 1;
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    if (headers && row.length === headers.length) {
      let obj: any = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"')
            d = d.substring(1, d.length - 1);
          if (d[d.length - 1] === '"')
            d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) {
          obj[headers[j]] = d;
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter((x) => x).length > 0) {
        obj = { ...obj, sno };
        list.push(obj);
        sno = sno + 1;
      }
    }
  }

  // prepare columns list from headers
  const columns = headers.map((c: any) => ({
    name: c,
    selector: c,
  }));

  setData(list);
  setColumns(columns);
};

export const fetchTasks = (
  setTasks: (data: any[]) => void,
  organization_id: string,
  user: any,
  branchUsers: any[]
) => {
  const subscriber = firestore
    .collection("tasks")
    .where("organization_id", "==", organization_id)
    .onSnapshot((task) => {
      let data: any[] = [];
      if (task) {
        if (user.branchPermission) {
          task.docs.forEach((task) => {
            if (branchUsers.includes(task.data().uid)) {
              data = data.concat(task.data().tasks);
            }
          });
        } else {
          task.docs.forEach((task) => {
            data = data.concat(task.data().tasks);
          });
        }

        data.forEach((task) => {
          if (
            task.status === "Pending" &&
            task.due_date.toDate() < new Date()
          ) {
            task.status = "Overdue";
          }
        });

        setTasks(data);
      } else {
        console.log("org not found");
      }
    });
  return subscriber;
};

export const fetchLeadTasks = (
  setTasks: (data: any[]) => void,
  contactId: string
) => {
  const subscribe = firestore
    .collection("tasks")
    .doc(contactId)
    .onSnapshot((task) => {
      if (task) {
        const data = task.data();
        if (data) {
          setTasks(data.tasks);
        } else {
          setTasks([]);
        }
        console.log("fetchLeadTasks___data",data)
        console.log("fetchLeadTasks___contactId",contactId)
        // console.log("fetchLeadTasks___data",data)
      } else {
        console.log("Contact not Found");
      }
    });
  return subscribe;
};

export const fetchContactResources = (
  setNotes: (data: any[]) => void,
  setAttachments: (data: any[]) => void,
  setCallLogs: (data: any[]) => void,
  contactId: string
) => {
  const subscribe = firestore
    .collection("contactResources")
    .doc(contactId)
    .onSnapshot((resources) => {
      if (resources) {
        const data = resources.data();
        console.log("contactId",contactId);
        console.log("notes data ",data && data.callLogs);
        if (data?.notes) {
          
          setNotes(data.notes);
        } else {
          setNotes([]);
        }
        if (data?.attachments) {
          setAttachments(data.attachments);
        } else {
          setAttachments([]);
        }
        if (data?.callLogs) {
          setCallLogs(data.callLogs);
        } else {
          setCallLogs([]);
        }
      } else {
        console.log("contact not Found");
      }
    });
  return subscribe;
};
export const fetchCallLogs = (
  setCallList: (data: any[]) => void,
  organization_id: string
) => {
  let callList: any[] = [];
  const subscribe = firestore
    .collection("contactResources")
    .where("organization_id", "==", organization_id)
    .onSnapshot((resources) => {
      resources.docs.forEach((resource) => {
        const data = resource.data();

        if (data?.callLogs) {
          callList.push({
            uid: data.uid,
            data: data.callLogs,
            contactId: resource.id,
          });
        }
      });
      setCallList(callList);
    });
  return subscribe;
};

export const fetchUserCallLogs = (
  setCallList: (data: any[]) => void,
  uid: string
) => {
  let callList: any[] = [];
  const subscribe = firestore
    .collection("contactResources")
    .where("uid", "==", uid)
    .onSnapshot((resources) => {
      resources.docs.forEach((resource) => {
        const data = resource.data();

        if (data?.callLogs) {
          callList.push({
            uid: data.uid,
            data: data.callLogs,
            contactId: resource.id,
          });
        }

        setCallList(callList);
      });
    });
  return subscribe;
};
export const fetchProjects = (
  setProjects: (data: any[]) => void,
  organization_id: string
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((projects) => {
      if (projects) {
        const projectData = projects.data();
        if (projectData?.projects) {
          setProjects(projectData?.projects);
        } else {
          setProjects([]);
        }
      } else {
        setProjects([]);
        console.log("Org not found");
      }
    });
  return subscriber;
};

export const deleteProject = async (
  organization_id: string,
  projectList: any[],
  index: number[],
  dispatcher: any,
  setLoad: (data: boolean) => any
) => {

  let data: any[] = [];
  firestore
    .collection("contacts")
    .where("organization_id", "==", organization_id)
    .where("project", "==", "project")
    .get()
    .then((contact) => {
      if (contact) {
        contact.forEach((item) => {
          data.push(item.data());
        });
      }

      if (data.length !== 0) {
        dispatcher(
          showSnackbarAction(
            "This Project Is Used In Some Contacts",
            "error"
          )
        );
        setLoad(false);
      } else {
        for (var i = index.length - 1; i >= 0; i--) {
          projectList.splice(index[i], 1);
        }

        try {
          firestore
            .collection("organizationResources")
            .doc(organization_id)
            .set(
              {
                projects: projectList,
              },
              { merge: true }
            );
          dispatcher(
            showSnackbarAction("Project Deleted", "success")
          );
          setLoad(true);
        } catch (e) {
          console.log("Error:", e);
          dispatcher(
            showSnackbarAction("Error! Try Again.", "error")
          );
          setLoad(false);
        }
      }
    });
};

export const craeteMultipleProject = (
  data: { [key: string]: any }[],
  map: { [key: string]: string },
  organization_id: string,
  dispatcher: any,
  submit: () => void,
  usersList: any[],
  setLoad: (data: boolean) => any
) => { };

export const createProject = (
  organization_id: string,
  project_name: string,
  developer_name: string,
  address: string,
  rera_link: string,
  property_stage: string,
  project_status:string,
  walkthrough_link: string,
  type: string,
  created_by: string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => any
) => {
  const doc = firestore
    .collection("projectResources")
    .doc();
  doc.set(
    {
      brochures: [],
      priceLists: [],
      forms: [],
      layouts: [],
      organization_id: organization_id,
    },
    { merge: true }
  );
  firestore
    .collection("organizationResources")
    .doc(organization_id)
    .set(
      {
        organization_id: organization_id,
        projects: Firebase.firestore.FieldValue.arrayUnion({
          project_name: project_name,
          developer_name: developer_name,
          address: address,
          walkthrough_link: walkthrough_link,
          rera_link: rera_link,
          property_type: type,
          property_stage: property_stage,
          project_status:project_status,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: created_by,
          project_id: doc.id,
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("Project Added", "success")
      );
      setLoad(false);
      history.push("./projects");
    })
    .catch((error) => {
      console.log("Projects Error", error);
      setLoad(false);
    });
};

export const changeOwner = async (
  selectedOwner: any,
  selectedRowsData: any[],
  dispatcher: any,
  organization_id: string
) => {
  const batch = firestore.batch();

  if (selectedRowsData.length > 500) {
    return false;
  } else {
    selectedRowsData.forEach((item) => {
      const contactsRef = firestore
        .collection("contacts")
        .doc(item.contactId);
      const tasksRef = firestore
        .collection("tasks")
        .doc(item.contactId);
      batch.set(
        contactsRef,
        {
          uid: selectedOwner.uid,
          contact_owner_email:
            selectedOwner.user_email.toLowerCase(),
          lead_assign_time:
            Firebase.firestore.Timestamp.now(),
          modified_at: Firebase.firestore.Timestamp.now(),
          previous_owner: item.contact_owner_email,
          previous_owner_one: item.previous_owner
            ? item.previous_owner
            : "",
        },
        { merge: true }
      );
      batch.set(
        tasksRef,
        {
          uid: selectedOwner.uid,
        },
        { merge: true }
      );
    });

    try {
      await batch.commit();

      let uid = selectedOwner.uid;
      functions.httpsCallable("sendNotifications")(
        JSON.stringify({
          organization_id: organization_id,
          notifications: { [uid]: selectedRowsData.length },
        })
      );
      dispatcher(
        showSnackbarAction("Owner Updated!!", "success")
      );
    } catch (error) {
      console.log("error", error);
      dispatcher(showSnackbarAction("Error!!", "error"));
    }
  }
};

export const bulkEdit = async (
  changeData: { [key: string]: any },
  selectedRowsData: any[],
  dispatcher: any,
  tasksList:any,
  setLoad: (value: boolean) => void,
  close: () => void,
) => {
  const batch = firestore.batch();

  if (selectedRowsData.length > 500) {
    return false;
  } else {
    selectedRowsData.forEach((item) => {
      const contactsRef = firestore
        .collection("contacts")
        .doc(item.contactId);
        const taskRef = firestore
        .collection("tasks")
        .doc(item.contactId); 
      if(changeData.lost_reason ){

        if (tasksList && tasksList.length !== 0) {
         
          let taskData = {
            status: "INACTIVE",
            tasks: modifyTaskStatus(tasksList),
          };
          batch.update(taskRef, { ...taskData });
        }
        // {changeData.not_int_reason&&changeLeadStage(
        //   item.contactId,
        //   {
        //     not_int_reason: changeData.not_int_reason,
        //     stage: "NOT INTERESTED",
        //     other_not_int_reason: "",
        //   },
        //   (data) => setLoad(data),
        //   dispatcher,
        //   () => {},
        //   // history,
        //   tasksList
        // );}
        changeLeadStage(
          item.contactId,
          {
            lost_reason: changeData.lost_reason,
            stage: "LOST",
            other_lost_reason: "",
          },
          (data) => setLoad(data),
          dispatcher,
          () => {},
          // history,
          tasksList
        );
        batch.set(contactsRef, {...changeData,stage_change_at: Firebase.firestore.Timestamp.now()}, { merge: true });
      }else{
        batch.set(contactsRef, changeData, { merge: true });
      }
    });
    try {
      await batch.commit();
      dispatcher(
        showSnackbarAction("Leads Updated!!", "success")
      );
    } catch (error) {
      console.log("error");
      dispatcher(showSnackbarAction("Error!!", "error"));
    }
  }
};

export const contactResources = (
  organization_id?: string,
  setLocations?: (data: any[]) => void,
  setBudgets?: (data: any[]) => void,
  setProjects?:(data: any[]) => void,
  setLeadSource?: (data: any[]) => void,
  setProjectDeveloperObj?:(data: any[]) => void,
) => {
  // console.log("organizasbbsbstion_id",organization_id);
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();
        console.log("resourceData",resourceData);
        if (resourceData?.locations) {
          let data: any = [];
          resourceData.locations.forEach((item: any) => {
            data.push(item.location_name);
          });
          setLocations && setLocations(data);
        } else {
          setLocations && setLocations([]);
        }
        if (resourceData?.budgets) {
          let data: any = [];
          resourceData.budgets.forEach((item: any) => {
            data.push(item.budget);
          });

          setBudgets && setBudgets(data);
        } else {
          setBudgets && setBudgets([]);
        }
        if (resourceData?.projects) {
          let data: any = [];
          let prodevData: any = [];
          // resourceData.projects?.filter((item:any)=>item.project_status==="Launched")?.forEach((item: any) => {
          //   data.push(item.project_name);
          // });
          resourceData.projects?.forEach((item: any) => {
            data.push(item.project_name);
          });
          setProjects && setProjects(data);
          // resourceData.projects?.filter((item:any)=>item.project_status==="Launched")?.forEach((item: any) => {
          //   prodevData.push({"project_name":item.project_name,"developer_name":item.developer_name});
          // });
          resourceData.projects?.forEach((item: any) => {
            prodevData.push({"project_name":item.project_name,"developer_name":item.developer_name});
          });
          setProjectDeveloperObj && setProjectDeveloperObj(prodevData)
        } else {
          setProjectDeveloperObj && setProjectDeveloperObj([]);
          setProjects && setProjects([]);
        }
        if (resourceData?.leadSources) {
          let data: any[] = [];
          resourceData?.leadSources.forEach((item: any) => {
            data.push(item.leadSource);
          });
          setLeadSource && setLeadSource(data);
        } else {
          setLeadSource && setLeadSource([]);
        }
      } else {
        console.log("Org not found");
      }
    });
  return subscriber;
};

export const editContact = async (
  contactId: string,
  first_name: string,
  last_name: string,
  contact_no: string,
  alternate_no: string,
  stage: string,
  project: string,
  budget: string,
  location: string,
  property_type: string,
  property_stage: string,
  lead_type: string,
  lead_source: string,
  addset:string,
  campaign:string,
  field_one:string,
  field_two:string,
  field_three:string,
  field_four:string,
  field_five:string,
  field_six:string,
  dispatcher: any,
  history: any,
  setLoad: (data: boolean) => void,
  check: boolean,
  organization_id: string,
  property_sub_type: string,
  country_code: string,
  contactNumberChange: boolean,
  alternateNumberChange: boolean,
  email:string

) => {
  if (check === true) {
    // let result;
    // if (alternate_no !== "") {
    //   const res = await functions.httpsCallable(
    //     "checkLead"
    //   )(
    //     JSON.stringify({
    //       organization_id: organization_id,
    //       contactList: [contact_no, alternate_no],
    //     })
    //   );
    //   result = res;
    // } else {
    //   const res = await functions.httpsCallable(
    //     "checkLead"
    //   )(
    //     JSON.stringify({
    //       organization_id: organization_id,
    //       contact_no: contact_no,
    //     })
    //   );
    //   result = res;
    // }
    let funcData;
    if(contactNumberChange && alternateNumberChange){
       funcData = JSON.stringify({
        organization_id: organization_id,
        contactList: [contact_no, alternate_no],
      });
    }
    else if(contactNumberChange){
      funcData = JSON.stringify({
       organization_id: organization_id,
       contact_no: contact_no,
     });
   }
   else if(alternateNumberChange){
    funcData = JSON.stringify({
     organization_id: organization_id,
     contact_no: alternate_no,
   });
  }
    const result = await functions.httpsCallable(
      "checkLead"
    )(funcData);
    if (result.data === false) {
      dispatcher(
        showSnackbarAction(
          // "This Contact Number Already Exists",
          "Contact number or Alternate number already exists on another lead",
          "warning"
        )
      );
      setLoad && setLoad(false);
    } else {
      firestore
        .collection("contacts")
        .doc(contactId)
        .set(
          {
            alternate_no,
            budget,
            contact_no,
            customer_name: first_name + " " + last_name,
            email,
            country_code,
            lead_source,
            campaign,
            addset,
            location,
            // lost_reason,
            modified_at: Firebase.firestore.Timestamp.now(),

            // not_init_reason,
            // other_not_init_reason,
            // other_lost_reason,

            project,
            property_stage,
            property_type,
            property_sub_type,
            stage,
            field_one:field_one,
            field_two:field_two,
            field_three:field_three,
            field_four:field_four,
            field_five:field_five,
            field_six:field_six
          },
          { merge: true }
        )
        .then(() => {
          dispatcher(
            showSnackbarAction(
              "Record has been updated successfully !",
              "success"
            )
          );
          setLoad(false);
          // history.replace("/");
          setTimeout(() => {
            history.replace("/");
            dispatcher(setRefreshContacts(true));
          }, 1000);

        })
        .catch((e) => {
          console.log("Error edit:", e);
          dispatcher(
            showSnackbarAction(
              "Error! Try Again!!",
              "error"
            )
          );
          setLoad(false);
        });
    }
  } 
  else {
    firestore
      .collection("contacts")
      .doc(contactId)
      .set(
        {
          alternate_no,
          budget,
          contact_no,
          customer_name: first_name + " " + last_name,
          email,
          country_code,
          lead_source,
          campaign,
          addset,
          location,
          // lost_reason,
          // not_init_reason,
          // other_not_init_reason,
          // other_lost_reason,

          project,
          property_stage,
          lead_type,
          property_type,
          property_sub_type,
          field_one:field_one,
          field_two:field_two,
          field_three:field_three,
          field_four:field_four,
          field_five:field_five,
          field_six:field_six,
          stage,
        },
        { merge: true }
      )
      .then(() => {
        dispatcher(
          showSnackbarAction(
            "Contact Details Edited!!",
            "success"
          )
        );
        setLoad(false);
        // history.replace("/");
        setTimeout(() => {
          history.replace("/");
          dispatcher(setRefreshContacts(true));
        }, 1000);
      })
      .catch((e) => {
        console.log("Error edit:", e);
        dispatcher(
          showSnackbarAction("Error! Try Again!!", "error")
        );
        setLoad(false);
      });
  }
};

export const fetchConstants = (
  setNotIntReason: (data: any[]) => void,
  setLostReason: (data: any[]) => void,
  setPropertyStage: (data: any[]) => void,
  setPropertyType: (data: any[]) => void,
  setFollowUpType?: (data: any[]) => void,
  setCallBack?: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("values")
    .doc("constants")
    .onSnapshot((constant) => {
      if (constant) {
        if (constant.data()?.not_interested) {
          setNotIntReason(constant.data()?.not_interested);
        }
        if (constant.data()?.lost_reasons) {
          setLostReason(constant.data()?.lost_reasons);
        }
        if (constant.data()?.property_stage) {
          setPropertyStage(constant.data()?.property_stage);
        }
        if (constant.data()?.property_type) {
          setPropertyType(constant.data()?.property_type);
        }
        if (
          constant.data()?.follow_up_type &&
          setFollowUpType
        ) {
          setFollowUpType(constant.data()?.follow_up_type);
        }
        if (constant.data()?.call_back && setCallBack) {
          setCallBack(constant.data()?.call_back);
        }
      }
    });
  return subscriber;
};

export const fetchOrganizationResources = (
  setNotIntReason: (data: any[]) => void,
  setLostReason: (data: any[]) => void,
  setPropertyStage: (data: any[]) => void,
  setPropertyType: (data: any[]) => void,
  setFollowUpType?: (data: any[]) => void,
  setCallBack?: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("values")
    .doc("constants")
    .onSnapshot((constant) => {
      if (constant) {
        if (constant.data()?.not_interested) {
          setNotIntReason(constant.data()?.not_interested);
        }
        if (constant.data()?.lost_reasons) {
          setLostReason(constant.data()?.lost_reasons);
        }
        if (constant.data()?.property_stage) {
          setPropertyStage(constant.data()?.property_stage);
        }
        if (constant.data()?.property_type) {
          setPropertyType(constant.data()?.property_type);
        }
        if (
          constant.data()?.follow_up_type &&
          setFollowUpType
        ) {
          setFollowUpType(constant.data()?.follow_up_type);
        }
        if (constant.data()?.call_back && setCallBack) {
          setCallBack(constant.data()?.call_back);
        }
      }
    });
  return subscriber;
};

export const getDataFromRoute = (contacts: any[]) => {
  let data = JSON.parse(JSON.stringify(contacts));
  data.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      if (datesField.includes(key) && item[key] !== "") {
        item[key] = moment(item[key]);
      }
    });
  });
  return data;
};

export const changeDateForRoute = (contacts: any[]) => {
  let data = JSON.parse(JSON.stringify(contacts));

  data.forEach((item: any, index: number) => {
    Object.keys(item).forEach((key) => {
      if (datesField.includes(key) && item[key] !== "") {
        item[key] = contacts[index][key].toDate
          ? contacts[index][key].toDate()
          : "";
      }
    });
  });
  return JSON.stringify(data);
};

export const changeLeadStage = (
  leadId: string,
  leadData: {},
  setLoad: (value: boolean) => void,
  dispatcher: any,
  close: () => void,
  history: any,
  taskList?: any
) => {
  setLoad(true);
  const batch = firestore.batch();
  const taskCollection = firestore
    .collection("tasks")
    .doc(leadId);
  const leadCollection = firestore
    .collection("contacts")
    .doc(leadId);
  batch.update(leadCollection, {
    ...leadData,
    modified_at: Firebase.firestore.Timestamp.now(),
    stage_change_at: Firebase.firestore.Timestamp.now(),
  });

  if (taskList && taskList.length !== 0) {
    let taskData = {
      status: "INACTIVE",
      tasks: modifyTaskStatus(taskList),
    };
    batch.update(taskCollection, { ...taskData });
  }

  batch
    .commit()
    .then(() => {
      setLoad(false);
      dispatcher(
        showSnackbarAction(
          "Lead Status Updated!!",
          "success"
        )
      );
      close();
      // history.push("/");
    })
    .catch((error) => {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      console.log("change stage error", error);
      close();
    });
};

export const createTaskFirebase = (
  leadId: string,
  tasks: any[],
  task: any,
  leadDataUpdate: {},
  setLoad: (value: boolean) => any,
  dispatcher: any,
  user: any,
  close: () => void,
  history: any,
  completed?: boolean,
  latitude?: any,  
  longitude?: any
) => {
  if (completed === false) tasks = cancelTaskStatus(tasks);
  else tasks = 
  (tasks);
  task = {
    ...task,
    created_at: Firebase.firestore.Timestamp.now(),
    leadId,
    created_by: user.user_email,
    latitude: latitude || null,   
    longitude: longitude || null,
  };
  
  leadDataUpdate={
    ...leadDataUpdate,
    latitude: latitude || null,   
    longitude: longitude || null,
  }
  
  setLoad(true);
  tasks.unshift(task);
  const batch = firestore.batch();
  const taskCollection = firestore
    .collection("tasks")
    .doc(leadId);
  const leadCollection = firestore
    .collection("contacts")
    .doc(leadId);
  batch.set(
    taskCollection,
    {
      status: "ACTIVE",
      tasks,
      uid: user.uid,
      organization_id: user.organization_id,
    },
    { merge: true }
  );
  batch.update(leadCollection, leadDataUpdate);
  batch
    .commit()
    .then(() => {
      setLoad(false);
      dispatcher(
        showSnackbarAction(
          "Lead Status Updated!!",
          "success"
        )
      );
      close();
    })
    .catch((error) => {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      console.log("Task Create Error", error);
    });
};

export const reScheduleTaskFirebase = async (
  date: Date,
  tasks: any[],
  leadId: string,
  dispatcher: any,
  history: any
) => {
  let task = { ...tasks[0] };
  task.due_date = date;
  tasks[0] = task;
  try {
    const batch = firestore.batch();
    const taskCollection = firestore
      .collection("tasks")
      .doc(leadId);
    const leadCollection = firestore
      .collection("contacts")
      .doc(leadId);
    batch.set(taskCollection, { tasks }, { merge: true });
    batch.update(leadCollection, {
      next_follow_up_date_time: date,
      modified_at: Firebase.firestore.Timestamp.now(),
    });
    await batch.commit().then(() => {
      dispatcher(
        showSnackbarAction("Task Reshduled!", "success")
      );
      history.push("/");
    });
  } catch (error) {
    dispatcher(showSnackbarAction("Try Again!!", "error"));
    console.log("Reschdule Error", error);
  }
};

export const validateInterested = (
  firstName: string,
  lastName: string,
  propertyType: string,
  propertySubType: string,
  propertyStage: string,
  location: string,
  project: string,
  budget: string,
  followUp: string,
  date: Date,
  leadSource: string,
  alternate: string,
  property_sub_type: string,
  dispatcher: any
) => {
  let error = "";
  if (firstName.length == 0) {
    error = "First Name is required!";
  } else if (lastName.length == 0) {
    error = "Last Name is required!";
  } else if (propertyType === "Select") {
    error = "Property Type is required!";
  } else if (propertySubType === "Select") {
    error = "Property Sub Type is required!";
  } else if (propertySubType === "Select") {
    error = "Property Sub Type is required!";
  } else if (leadSource === "Select") {
    error = "Lead Source is required!";
  } else if (propertyStage === "Select") {
    error = "Property Stage is required!";
  } else if (location === "Select") {
    error = "Location is required!";
  } else if (project === "Select") {
    error = "Project is required!";
  } else if (budget === "Select") {
    error = "Budget is required!";
  } else if (followUp === "Select") {
    error = "Follow Up is required!";
  } else if (date < new Date()) {
    error = "Task Cannot be Schedule For Old Date & Time!";
  }
  if (error == "") {
    return {
      budget: budget,
      customer_name: firstName + " " + lastName,
      location: location,
      project: project,
      property_stage: propertyStage,
      property_type: propertyType,
      property_sub_type: property_sub_type,
      stage: "INTERESTED",
      alternate_no: alternate,
      next_follow_up_type: followUp,
      next_follow_up_date_time: moment(date).toDate(),
      modified_at: Firebase.firestore.Timestamp.now(),
      stage_change_at: Firebase.firestore.Timestamp.now(),
      lead_source: leadSource,
    };
  } else {
    dispatcher(showSnackbarAction(error, "error"));
    return undefined;
  }
};

export const modifyTaskStatus = (tasks: any[]) => {
  if (tasks.length < 1) {
    return tasks;
  }
  let taskData = { ...tasks[0] };
  taskData.status = "Completed";
  taskData = {
    ...taskData,
    completed_at: Firebase.firestore.Timestamp.now(),
  };
  tasks[0] = taskData;
  return tasks;
};

export const cancelTaskStatus = (tasks: any[]) => {
  if (tasks.length < 1) {
    return tasks;
  }
  let taskData = { ...tasks[0] };
  taskData.status = "Cancelled";
  tasks[0] = taskData;
  return tasks;
};

export const uploadAttachment = async (
  leadId: string,
  file: any,
  attachments: any[],
  type: string,
  filename: string,
  dispatcher: any,
  setLoad: (data: boolean) => any,
  close: () => void
) => {
  const path = `${leadId}/${type}/${filename}`;
  const reference = storage.ref(path);
  await reference.put(file);
  const url = await reference.getDownloadURL();
  const firestoreData = {
    name: filename,
    type: type,
    url: url,
    created_at: Firebase.firestore.Timestamp.now(),
  };

  const result = await addAttachmentFirestore(
    leadId,
    firestoreData,
    attachments
  );
  if (result) {
    dispatcher(
      showSnackbarAction(
        "Uploaded Successfully!",
        "success"
      )
    );
    setLoad(false);
    close();
  } else {
    dispatcher(
      showSnackbarAction("Upload Error!!", "error")
    );
    setLoad(false);
    close();
  }
};

export const addAttachmentFirestore = async (
  leadId: string,
  data: {},
  attachments: any[]
) => {
  let attachmentsData: any[] = [...attachments];
  attachmentsData.unshift(data);
  try {
    await firestore
      .collection("contactResources")
      .doc(leadId)
      .set(
        {
          attachments: attachmentsData,
        },
        { merge: true }
      );
    return true;
  } catch (error) {
    console.log("attachments firestore", error);
    return false;
  }
};

export const addNoteFirebase = (
  leadId: string,
  notes: any[],
  note: string,
  setLoad: (value: boolean) => void,
  dispatcher: any,
  close?: () => void,
  userEmail?: string
) => {

  if (note.length === 0) {
    dispatcher(
      showSnackbarAction("Please Add A Note", "error")
    );
    return;
  }
  setLoad(true);
  let noteData = [...notes];
  noteData.unshift({
    note: note,
    created_at: Firebase.firestore.Timestamp.now(),
    userEmail: userEmail,
  });
  firestore
    .collection("contactResources")
    .doc(leadId)
    .set(
      {
        notes: noteData,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction("Saved", "success"));
      setLoad(false);
      close && close();
    })
    .catch((error) => {
      setLoad(false);
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      console.log("AddNote Error", error);
    });
};

export const fetchpropertySubType = (
  organization_id: string,
  setComTypes: (data: any[]) => void,
  setResTypes: (data: any[]) => void,
  setLeadSource: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((doc) => {
      const resourceData = doc.data();
      if (resourceData?.comTypes) {
        setComTypes(resourceData?.comTypes);
      } else {
        setComTypes([]);
      }
      if (resourceData?.resTypes) {
        setResTypes(resourceData?.resTypes);
      } else {
        setResTypes([]);
      }
      if (resourceData?.leadSources) {
        let data: any[] = [];
        resourceData?.leadSources.forEach((item: any) => {
          data.push(item.leadSource);
        });
        setLeadSource(data);
      } else {
        setLeadSource([]);
      }
    });

  return subscriber;
};

export const fetchpropertyStage = (
  organization_id: string,
  setPropertyStages: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((doc) => {
      const resourceData = doc.data();
      if (resourceData?.PropertyStages) {
        let data: any[] = [];
        resourceData?.PropertyStages.forEach((item: any) => {
          data.push(item);
        });
        setPropertyStages(data);
      } else {
        setPropertyStages([]);
      }
    });

  return subscriber;
};


export const fetchpropertyType = (
  organization_id: string,
  setPropertyTypes: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((doc) => {
      const resourceData = doc.data();
      if (resourceData?.PropertyTypes) {
        let data: any[] = [];
        resourceData?.PropertyTypes.forEach((item: any) => {
          data.push(item);
        });
        setPropertyTypes(data);
      } else {
        setPropertyTypes([]);
      }
    });

  return subscriber;
};

export const fetchNewPropertySubType = (
  organization_id: string,
  setPropertyTypes: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((doc) => {
      const resourceData = doc.data();
      if (resourceData?.PropertySubTypes) {
        let data: any[] = [];
        resourceData?.PropertySubTypes.forEach((item: any) => {
          data.push(item);
        });
        setPropertyTypes(data);
      } else {
        setPropertyTypes([]);
      }
    });

  return subscriber;
};


export const CreateLeadDistribution = (
  organization_id: string,
  leadSourceList: string[],
  projectList: string[],
  locationList: string[],
  budgetList: string[],
  propertyList: string[],
  usersList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .set(
      {
        organization_id: organization_id,
        logic: Firebase.firestore.FieldValue.arrayUnion({
          source: leadSourceList,
          project: projectList,
          location: locationList,
          budget: budgetList,
          property_type: propertyList,
          users: usersList,
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now(),
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Distribution Created!!",
          "success"
        )
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log(
        "Lead Distribution Created Error:",
        error
      );
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      setLoad(false);
      close();
    });
};

export const CreateLeadDistributionRoundrobin = (
  organization_id: string,
  leadSourceList: string[],
  projectList: string[],
  locationList: string[],
  budgetList: string[],
  propertyList: string[],
  usersList: any[],
  usersQueue: any[],
  leadManagerList:any[],
  logictype: any,
  leadDist_id:any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history:any
) => {
  firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .set(
      {
        organization_id: organization_id,
        logic: Firebase.firestore.FieldValue.arrayUnion({
          source: leadSourceList,
          project: projectList,
          location: locationList,
          budget: budgetList,
          property_type: propertyList,
          users: usersList,
          usersQueue: usersQueue,
          leadManager_users:leadManagerList,
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now(),
          distribution_type:logictype,
          userIndex:0,
          leadDist_id:leadDist_id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Distribution Created!!",
          "success"
        )
      );
      setLoad(false);
      history.push("/leadDistributor");
    })
    .catch((error) => {
      console.log(
        "Lead Distribution Created Error:",
        error
      );
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const fetchRotationTimeData = (setProfile: (data: any[]) => void) => {
  const subscriber = firestore
    .collection('values')
    .doc('rotationTime')
    .onSnapshot((time) => {
      if (time) {
        setProfile(time.data()?.time);
      } else {
        console.log('profiles not found');
      }
    });
  return subscriber;
};

export const CreateReassignLogic = (
  organization_id: string,
  leadSourceList: string[],
  projectList: string[],
  rotationTime:any,
  usersList: any[],
  usersQueue: any[],
  leadManagerList:any[],
  leadDist_id:any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history:any
) => {
  firestore
    .collection("reassignUnattended")
    .doc(organization_id)
    .set(
      {
        organization_id: organization_id,
        logic: Firebase.firestore.FieldValue.arrayUnion({
          source: leadSourceList,
          project: projectList,
          rotation_time:rotationTime,
          users: usersList,
          usersQueue: usersQueue,
          leadManager_users:leadManagerList,
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now(),
          userIndex:0,
          reloc_id:leadDist_id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Reassign Created!!",
          "success"
        )
      );
      setLoad(false);
      history.push("/reassignList");
    })
    .catch((error) => {
      console.log(
        "Reassign Created Error:",
        error
      );
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const CreateLeadDistributionNormal = (
  organization_id: string,
  leadSourceList: string[],
  projectList: string[],
  locationList: string[],
  budgetList: string[],
  propertyList: string[],
  usersList: any[],
  usersQueue: any[],
  logictype: any,
  leadDist_id:any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history:any
) => {
  firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .set(
      {
        organization_id: organization_id,
        logic: Firebase.firestore.FieldValue.arrayUnion({
          source: leadSourceList,
          project: projectList,
          location: locationList,
          budget: budgetList,
          property_type: propertyList,
          users: usersList,
          usersQueue: usersQueue,
          created_at: Firebase.firestore.Timestamp.now(),
          modify_at: Firebase.firestore.Timestamp.now(),
          distribution_type:logictype,
          userIndex:0,
          leadManager_users:[],
          leadDist_id:leadDist_id
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Distribution Created!!",
          "success"
        )
      );
      setLoad(false);
      history.push("/leadDistributor");
    })
    .catch((error) => {
      console.log(
        "Lead Distribution Created Error:",
        error
      );
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const fetchLeadDistributions = (
  organization_id: string,
  setData: (data: any[]) => void
) => {
  const subscribe = firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        console.log("filterData Json",JSON.stringify(data?.logic));
        if (data?.logic) {
          data.logic.forEach((logic: any) => {
            logic.users = logic.users.map(
              (user: any) => user.user_email
            );
          });
          setData(data.logic);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });
  return subscribe;
};

export const fetchRotation = (
  organization_id: string,
  setData: (data: any[]) => void
) => {
  const subscribe = firestore
    .collection("reassignUnattended")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        console.log("data",JSON.stringify(data));
        if (data?.logic) {
          data.logic.forEach((logic: any) => {
            logic.users = logic.users.map(
              (user: any) => user.user_email
            );
          });
          setData(data.logic);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });
  return subscribe;
};

export const fetchCoupon = (
  organization_id: string,
  setData: (data: any[]) => void
) => {
  const subscribe = firestore
    .collection("coupon")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        if (data?.coupon) {
          setData(data.coupon);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });
  return subscribe;
};

export const fetchCouponList = (
  organization_id: string,
  setData: (data: { value: number; name: string }[]) => void
) => {
  const subscribe = firestore
    .collection("coupon")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        if (data?.coupon) {
          const transformedData = data.coupon.map((coupon: any) => ({
            value: coupon.discount,
            name: coupon.coupon_Code,
          }));
          setData(transformedData);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });
  return subscribe;
};

export const fetchRotationData = (
  organization_id: string,
  setData: (data: any[]) => void
) => {
  const subscribe = firestore
    .collection("reassignUnattended")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        if (data?.logic) {
          data.logic.forEach((logic: any) => {
            logic.users = logic.users.map(
              (user: any) => user.user_email
            );
          });
          setData(data.logic);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });
  return subscribe;
};

export const fetchLeadDistributionsEdit = (
  organization_id: string,
  setData: (data: any[]) => void
) => {
  const subscribe = firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .onSnapshot((doc) => {
      if (doc) {
        const data = doc.data();
        if (data?.logic) {
          setData(data.logic);
        } else {
          setData([]);
        }
      } else {
        setData([]);
      }
    });

  return subscribe;
};

export const editLeadDistribution = (
  organization_id: string,
  leadDistributionList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .set(
      {
        logic: leadDistributionList,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Distribution Updated!!",
          "success"
        )
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
      console.log("Upload Error:", error);
    });
};

export const deleteLeadDistribution = (
  organization_id: string,
  leadDistributionList: any[],
  dispatcher: any,
) => {
  firestore
    .collection("leadDistribution")
    .doc(organization_id)
    .set(
      {
        logic: leadDistributionList,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Distribution Deleted!!",
          "success"
        )
      );
      // setLoad(false);
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      // setLoad(false);
      console.log("Upload Error:", error);
    });
};
export const deleteCoupon = (
  organization_id: string,
  couponList: any[],
  dispatcher: any,
) => {
  firestore
    .collection("coupon")
    .doc(organization_id)
    .set(
      {
        coupon: couponList,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Coupon Deleted!!",
          "success"
        )
      );
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      console.log("Upload Error:", error);
    });
};

export const deleteLeadRotation = (
  organization_id: string,
  leadDistributionList: any[],
  dispatcher: any,
) => {
  firestore
    .collection("reassignUnattended")
    .doc(organization_id)
    .set(
      {
        logic: leadDistributionList,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Lead Rotation Deleted!!",
          "success"
        )
      );
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      console.log("Upload Error:", error);
    });
};


const normalizeUsersDataRoundrobin = (users:any,uidObject:any) => {
  return users?.map((user:any) => {
    const userEmail = typeof user === 'string' ? user : user.user_email;
    return {
        user_email: userEmail,
        uid: uidObject[userEmail] || '' // Look up UID from the mapping
    };
});
};
const normalizeManagerDataRoundrobin = (users:any,uidObject:any) => {
  return users?.map((user:any) => {
    const userEmail = typeof user === 'string' ? user : user.user_email;
    return {
        user_email: userEmail,
        uid: uidObject[userEmail] || '' // Look up UID from the mapping
    };
});
};
export const editLeadDistributionRoundrobin = async (
  uidObject:any,
  organization_id: string,
  leadDistributionList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history: any
) => {
  // Normalize user data in leadDistributionList
  const updatedLeadDistributionList = leadDistributionList.map((lead) => ({
    ...lead,
    leadManager_users:normalizeManagerDataRoundrobin(lead.leadManager_users,uidObject),
    users: normalizeUsersDataRoundrobin(lead.users,uidObject), // Normalize user data
    created_at: lead.created_at || Firebase.firestore.Timestamp.now(), // Use existing or set new timestamp
    modify_at: Firebase.firestore.Timestamp.now(), // Update modify timestamp
  }));
  console.log("Updated Lead Distribution List:", JSON.stringify(updatedLeadDistributionList));
  try {
    await firestore
      .collection("leadDistribution")
      .doc(organization_id)
      .set(
        {
          logic: updatedLeadDistributionList,
        },
        { merge: true }
      );
    dispatcher(showSnackbarAction("Lead Distribution Updated!!", "success"));
    setLoad(false);
    history.push("/leadDistributor");
  } catch (error) {
    // Handle error
    console.error("Upload Error:", error);
    dispatcher(showSnackbarAction("Error!! Try Again!!", "error"));
    setLoad(false);
  }
};

const normalizeUsersData = (users:any,uidObject:any) => {
  return users?.map((user:any) => {
    const userEmail = typeof user === 'string' ? user : user.user_email;
    return {
        user_email: userEmail,
        uid: uidObject[userEmail] || '' // Look up UID from the mapping
    };
});
};
export const editLeadDistributionNormal = async (
  uidObject:any,
  organization_id: string,
  leadDistributionList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void,
  history: any
) => {
  // Normalize user data in leadDistributionList
  const updatedLeadDistributionList = leadDistributionList.map((lead) => ({
    ...lead,
    users: normalizeUsersData(lead.users,uidObject), // Normalize user data
    created_at: lead.created_at || Firebase.firestore.Timestamp.now(), // Use existing or set new timestamp
    modify_at: Firebase.firestore.Timestamp.now(), // Update modify timestamp
  }));
  console.log("Updated Lead Distribution List:", JSON.stringify(updatedLeadDistributionList));
  try {
    await firestore
      .collection("leadDistribution")
      .doc(organization_id)
      .set(
        {
          logic: updatedLeadDistributionList,
        },
        { merge: true }
      );
    dispatcher(showSnackbarAction("Lead Distribution Updated!!", "success"));
    setLoad(false);
    history.push("/leadDistributor");
  } catch (error) {
    // Handle error
    console.error("Upload Error:", error);
    dispatcher(showSnackbarAction("Error!! Try Again!!", "error"));
    setLoad(false);
  }
};

// export const editLeadDistributionNormal = (
//   organization_id: string,
//   leadDistributionList: any[],
//   dispatcher: any,
//   setLoad: (data: boolean) => void,
//   history:any
// ) => {
//   console.log("leadDistributionList",leadDistributionList);
//   firestore
//     .collection("leadDistribution")
//     .doc(organization_id)
//     .set(
//       {
//         logic: leadDistributionList,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       dispatcher(
//         showSnackbarAction(
//           "Lead Distribution Updated!!",
//           "success"
//         )
//       );
//       setLoad(false);
//       // history.push("/leadDistributor")
//     })
//     .catch((error) => {
//       dispatcher(
//         showSnackbarAction("Error!! Try Again!!", "error")
//       );
//       setLoad(false);
//       console.log("Upload Error:", error);
//     });
// };


export const uploadContactsFile = async (
  file: any,
  organization_id: string,
  uid: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("contactsUploadRequest")
    .doc();
  const path = `contactsUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {
    await reference.put(file);
    const url = await reference.getDownloadURL();
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
          uid: uid,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Contacts Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Contacts upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadprojectFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("projectUploadRequest")
    .doc();
  const path = `projectUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Project Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Project upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadLeadSourceFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("leadSourceUploadRequest")
    .doc();
  const path = `leadSourceUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Lead Source Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Lead Source upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadLocationFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("locationUploadRequest")
    .doc();
  const path = `locationUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Location Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Location upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};
export const uploadBudgetFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("budgetUploadRequest")
    .doc();
  const path = `budgetUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Budget Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Budget upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};
export const uploadResidentialTypeFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("residentialTypeUploadRequest")
    .doc();
  const path = `residentialTypeUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("Residential Type Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Residential Type upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadTransferReasonTypeFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("transferReasonUploadRequest")
    .doc();
  const path = `transferReasonUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("TransferReason Type Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("TransferReason Type upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadPropertyTypesFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("propertyTypeUploadRequest")
    .doc();
  const path = `propertyTypeUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("Property Type Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Property Type upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadPropertyStageFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("propertyStageUploadRequest")
    .doc();
  const path = `propertyStageUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("Property Stage Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Property Stage upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadPropertySubTypeFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("propertySubTypeUploadRequest")
    .doc();
  const path = `propertySubTypeUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("Property Sub Type Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Property Sub type upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadCommercialTypeFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("commercialTypeUploadRequest")
    .doc();
  const path = `commercialTypeUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();
    
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
    console.log("Commercial Type Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Commercial Type upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};
export const uploadfaqFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("faqUploadRequest")
    .doc();
  const path = `faqUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {
    await reference.put(file);
    const url = await reference.getDownloadURL();
    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("FAQ Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("FAQ upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};

export const uploadApiFile = async (
  file: any,
  organization_id: string,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  createdBy: string
) => {
  const doc = firestore
    .collection("apiUploadRequest")
    .doc();
  const path = `apiUploadData/${organization_id}/${doc.id}.csv`;
  const reference = storage.ref(path);

  try {

    await reference.put(file);
    const url = await reference.getDownloadURL();

    try {
      await doc.set(
        {
          request_id: doc.id,
          organization_id: organization_id,
          fileUrl: url,
          status: "Uploaded",
          responseURL: "",
          uploadCount: 0,
          created_at: Firebase.firestore.Timestamp.now(),
          created_by: createdBy,
        },
        { merge: true }
      );
      dispatcher(
        showSnackbarAction("File Uploaded!!", "success")
      );
      setLoad(false);
    } catch (error) {
      console.log("Api Upload Error", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    }
  } catch (error) {
    console.log("Api upload Error", error);
    dispatcher(
      showSnackbarAction("Error!! Try Again!!", "error")
    );
    setLoad(false);
  }
};




export const fetchContactsFile = (
  organization_id: string,
  uid: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  console.log("organization_id fetch by organization:",organization_id);
  console.log("uid fetch by uid:",uid);
  const subscriber = firestore
    .collection("contactsUploadRequest")
    .where("organization_id", "==", organization_id)
    .where("uid", "==", uid)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {
            const path = `contactsUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);

            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const contactsData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          contactsData.push(data);
        });
        setUploadFiles(contactsData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};


export const fetchFaqFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  console.log("Organization Id pass :-",organization_id);
  const subscriber = firestore
    .collection("faqUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `faqUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const projectData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          projectData.push(data);
        });
        setUploadFiles(projectData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchProjectFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {

  const subscriber = firestore
    .collection("projectUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `projectUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const projectData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          projectData.push(data);
        });
        setUploadFiles(projectData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};
export const fetchLocationFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("locationUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `locationUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const locationData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          locationData.push(data);
        });
        console.log("locationData",locationData);
        setUploadFiles(locationData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};
export const fetchLeadSourceFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("leadSourceUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `leadSourceUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const leadSourceData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          leadSourceData.push(data);
        });
        setUploadFiles(leadSourceData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};
export const fetchBudgetFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("budgetUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `budgetUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const budgetData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          budgetData.push(data);
        });
        console.log("budgetData",budgetData);
        setUploadFiles(budgetData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchCommercialTypeFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("commercialTypeUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `commercialTypeUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const CommercialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          CommercialTypeData.push(data);
        });
        console.log("CommercialTypeData",CommercialTypeData);
        setUploadFiles(CommercialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchTransferReasonFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("transferReasonUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `transferReasonUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const ResidentialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          ResidentialTypeData.push(data);
        });
        console.log("TransferReason Data",ResidentialTypeData);
        setUploadFiles(ResidentialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchPropertyTypeFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("propertyTypeUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `propertyTypeUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const ResidentialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          ResidentialTypeData.push(data);
        });
        console.log("PropertyType Data",ResidentialTypeData);
        setUploadFiles(ResidentialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchPropertyStageFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("propertyStageUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `propertyStageUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const ResidentialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          ResidentialTypeData.push(data);
        });
        console.log("PropertyType Data",ResidentialTypeData);
        setUploadFiles(ResidentialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchPropertySubTypeFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("propertySubTypeUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `propertySubTypeUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const ResidentialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          ResidentialTypeData.push(data);
        });
        console.log("PropertySubType Data",ResidentialTypeData);
        setUploadFiles(ResidentialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchResidentialTypeFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("residentialTypeUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {

            const path = `residentialTypeUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const ResidentialTypeData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          ResidentialTypeData.push(data);
        });
        console.log("ResidentialTypeData",ResidentialTypeData);
        setUploadFiles(ResidentialTypeData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};

export const fetchApiFile = (
  organization_id: string,
  setUploadFiles: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("apiUploadRequest")
    .where("organization_id", "==", organization_id)
    .orderBy("created_at", "desc")
    .limit(25)
    .onSnapshot((docs) => {
      if (docs.docs) {
        docs.docs.forEach((doc) => {
          const data = doc.data();
          if (
            data.status === "Processed" &&
            data.responseURL === ""
          ) {
            const path = `apiUploadData/${organization_id}/r-${data.request_id}.csv`;
            const reference = storage.ref(path);
            reference
              .getDownloadURL()
              .then((responseURL) => {
                doc.ref.set(
                  { responseURL },
                  { merge: true }
                );
              })
              .then(() => {
                dispatcher(
                  showSnackbarAction(
                    data.request_id + " File Processed!!",
                    "success"
                  )
                );
              });
          }
        });
      }
      if (docs) {
        const apiData: any[] = [];
        docs.docs.forEach(async (item, index) => {
          const data = item.data();
          apiData.push(data);
        });
        setUploadFiles(apiData);
      } else {
        setUploadFiles([]);
      }
    });
  return subscriber;
};


export const toggleis_button_called = async (
  contactId: any
) => {

  await firestore
    .collection("contacts")
    .doc(contactId)
    .update({
      is_button_called: true,
    });
};

export const deleteRecords = (leadIds: any) => {
  const newId: string[] = [];

  leadIds.forEach((element: any) => {

    newId.push(element.contactId);
  });




 try{
  // console.log("amamsksl",newId);
  newId.forEach(async (id: string,index:number) => {
    const contactDoc = await firestore
      .collection("contacts")
      .doc(`${id}`)
      .get();

      const contactDocRef = await firestore
      .collection("contacts")
      .doc(`${id}`)
      // .get();

    const taskDocRef = await firestore
      .collection("tasks")
      .doc(contactDoc.id)
      // .get();
    const resourceDocRef = await firestore
      .collection("contactResources")
      .doc(contactDoc.id)
      // .get();
    await contactDocRef.delete();
    await taskDocRef.delete();
    await resourceDocRef.delete();
  });

  return true;
  // newId.forEach(async (id: string) => {
  //   const doc = await firestore
  //     .collection("tasks")
  //     .doc(`${id}`)
  //     .get();


  //   await doc.ref.delete();



  // });
  // newId.forEach(async (id: string) => {
  //   const doc = await firestore
  //     .collection("contactResources")
  //     .doc(`${id}`)
  //     .get();


  //   await doc.ref.delete();



  // });
 }catch(err){
  console.log(err);
  return false;
 }
};

// export const deleteRecords = (newId: any) => {
//  try{
//   newId.forEach(async (id: string,index:number) => {
//     // const contactDoc = await firestore
//     //   .collection("contacts")
//     //   .doc(`${id}`)
//     //   .get();
//       const contactDocRef = await firestore
//       .collection("contacts")
//       .doc(`${id}`)
//       // .get();

//     const taskDocRef = await firestore
//       .collection("tasks")
//       .doc(id)
//       // .get();
//     const resourceDocRef = await firestore
//       .collection("contactResources")
//       .doc(id)
//       // .get();
//       // console.log("amamsksl",contactDocRef.data(), taskDocRef.data() ,resourceDocRef.data() , id,index);
//     await contactDocRef.delete();
//     await taskDocRef.delete();
//     await resourceDocRef.delete();
//   });

//   return true;
//   // newId.forEach(async (id: string) => {
//   //   const doc = await firestore
//   //     .collection("tasks")
//   //     .doc(`${id}`)
//   //     .get();


//   //   await doc.ref.delete();



//   // });
//   // newId.forEach(async (id: string) => {
//   //   const doc = await firestore
//   //     .collection("contactResources")
//   //     .doc(`${id}`)
//   //     .get();


//   //   await doc.ref.delete();



//   // });
//  }catch(err){
//   console.log(err);
//   return false;
//  }
// };

// export const deleteRecords = (leadIds: any) => {
//   const newId: string[] = [];

//   leadIds.forEach((element: any) => {

//     newId.push(element.contactId);
//   });




//   try{
//     newId.forEach(async (id: string) => {
//       const doc = await firestore
//         .collection("contacts")
//         .doc(`${id}`)
//         .get();
  
//       await firestore
//         .collection("tasks")
//         .doc(doc.id)
//         .delete();
//       await firestore
//         .collection("contactResources")
//         .doc(doc.id)
//         .delete();
  
//       await doc.ref.delete();
  
  
  
//     });
//     newId.forEach(async (id: string) => {
//       const doc = await firestore
//         .collection("tasks")
//         .doc(`${id}`)
//         .get();
  
  
//       await doc.ref.delete();
  
  
  
//     });
//     newId.forEach(async (id: string) => {
//       const doc = await firestore
//         .collection("contactResources")
//         .doc(`${id}`)
//         .get();
  
  
//       await doc.ref.delete();
  
  
  
//     });
//     return true;
//   }catch(err){
//     console.log(err);
//   }
// };