import React, { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { showSnackbarAction } from "../../Redux/actions";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { url } from "../../Values/constants";

interface MyFormValues {
  [key: string]: any;
}

const initialFormValues: MyFormValues = {
  booking_id: "Booking Id",
  added_by: "Added By",
  added_time: "Added Time",
  modified_by: "Modified By",
  modified_time: "Modified Time",
  date_booking: "Date Booking",
  developer_name: "Developer Name",
  project_name: "Project Name",
  area: "Area",
  unit_no: "Unit Number",
  source_fund: "Source Fund",
  scheme: "Scheme",
  booking_attachment: "Booking Attachment",
  pan_card: "Pan Card",
  area_type: "Area Type",
  Kyc_attachment: "Kyc Attachment",
  employee_details: {
    owner_email: "Owner Email",
    in_active_employe: "In Active Employe",
  },
  applicant_details: {
    applicant: {
      name: "Name",
      address_line1: "Address Line1",
      address_line2: "Address Line2",
      city: "City",
      state: "State",
      country: "Country",
      postal_code: "PinCode",
      phone_number1: "Phone Number1",
      phone_number2: "Phone Number2",
      email: "Email",
      gender: "Gender",
      date_of_birth: "Date of Birth",
      marrital_status: "Marrital Status",
      occupation: "Occupation",
      company_name: "Company Name",
      designation: "Designation",
      net_monthly_income: "Net Monthly Income",
      aadhaar_no: "Aadhaar No",
      pan_no: "Pan No",
    },
    co_applicant: [
      {
        name: "Name",
        address_line1: "Address Line1",
        address_line2: "Address Line2",
        city: "City",
        state: "State",
        country: "Country",
        postal_code: "PinCode",
        phone_number1: "Phone Number1",
        phone_number2: "Phone Number2",
        email: "Email",
        gender: "Gender",
        date_of_birth: "Date of Birth",
        marrital_status: "Marrital Status",
        occupation: "Occupation",
        company_name: "Company Name",
        designation: "Designation",
        net_monthly_income: "Net Monthly Income",
        aadhaar_no: "Aadhaar No",
        pan_no: "Pan No",
      },
    ],
  },
  property_details_BSP: {
    tower_block: "Tower Block",
    area: "Area",
    measure: "Measure",
    floor: "Floor",
    payment_plan: "Payment Plan",
    bsp: "BSP",
    bsp_discounting_type: "BSP Discounting Type",
    bsp_discount_amount: "BSP Discount Amount",
    bsp_discount: "BSP Discount",
    net_bsp_after_discount: "Net BSP After Discount",
  },
  additional_charges: {
    a: "A",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    h: "H",
    i: "I",
    a_amount: "A Amount",
    b_amount: "B Amount",
    c_amount: "C Amount",
    d_amount: "D Amount",
    e_amount: "E Amount",
    f_amount: "F Amount",
    g_amount: "G Amount",
    h_amount: "H Amount",
    i_amount: "I Amount",
    total_additional_charges: "Total Additional Charges",
  },
  consolidated_costing: {
    net_bsp_area: "Net BSP Area",
    total_additional_charges: "Total Additional Charges",
    gross_property_cost_applicant: "Gross Property Cost Applicant",
    gst_amount: "GST Amount",
    gst: "GST",
    net_property_cost_applicant: "NET Property Cost Applicant",
    minimum_booking_amount: "Minimum Booking Amount",
  },
  payment_plan: [
    {
      payment_instalments: "Payment Instalments",
      payment: "Payment",
      payment_amount: "Payment Amount",
      payment_date: "Payment Date",
      payment_reference: "Payment Reference",
      payment_source: "Payment Source",
      bank_name: "Bank Name",
      payment_receiving_proof: "Payment Receiving Proof",
      payment_clearance_proof: "Payment Clearance Proof",
      payment__proof: "Payment Proof",
      Payment_Status: "Payment Status",
    },
  ],
  source_of_fund: {
    funding_source: "Funding Source",
    Occupation: "Occupation",
    gross_monthly_income: "GROSS Monthly Income",
    annual_bonus_incentive: "Annual Bonus Incentive",
    rental_income: "Rntal Income",
    existing_EMI: "Existing EMI",
  },
  attachments: {
    developer_receiving: "Developer Receiving",
    BE_form: "BE Form",
    approvals: "Approvals",
    remarks: "Remarks",
  },
};

type props = {
  user: any;
  theme: any;
  selectedIndustries: any;
  allIndustries: any;
  history: any;
};
const BookingForm: FunctionComponent<props> = ({
  user,
  theme,
  selectedIndustries,
  allIndustries,
  history
}) => {
  const dispatcher = useDispatch();
  const [formValues, setFormValues] = useState<MyFormValues>(initialFormValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleInputChange = (path: string, value: any) => {
    const keys = path.split(".");
    setFormValues((prev) => {
      let updated = { ...prev };
      let current = updated;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current = current[key];
        }
      });
      return updated;
    });
  };
  const capitalizeKey = (key: string) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  const renderFormFields = (data: any, prefix: string = "") => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "16px",
        }}
      >
        {Object.keys(data).map((key) => {
          const fullPath = prefix ? `${prefix}.${key}` : key;
          const value = data[key];
          const displayKey = capitalizeKey(key);

          if (typeof value === "object" && !Array.isArray(value)) {
            return (
              <fieldset
                key={fullPath}
                style={{ gridColumn: "span 4", border: "none", padding: 0 }}
              >
                <legend
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    fontSize: "16px",
                  }}
                >
                  {displayKey}
                </legend>
                {renderFormFields(value, fullPath)}
              </fieldset>
            );
          } else if (Array.isArray(value)) {
            return (
              <fieldset
                key={fullPath}
                style={{ gridColumn: "span 4", border: "none", padding: 0 }}
              >
                <legend
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    fontSize: "16px",
                  }}
                >
                  {displayKey}
                </legend>
                {value.map((item, index) => (
                  <fieldset
                    key={`${fullPath}[${index}]`}
                    style={{ border: "none", padding: 0 }}
                  >
                    {renderFormFields(item, `${fullPath}[${index}]`)}
                  </fieldset>
                ))}
              </fieldset>
            );
          } else {
            return (
              <div key={fullPath} style={{ marginBottom: "10px" }}>
                {/* <label
                style={{
                  display: "block",
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {displayKey}
              </label> */}
                <input
                  type="text"
                  value={value || ""}
                  onChange={(e) => handleInputChange(fullPath, e.target.value)}
                  placeholder="Enter value"
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const getSingleInd = allIndustries.filter(
        (obj: any) => obj.industry_id === selectedIndustries
      );
      const apiData = {
        industry_id: selectedIndustries,
        industry_name: getSingleInd[0]["industry_name"],
        created_by: user.user_email,
        bookingFrom: formValues,
      };
      const res = await axios.post(url + "/bookings/createFrom", apiData);
      if (res.status == 200) {
        if (res.data === "Booking From Already Exist") {
          dispatcher(showSnackbarAction(res.data, "error"));
        } else {
          dispatcher(showSnackbarAction(res.data, "success"));
        }
        history.replace("/bookingForm");
        setIsSubmitting(false);
      } else {
        dispatcher(showSnackbarAction(res.data, "error"));
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      showSnackbarAction("An error occurred", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  return (
    <div
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{
        ...containerStyles,
        marginTop: "-1%",
        minHeight: "80vh",
        overflow: "hidden",
      }}
    >
      <div
        className="w-100 d-flex flex-column gap-2"
        style={{
          ...cardContainerStyles,
          flex: 1,
          overflowY: "auto",
          maxHeight: "calc(100vh - 120px)",
          paddingBottom: "40px",
        }}
      >
        <form
          className="card p-4 shadow rounded d-flex flex-column gap-4"
          style={cardContainerStyles}
          onSubmit={handleSubmit}
        >
          {renderFormFields(formValues)}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
          >
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: "#272944",
                color: "white",
                border: "none",
                outline: "none",
                width: "150px",
                padding: "10px 16px",
                borderRadius: "4px",
                fontSize: "16px",
                cursor: isSubmitting ? "not-allowed" : "pointer",
              }}
            >
              {isSubmitting ? "Saving..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(BookingForm);
