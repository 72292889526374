import React, { FunctionComponent, useRef, useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import Label from "../../Components/Label";
import { themeColors } from "../../Components/theme";
import { v4 as uuidv4 } from 'uuid';
import { createCouponCost } from "../../Services/resources";


type Props = {
  history: any;
  theme: any;
};

const AddCoupon: FunctionComponent<Props> = ({ history, theme }) => {
  const [load, setLoad] = useState(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [discount, setDiscount] = useState<number | undefined>(undefined);
//   const [expirationDate, setExpirationDate] = useState<string>("");
  const dispatcher = useDispatch();

  const handleValidation = () => {
    if (!couponCode.trim()) {
      dispatcher(showSnackbarAction("Please enter a coupon code!", "error"));
      return false;
    }
    if (!discount || discount <= 0 || discount > 100) {
      dispatcher(
        showSnackbarAction(
          "Please enter a valid discount percentage (1-100)!",
          "error"
        )
      );
      return false;
    }
    // if (!expirationDate) {
    //   dispatcher(
    //     showSnackbarAction("Please select an expiration date!", "error")
    //   );
    //   return false;
    // }
    return true;
  };

  const createCoupon = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!handleValidation()) return;
    try {
      const coupon_id = uuidv4();
      setLoad(true);
      createCouponCost(
        couponCode.trim(),
        discount,
        // expirationDate,
        coupon_id,
        dispatcher,
        (data: boolean) => setLoad(data),
        history
      );
    } catch (error) {
      console.error("Error creating coupon:", error);
      dispatcher(
        showSnackbarAction(
          "Failed to create coupon. Please try again.",
          "error"
        )
      );
    } finally {
      setLoad(false);
    }
  };

  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  return (
    <div
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{
        ...containerStyles,
        marginTop: "4%",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <>
        <h4>Create Coupon</h4>
        <div
          className="w-100 d-flex flex-column gap-2"
          style={cardContainerStyles}
        >
          <form
            className="card p-4 shadow rounded"
            style={cardContainerStyles}
            onSubmit={createCoupon}
          >
            <div className="mb-3">
              <Label label="Coupon Code" required />
              <input
                type="text"
                className="form-control"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter coupon code"
              />
            </div>
            <div className="mb-3">
              <Label label="Discount (%)" required />
              <input
                type="number"
                className="form-control"
                value={discount}
                onChange={(e) => setDiscount(Number(e.target.value))}
                min="1"
                max="100"
                placeholder="Enter discount percentage"
              />
            </div>
            {/* <div className="mb-3">
              <Label label="Expiration Date" required />
              <input
                type="date"
                className="form-control"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
              />
            </div> */}
            <button
              type="submit"
              className={styles.button}
              style={{
                backgroundColor: theme ? "#272944" : "rgb(54, 56, 83)",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(AddCoupon);
