import React, { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import { themeColors } from "../../Components/theme";
import { connect } from "react-redux";
import { url } from "../../Values/constants";
import BookingForm from "./BookingForm";

type props = {
  history: any;
  user: any;
  theme: any;
};
const AddBooking: FunctionComponent<props> = ({ history, user, theme }) => {
  const [selectedIndustries, setSelectedIndustries] = useState("");
  const [load, setLoad] = useState(false);
  const [allIndustries, setallIndustries] = useState<
    {
      industry_id: string;
      status: string;
      industry_name: string;
      screens: string[];
    }[]
  >([]);
 
  useEffect(() => {
    getAllIndustries();
  }, []);

  const getAllIndustries = async () => {
    setLoad(true);
    const apiData = {
      status: "Launched",
    };
    try {
      const res = await axios.post(url + "/headers/getAllIndustries", apiData);
      setallIndustries(res.data);
      setLoad(false);
    } catch (e) {
      console.log("Provide Exception By get all industries :", e);
      setLoad(false);
    }
  };
  
  const handleIndustriesChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedIndustries(event.target.value);
  };

  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  console.log("selectedIndustries",selectedIndustries);
  return (
    <div
      className="container-fluid p-4 d-flex flex-column gap-4"
      style={{
        ...containerStyles,
        marginTop: "4%",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{marginLeft:"24px"}}>
        <select
          id="dropdown"
          value={selectedIndustries}
          onChange={handleIndustriesChange}
          style={{
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontSize: "16px",
            padding: "2px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            width: "200px",
          }}
        >
          <option value="">select an Industries</option>
          {allIndustries?.map((item: any) => {
            return (
              <option value={item.industry_id}>{item.industry_name}</option>
            );
          })}
        </select>
      </div>
      {selectedIndustries && (
        <>
          <BookingForm  history={history} selectedIndustries = {selectedIndustries} allIndustries={allIndustries}/>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(AddBooking);
