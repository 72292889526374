import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from 'yup';
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import styles from '../Analytics/Analytics.module.css'
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import moment from "moment";
import { url } from "../../Values/constants";
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { Grid, Button, Typography, Select, MenuItem, Card, CardMedia, Avatar } from "@mui/material";
import {
    contactResources,
} from "../../Services/contacts";
import './booking.style.css';
import { makeStyles } from "@material-ui/core";
import { themeColors } from "../../Components/theme";

const CssTextField = styled(TextField)({
    fontSize: "0.8rem"
});
const arrAdditionalCharges = [
    {
        value: 'Club Membership',
        label: 'Club Membership'
    },
    {
        value: 'Dual Electric Meter Charges',
        label: 'Dual Electric Meter Charges'
    },
    {
        value: 'EDC',
        label: 'EDC'
    },
    {
        value: 'EEC',
        label: 'EEC'
    },
    {
        value: 'FFC',
        label: 'FFC'
    },
    {
        value: 'Gas Connection',
        label: 'Gas Connection'
    },
    {
        value: 'IFMS',
        label: 'IFMS'
    },
    {
        value: 'Lease Rent',
        label: 'Lease Rent'
    },
    {
        value: 'Others',
        label: 'Others'
    },
    {
        value: 'Parking Covered',
        label: 'Parking Covered'
    },
    {
        value: 'Parking Open',
        label: 'Parking Open'
    },
    {
        value: 'Parking Stilt',
        label: 'Parking Stilt'
    },
    {
        value: 'PLC Corner',
        label: 'PLC Corner'
    },
    {
        value: 'PLC Floor',
        label: 'PLC Floor'
    },
    {
        value: 'PLC Green',
        label: 'PLC Green'
    },
    {
        value: 'PLC Pool',
        label: 'PLC Pool'
    },
    {
        value: 'Power Backup',
        label: 'Power Backup'
    },
    {
        value: 'Stamp Duty',
        label: 'Stamp Duty'
    },
    {
        value: 'Registration Charges',
        label: 'Registration Charges'
    },
    {
        value: 'PLC Road',
        label: 'PLC Road'
    }
];
type props = {
    formikProps: any;
    theme:any;
    bookingHeader:any;
};
const EditAdditionalCharges: FunctionComponent<props> = ({ formikProps,theme,bookingHeader}) => {
    const { a_amount,
        b_amount,
        c_amount,
        d_amount,
        e_amount,
        f_amount,
        g_amount,
        h_amount,
        i_amount,
        ...objData
    } = formikProps.values.additional_charges;

    useEffect(() => {
        const totalObj = {
            "a_amount": a_amount,
            "b_amount": b_amount,
            "c_amount": c_amount,
            "d_amount": d_amount,
            "e_amount": e_amount,
            "f_amount": f_amount,
            "g_amount": g_amount,
            "h_amount": h_amount,
            "i_amount": i_amount,
        };
        const sumTotal = Object.entries(totalObj).reduce((finalValue, [key, value]) => {
            return finalValue + Number(value);
        }, 0);
        formikProps.setFieldValue("additional_charges.total_additional_charges", sumTotal);
    }, [a_amount,
        b_amount,
        c_amount,
        d_amount,
        e_amount,
        f_amount,
        g_amount,
        h_amount,
        i_amount]);
        const useHelperTextStyles = makeStyles(() => ({
            inputRoot: {
              // "&$disabled": {
              //   color: "green"
              // }
            },
            disabled: {
            },
            root: {
              "& .MuiFormLabel-root": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiFormLabel-root.Mui-disabled": {
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiSelect-select":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiSelect-select.Mui-disabled":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiInputBase-input":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiInputBase-input.Mui-disabled":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
                "-webkit-text-fill-color":  `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiOutlinedInput-input":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiOutlinedInput-input.Mui-disabled":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
              },
              "& .MuiSvgIcon-root":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            },
            "& .MuiSvgIcon-root.Mui-disabled":{
                color: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark}`,
            }
            },
            notchedOutline:{
              borderWidth: "1px",
              borderColor: `${theme ? themeColors.textPrimary : themeColors.textPrimaryDark} !important`,
            },
            preview: {
              color:"black"
            },
            dropzone:{
              color:"black"
            },
            formHelperText:{
            },
          }));
          const helperTextStyles = useHelperTextStyles();
    return (
        <>
            <div style={{
                border: "solid 1px #AEAEAE",
                boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
                borderRadius: "10px",
                padding: "10px",
            }}>
                <Grid container className="booking_container" spacing={2} style={{ padding: "30px" }} >
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.a || "A"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.a')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.a_amount || "A Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.a_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.b || "B"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.b')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.b_amount || "B Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.b_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.c || "C"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.c')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.c_amount || "C Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.c_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.d || "D"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.d')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.d_amount || "D Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.d_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.e || "E"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.e')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.e_amount || "E Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.e_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.f || "F"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.f')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.f_amount || "F Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.f_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.g || "G"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.g')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.g_amount || "G Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.g_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.h || "H"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.h')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.h_amount || "H Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.h_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            select
                            label={bookingHeader[0]?.applicant_details.applicant.i || "I"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.i')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                            {arrAdditionalCharges.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.i_amount || "I Amount"}
                            size="small"
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.i_amount')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CssTextField
                            type="text"
                            fullWidth
                            label={bookingHeader[0]?.applicant_details.applicant.total_additional_charges || "Total Amount"}
                            size="small"
                            disabled
                            id="custom-css-outlined-input"
                            {...formikProps.getFieldProps('additional_charges.total_additional_charges')}
                            className={helperTextStyles.root}
                            InputProps={{
                              classes: {
                                notchedOutline: helperTextStyles.notchedOutline
                              }
                            }}
                            sx={{ input: { color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark } }}
                        >
                        </CssTextField>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user.data,
        theme: state.theme.isLightMode,
        bookingHeader: state.bookingHearderList.data,
    };
};
export default connect(mapStateToProps)(EditAdditionalCharges);